var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AuthService } from 'app/auth/auth.service';
import { AuthStatus, passwordValidator } from './../../app.utils';
import { OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'app/shared/api.services/user.service';
import { throwError } from 'rxjs';
import { SessionStorage } from 'ngx-webstorage';
import { catchError } from 'rxjs/operators';
import { MyErrorStateMatcher, getTitleCase, ApiError } from 'app/app.utils';
import { ToastrService } from 'ngx-toastr';
export class ChangePasswordComponent {
    constructor(dialogRef, snackBar, formBuilder, userService, authservice, toastr) {
        this.dialogRef = dialogRef;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.authservice = authservice;
        this.toastr = toastr;
        this.cp_visible = false;
        this.np_visible = false;
        this.ncp_visible = false;
        this.pwd = null;
        this.matcher = new MyErrorStateMatcher();
    }
    ngOnInit() {
        this.passwordForm = this.formBuilder.group({
            current_password: [null, [Validators.required]],
            password: ['', [Validators.required]],
            password_confirmation: ['', []],
        });
        this.user = this.authservice.user;
        this.password.valueChanges.subscribe(val => this.pwd = val);
        this.passwordForm.setValidators([Validators.required, (g) => {
                const pwd_value = g.get('password').value;
                const cnf_value = g.get('password_confirmation').value;
                return passwordValidator(pwd_value, cnf_value, this.user);
            }]);
    }
    get passwordConfirmation() {
        return this.passwordForm.get('password_confirmation');
    }
    get password() {
        return this.passwordForm.get('password');
    }
    onSubmit() {
        const formData = this.passwordForm.getRawValue();
        this.userService.changePassword({
            current_password: formData.current_password,
            new_password: formData.password
        }).pipe(catchError((err) => {
            this.dialogRef.close();
            if (err.status === 401) {
                this.snackBar.open(`${getTitleCase(`Your current password is incorrect, please login to continue!`)}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
            }
            else if (err.error.error.name === ApiError.PasswordRequirementNotSatisfied) {
                this.snackBar.open(`${getTitleCase(`Password Requirements not Satisfied. Please try again.`)}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
            }
            else {
                this.snackBar.open(`${getTitleCase(`Something went wrong while updating your password!`)}`, null, {
                    duration: 3000,
                });
            }
            return throwError(err);
        })).subscribe(res => {
            if (res.data && res.data.status === AuthStatus.RESET_PASSWORD) {
                this.snackBar.open(`${getTitleCase(`This password is part of hacked passwords. please choose a stronger password.`)}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
            }
            else {
                this.dialogRef.close();
                this.snackBar.open(`${getTitleCase(`Your password has been changed successfully!`)}`, null, {
                    duration: 3000,
                });
            }
        });
    }
}
__decorate([
    SessionStorage('isLoading', false),
    __metadata("design:type", Object)
], ChangePasswordComponent.prototype, "loading", void 0);
