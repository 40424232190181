import { Observable } from 'rxjs';

import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from './auth/auth.service';
import { UpdateService } from './shared/update.service/update.service';
import { UploadService } from './shared/api.services/upload.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})
export class AppComponent {
  constructor(
    private location: Location,
    private authService: AuthService,
    private updateService: UpdateService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private titleService: Title,
    private uploadService: UploadService,
    @Inject(DOCUMENT) private _document: HTMLDocument) {
    this.authService.loadedUrl = this.location.path();
    this.updateService.checkForUpdates();

    // IE not supported
    if (this.deviceService.getDeviceInfo().browser === 'IE') {
      this.router.navigateByUrl('/notsupported');
    }

    if (environment.isATS) {
      // pull icons from the assests folder
      this._document.getElementById('appFavicon').setAttribute('href', '../assets/icons/logo_ats.png');
      this.titleService.setTitle('At-Scene LLC');
    } else {
      this._document.getElementById('appFavicon').setAttribute('href', '../assets/icons/logo_ib.png');
      this.titleService.setTitle('iCrimeFighter');
    }
    window.addEventListener('beforeunload', (event) => {
      this.uploadService.uploads.subscribe(uploads => {
        if (uploads.length > 0 && this.uploadService.uploaderCancel === false) {
          event.returnValue = `Are you sure you want to leave?`;
        }
      });
    });
  }
}
