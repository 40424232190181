import { appVersion } from 'app/app.version';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notauthorized',
  templateUrl: './notauthorized.component.html',
  styleUrls: ['./notauthorized.component.scss']
})
export class NotauthorizedComponent implements OnInit {
  version = appVersion;
  now = new Date();
  errName = '';
  errMessage = '';
  errTag = '';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.setErrorMessage(this.route.snapshot.params.errStat || '');
  }

  private setErrorMessage(err_name: string) {
    this.errTag = err_name;
    const err_name_data_set = {
      NotAuthorized: 'Not Authorized',
      EntityNotFound: 'Page Not Found',
      CaseDeleted: 'Case Deleted',
      CaseNotFound: 'Case Not Found',
      InvalidToken: 'Session Expired',
      SubscriptionExpired: 'Your Subscription Has Expired'
    };

    const err_msg_data_set = {
      NotAuthorized: 'You are not authorized to access this page.',
      EntityNotFound: '',
      CaseDeleted: 'Restore the case to access it.',
      CaseNotFound: '',
      InvalidToken: '',
      SubscriptionExpired: 'Renew your subscription to view the case files.'
    };

    this.errName = err_name_data_set[err_name] ? err_name_data_set[err_name] : err_name_data_set['EntityNotFound'];
    this.errMessage = err_msg_data_set[err_name] ? err_msg_data_set[err_name] : err_msg_data_set['EntityNotFound'];
  }
}
