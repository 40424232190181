import { Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
const ɵ0 = () => import("./wrapper/wrapper.module.ngfactory").then(m => m.WrapperModuleNgFactory);
const routes = [
    { path: 'app', loadChildren: ɵ0, canLoad: [AuthGuard] },
    { path: '', redirectTo: '/app/cases', pathMatch: 'full' },
];
export class AppRoutingModule {
}
export { ɵ0 };
