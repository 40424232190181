import { MomentModule } from 'ngx-moment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule, MatCardModule, MatMenuModule, MatToolbarModule,
  MatIconModule, MatInputModule, MatListModule, MatSidenavModule,
  MatProgressSpinnerModule, MatSnackBarModule, MatDialogModule, MatProgressBarModule, MatExpansionModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { AccountRoutingModule } from './account-routing.module';
import { RecoveryComponent } from './recovery/recovery.component';
import { ResetComponent } from './reset/reset.component';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WelcomeComponent } from './welcome/welcome.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthBarModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    SharedModule,
    AccountRoutingModule,
    MatExpansionModule,
    RecaptchaModule,
    MomentModule
  ],
  declarations: [
    LoginComponent,
    RecoveryComponent,
    ResetComponent,
    WelcomeComponent,
    ChangePasswordComponent,
  ],
  entryComponents: [ChangePasswordComponent]
})
export class AccountModule { }
