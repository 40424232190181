import { ToastrService } from 'ngx-toastr';
import { ErrorTags, errorMsgConfig } from './../../app.utils';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { appVersion } from 'app/app.version';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ServerConstants } from 'app/shared/constants';
import { request } from '../../../../worker/utils';
import { take } from 'rxjs/operators';
import { CaseService } from 'app/shared/api.services/case.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';
import { MatSnackBar } from '@angular/material';
import { ApiError, logToSentry, getTitleCase } from 'app/app.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  now = new Date();
  token: string;
  version = appVersion;
  appstoreUrl = environment.appstore_url;
  playstoreUrl = environment.playstore_url;
  browserName;
  download: {
    input?: string,
    file?: File,
    state?: string,
    status?: string,
    message?: string,
    progressValue?: string,
    downloadedBytes?: number,
    totalBytes?: number,
    processor?: any,
  } = {};
  otpForm: FormGroup;
  activateResendOtp = false;
  timeout = 120;
  isMultipleCodeRequest = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private caseService: CaseService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private toastr: ToastrService) {
    this.getBrowserData();
  }

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      this.token = params['token'];
      if (!this.token) {
        return this.router.navigateByUrl('/');
      }
    });
    this.download.state = 'loading';
    this.download.message = `Connecting...`;
    this.getVerificationToken(this.token);
  }

  private getVerificationToken(token) {
    this.caseService.generateVerificationToken(token).subscribe((res: any) => {
      this.otpForm = this.formBuilder.group({
        confirmation: ['', [Validators.required, this.confirmationValidator()]]
      });
      this.download.state = 'verification';
      this.isMultipleCodeRequest = res.data.code_requests > 1;
      setTimeout(() => {
        this.activateResendOtp = true;
      }, 120000);
      this.timeCount();
    }, (err: HttpErrorResponse) => {
      this.router.navigateByUrl(`/download?token=${this.token}`);
      this.download.state = 'errored';
      if (err.error.error.name === ApiError.InvalidToken) {
        this.download.message = 'Invalid download! Please contact the person who shared this link with you.';
      } else if (err.error.error.name === ApiError.DownloadNotFound) {
        this.download.message = 'Sorry, the requested download is not found! Please contact the person who shared this link with you.';
      } else if (err.error.error.name === ApiError.ResendNotPermitted) { // request made before resend time limit
        this.router.navigateByUrl(`/download?token=${this.token}`);
        this.otpForm = this.formBuilder.group({
          confirmation: ['', [Validators.required, this.confirmationValidator()]]
        });
        this.download.state = 'verification';
        this.isMultipleCodeRequest = this.isMultipleCodeRequest;
        setTimeout(() => {
          this.activateResendOtp = true;
        }, 120000);
        this.timeCount();
      } else {
        this.download.message = 'Something went wrong! Please contact the person who shared this link with you.';
      }
      logToSentry(err, ErrorTags.DownloadError);
      const err_msg = err.error ? err.error.error.message :
        err.message ? err.message : err;
      this.logExternalDownloadError(this.token, err_msg);
    });
  }

  private confirmationValidator() {
    return (control: FormControl) => {
      if (control.value.length === 6) {
        return null;
      } else {
        return { 'confirmation_error': true }
      }
    };
  }

  private timeCount() {
    if (this.timeout !== 0) {
      this.timeout--;
      setTimeout(() => {
        this.timeCount();
      }, 1000);
    }
  }

  getBrowserData() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.browserName = deviceInfo.browser;
  }

  onSubmit() {
    this.download.state = 'loading';
    const confirmationCode = this.otpForm.getRawValue().confirmation;
    this.authService.verifyConfirmationCode(confirmationCode, this.token).subscribe(res => {
      // this.download.state = 'click';
      this.downloadFile();
    }, (err: HttpErrorResponse) => {
      if (err.error.error.name === ApiError.ValidationLimitReached) {
        this.router.navigateByUrl(`/download?token=${this.token}`);
        this.download.state = 'ValidationError';
      } else {
        this.router.navigateByUrl(`/download?token=${this.token}`);
        this.download.state = 'verification';
        this.snackBar.open(`${getTitleCase('Code is invalid or inactive!')}`, null, {
          duration: 3000
        });
      }
      logToSentry(err, ErrorTags.DownloadError);
      const err_msg = err.error ? err.error.error.message :
        err.message ? err.message : err;
      this.logExternalDownloadError(this.token, err_msg);
      console.log(err);
    });
  }

  onResendCode() {
    this.download.state = 'loading';
    this.authService.resendVerificationCode(this.token, 'download').subscribe((res: any) => {
      this.download.state = 'verification';
      this.timeout = 120;
      this.activateResendOtp = false;
      this.isMultipleCodeRequest = res.data.code_requests > 1;
      setTimeout(() => {
        this.activateResendOtp = true;
      }, 120000);
      this.timeCount();
    }, err => {
      this.router.navigateByUrl(`/download?token=${this.token}`);
      this.download.state = 'errored';
      if (err.error.error.name === ApiError.ResendNotPermitted) { // request made before resend time limit
        this.router.navigateByUrl(`/download?token=${this.token}`);
        this.otpForm = this.formBuilder.group({
          confirmation: ['', [Validators.required, this.confirmationValidator()]]
        });
        this.download.state = 'verification';
        this.isMultipleCodeRequest = this.isMultipleCodeRequest;
        setTimeout(() => {
          this.activateResendOtp = true;
        }, 120000);
        this.timeCount();
      } else {
        this.download.message = 'Something went wrong! Please contact the person who shared this link with you.';
      }
      logToSentry(err, ErrorTags.DownloadError);
      const err_msg = err.error ? err.error.error.message :
        err.message ? err.message : err;
      this.logExternalDownloadError(this.token, err_msg);
      console.log(err);
    });
  }

  async downloadFile() {
    this.caseService.downloadWithToken(this.token).subscribe((res: any) => {
      if (res.status) {
        if (res.data.download_url) {
          this.downloadWithUrl(res.data.download_url);
        } else {
          this.download.state = 'loading';
          this.download.message = 'Preparing your download, please wait. The download will start automatically.';
          this.downloadByZip();
        }
      }
    }, async (err: HttpErrorResponse) => {
      this.router.navigateByUrl(`/download?token=${this.token}`);
      this.download.status = 'failed';
      this.download.state = 'errored';
      // this.download.message = 'File already downloaded or not found';
      switch (err.error.error.name) {
        case ApiError.InvalidToken:
          this.download.message = 'Provided token is invalid or expired! Please contact the person who shared this link with you.';
          break;
        case ApiError.DownloadNotFound:
          this.download.message = 'Requested download is not found! Please contact the person who shared this link with you.';
          break;
        case ApiError.FileDeleted:
          this.download.message = 'Requested file has been deleted! Please contact the person who shared this link with you.';
          break;
        default:
          this.download.message = 'File already downloaded or not found';
      }
      logToSentry(err, ErrorTags.DownloadError);
      const err_msg = err.error ? err.error.error.message :
        err.message ? err.message : err;
      await this.logExternalDownloadError(this.token, err_msg);
      return throwError(err);
    });
  }

  // not being used
  private async downloadByZip() {
    try {
      const res = await request({
        url: `${environment.api_url}${ServerConstants.Cases}/downloadzip/external`,
        headers: {
          authorization: this.token,
        }
      });
      if (res.status) {
        if (res.data.download_url) {
          this.downloadWithUrl(res.data.download_url);
        } else {
          this.download.state = 'loading';
          this.download.message = 'Preparing your download, please wait. The download will start automatically.';
          setTimeout(() => {
            this.downloadByZip();
          }, 2000);
        }
      }
    } catch (err) {
      this.router.navigateByUrl(`/download?token=${this.token}`); // to-do handle in a better way
      this.download.status = 'failed';
      this.download.state = 'errored';
      this.download.message = 'File already downloaded or not found';
      logToSentry(err, ErrorTags.DownloadError);
      const err_msg = err.error ? err.error.error.message :
        err.message ? err.message : err;
      await this.logExternalDownloadError(this.token, err_msg);
      return throwError(err);
    }
  }

  private downloadWithUrl(url) {
    const timeoutId = setTimeout(() => {
      this.download.state = 'connecting';
      if (this.browserName === 'Chrome') {
        this.download.message = `The files are currently being downloaded to your computer. The download progress can be seen in the bottom
          left corner
          of the browser.`;
      } else if (this.browserName === 'Firefox') {
        this.download.message = `The files are currently being downloaded to your computer. The download progress can be seen in the top
          right corner
          of the browser.`;
      } else if (this.browserName === 'IE' || this.browserName === 'MS-Edge') {
        this.download.message = `The files are currently being downloaded to your computer.
        The download progress can be seen at the bottom of the browser.`;
      } else {
        this.download.message = `The files are currently being downloaded to your computer.
        The download progress can be seen in the download section of the browser settings.`;
      }
    }, 2000);
    // this.caseService.downloadWithLink(this.authService.token);
    // this.download.processor
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.setAttribute('style', 'width:0;height:0;display:none;');
    iframe.src = url;

    iframe.onload = () => {
      // failed
      clearTimeout(timeoutId);
      this.download.state = 'completed';
      this.download.status = 'failed';
      this.download.message = 'Sorry, download failed! Please try again later.';
      logToSentry(`Download failed for the url: ${url}`);
      this.logExternalDownloadError(this.token, `Download failed, url: ${url}`)
    };
  }

  private async logExternalDownloadError(token, message) {
    try {
      await request({
        url: `${environment.api_url}${ServerConstants.Cases}/download/external/log`,
        method: 'POST',
        headers: {
          authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: message
        })
      });
    } catch (err) {
      this.router.navigateByUrl(`/download?token=${this.token}`); // to-do handle in a better way
      logToSentry(err, ErrorTags.DownloadError);
      return throwError(err);
    }
  }
}
