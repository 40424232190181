var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as mime from 'mime';
import * as Sentry from '@sentry/browser';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'environments/environment';
// adding any new file extension needs a db check to covert any of the existing file to be in that category
const fileExtensionsByType = {
    photo: [
        '.jpg',
        '.jpeg',
        '.png',
        '.gif',
        '.bmp',
        '.heic',
        '.heif',
        '.svg',
        '.cr2',
        '.cr3',
        '.dng',
        '.rw2',
        '.raw'
    ],
    audio: [
        '.mp3',
        '.wav',
        '.aac',
        '.m4a',
        '.dss',
        '.ds2',
        '.wma'
    ],
    video: [
        '.mp4',
        '.mov',
        '.m4v',
        '.avi',
        '.qt',
        '.wmv',
        '.webm',
        '.ogg',
        '.3gp',
        '.g64x',
        '.asf',
        '.flv',
        '.swf',
        '.mkv',
        '.ts',
        '.mpg',
        '.mpeg',
        '.vob',
        '.dav'
    ],
    note: ['.txt'],
    document: [
        '.csv',
        '.pdf',
        '.doc',
        '.xls',
        '.ppt',
        '.docx',
        '.xlsx',
        '.pptx',
        '.tif',
        '.tiff',
        '.rtf'
    ],
    misc: [
        '.zip',
        '.iso',
        '.7z'
    ]
};
export var FileType;
(function (FileType) {
    FileType["Photo"] = "photo";
    FileType["Video"] = "video";
    FileType["Interview"] = "interview";
    FileType["Dictation"] = "dictation";
    FileType["Audio"] = "audio";
    FileType["Note"] = "note";
    FileType["Document"] = "document";
})(FileType || (FileType = {}));
export var SelectionState;
(function (SelectionState) {
    SelectionState["NONE"] = "none";
    SelectionState["FEW"] = "few";
    SelectionState["PAGE"] = "page";
    SelectionState["ALL"] = "all";
})(SelectionState || (SelectionState = {}));
;
export var Status;
(function (Status) {
    Status[Status["ACTIVE"] = 1] = "ACTIVE";
    Status[Status["DELETED"] = 2] = "DELETED";
    Status[Status["ARCHIVED"] = 3] = "ARCHIVED";
    Status[Status["INACTIVE"] = 4] = "INACTIVE";
    Status[Status["FAILED"] = 6] = "FAILED";
    Status[Status["MERGED"] = 7] = "MERGED";
    Status[Status["UPLOADING"] = 8] = "UPLOADING";
    Status[Status["PROCESSING"] = 9] = "PROCESSING";
    Status[Status["VERIFIED"] = 10] = "VERIFIED";
    Status[Status["MALICIOUS"] = 11] = "MALICIOUS";
})(Status || (Status = {}));
;
export var AuthStatus;
(function (AuthStatus) {
    AuthStatus["MFA"] = "mfa";
    AuthStatus["ACCOUNT_LOCKED"] = "account_locked";
    AuthStatus["RESET_PASSWORD"] = "reset_password";
})(AuthStatus || (AuthStatus = {}));
;
export var UserPreferenceName;
(function (UserPreferenceName) {
    UserPreferenceName["DownloadZippingMedia"] = "download_zip_with_media_type_folders";
    UserPreferenceName["RetainFileName"] = "retain_file_name";
})(UserPreferenceName || (UserPreferenceName = {}));
;
export var UserNotificationName;
(function (UserNotificationName) {
    UserNotificationName["SharedCasesNotification"] = "shared_cases";
    UserNotificationName["NewCaseNotification"] = "new_case";
    UserNotificationName["NewAudioNotification"] = "new_audio_file";
    UserNotificationName["NewVideoNotification"] = "new_video_file";
    UserNotificationName["NewDocumentNotification"] = "new_doc_file";
    UserNotificationName["NewDictationNotification"] = "new_dictation";
    UserNotificationName["DownloadConfirmationNotification"] = "download_confirmation";
})(UserNotificationName || (UserNotificationName = {}));
export var UserPreferenceDescription;
(function (UserPreferenceDescription) {
    UserPreferenceDescription["DownloadZippingMedia"] = "Download Zip With Media Type Folders";
    UserPreferenceDescription["RetainFileName"] = "Retain File Name for Downloads";
})(UserPreferenceDescription || (UserPreferenceDescription = {}));
;
export var UserNotificationDescription;
(function (UserNotificationDescription) {
    UserNotificationDescription["SharedCasesNotification"] = "Shared Cases";
    UserNotificationDescription["NewCaseNotification"] = "New Case";
    UserNotificationDescription["NewAudioNotification"] = "New Audio File";
    UserNotificationDescription["NewVideoNotification"] = "New Video File";
    UserNotificationDescription["NewDocumentNotification"] = "New Document";
    UserNotificationDescription["NewDictationNotification"] = "New Dictation";
    UserNotificationDescription["DownloadConfirmationNotification"] = "Shared Case Downloaded";
})(UserNotificationDescription || (UserNotificationDescription = {}));
export var ApiError;
(function (ApiError) {
    ApiError["AccountLimitReached"] = "AccountLimitReached";
    ApiError["ValidationLimitReached"] = "ValidationLimitReached";
    ApiError["StreamRunning"] = "StreamRunning";
    ApiError["InvalidToken"] = "InvalidToken";
    ApiError["DownloadNotFound"] = "DownloadNotFound";
    ApiError["ResendNotPermitted"] = "ResendNotPermitted";
    ApiError["FolderAlreadyExists"] = "FolderAlreadyExists";
    ApiError["FolderLimitReached"] = "FolderLimitReached";
    ApiError["InvalidCredentials"] = "InvalidCredentials";
    ApiError["LicenseExpired"] = "LicenseExpired";
    ApiError["FolderNotFound"] = "FolderNotFound";
    ApiError["CaseNotFound"] = "CaseNotFound";
    ApiError["FileNotFound"] = "FileNotFound";
    ApiError["SourceFileNotFoun"] = "SourceFileNotFoun";
    ApiError["NotAuthorized"] = "NotAuthorized";
    ApiError["ParameterError"] = "ParameterError";
    ApiError["FileDeleted"] = "FileDeleted";
    ApiError["AccountNotFound"] = "AccountNotFound";
    ApiError["GroupNotFound"] = "GroupNotFound";
    ApiError["ExpiredValidationCode"] = "ExpiredValidationCode";
    ApiError["InvalidValidationCode"] = "InvalidValidationCode";
    ApiError["PasswordRequirementNotSatisfied"] = "PasswordRequirementNotSatisfied";
    ApiError["InvalidEmailAddress"] = "InvalidEmailAddress";
    ApiError["InvalidRequestData"] = "InvalidRequestData";
})(ApiError || (ApiError = {}));
export var SelectedTab;
(function (SelectedTab) {
    SelectedTab["All"] = "all_cases";
    SelectedTab["SHARED"] = "shared_cases";
    SelectedTab["AGENCY"] = "agency_cases";
    SelectedTab["MY"] = "my_cases";
})(SelectedTab || (SelectedTab = {}));
;
const ɵ0 = a => fileExtensionsByType[a];
export const allowedFileExtensions = Array.prototype.concat.apply([], Object.keys(fileExtensionsByType).map(ɵ0));
function getTypeByExt(ext) {
    return Object.keys(fileExtensionsByType)
        .find((group) => fileExtensionsByType[group].includes(`.${ext.toLowerCase()}`)) || 'misc';
}
export function getFileType(file) {
    const dotpos = file.name.lastIndexOf('.');
    const ext = file.name.split('.').pop();
    const file_type = (dotpos < 0 || !ext) ? 'misc' : getTypeByExt(ext);
    if (file_type === 'unknown') {
        Sentry.setExtra('data', { mimetype: file.type, name: file.name, size: file.size, ext: mime.getExtension(file.type) });
        Sentry.captureMessage('UNKNOWN_FILE_TYPE', Sentry.Severity.Info);
    }
    return file_type;
}
export function getFileExtensionLength(fileName) {
    const dotpos = fileName.lastIndexOf('.');
    const ext = fileName.split('.').pop();
    return (dotpos < 0 || !ext) ? 0 : ext.length;
}
export class MyErrorStateMatcher {
    isErrorState(control, form) {
        const isSubmitted = form && form.submitted;
        return (control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}
export function request(req) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(req.method || 'GET', req.url);
            if (req.headers) {
                Object.keys(req.headers).forEach(key => {
                    xhr.setRequestHeader(key, req.headers[key]);
                });
            }
            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.response);
                }
                else {
                    reject(xhr.statusText);
                }
            };
            xhr.onerror = () => reject(xhr.statusText);
            xhr.send(req.body);
        });
    });
}
;
export function getFileSignature(file) {
    // The key tries to give a signature to a file in the absence of its path.
    // "<filename>-<mimetype>-<modifieddate>-<filesize>"
    return [
        file.name,
        file.type,
        file.lastModified,
        file.size
    ].join('-');
}
export function logToSentry(error, tag) {
    if (tag) {
        Sentry.configureScope((scope) => {
            scope.setTag('error-name', tag);
        });
        Sentry.captureException(error.error || error.message || error.originalError || error);
    }
    else {
        if (error.indexOf('Download failed') !== -1) {
            Sentry.configureScope((scope) => {
                scope.setTag('error-name', 'download-error');
                scope.setLevel(Sentry.Severity.Error);
            });
        }
        else {
            Sentry.configureScope((scope) => {
                scope.setLevel(Sentry.Severity.Error);
            });
        }
        Sentry.captureMessage(error);
    }
}
export const asciiCheck = /^[ -~\t\n\r]+$/;
export const urlSafeCheck = /[^a-z0-9\-_.]/gi;
export const maxCharLength = 255;
export const maxFileExtLength = 16;
export const pwdCharLength = 20;
export function decodeName(nameString, returnVal) {
    const last_pos = nameString.lastIndexOf('$');
    if (returnVal === 'name') {
        if (last_pos < 0) {
            return nameString;
        }
        else {
            return nameString.substr(0, last_pos);
        }
    }
    if (returnVal === 'id') {
        if (last_pos >= 0) {
            const id_info = nameString.split('$')[(nameString.split('$')).length - 1];
            return id_info.split('.')[0];
        }
    }
    if (returnVal === 'parent_id') {
        if (last_pos >= 0) {
            const id_info = nameString.split('$')[(nameString.split('$')).length - 1];
            return id_info.split('.')[1];
        }
    }
    if (returnVal === 'files') {
        if (last_pos >= 0) {
            const id_info = nameString.split('$')[(nameString.split('$')).length - 1];
            return id_info.split('.')[2] !== 'null' ? id_info.split('.')[2] : null;
        }
    }
}
export function URLSafeName(name) {
    // Remove multiple spaces
    let safe_name = name.replace(/[ ]{2,}/gi, '_');
    // Remove unsafe characters from name
    safe_name = safe_name.replace(/[^a-z0-9\-_.]/gi, '_');
    return safe_name;
}
;
export var ErrorTags;
(function (ErrorTags) {
    ErrorTags["DownloadError"] = "download-error";
    ErrorTags["CaseLoadError"] = "case-load-error";
    ErrorTags["NoteError"] = "note-error";
    ErrorTags["StreamingError"] = "streaming-error";
    ErrorTags["DeleteError"] = "delete-error";
    ErrorTags["ServerStatusError"] = "server-status-error";
    ErrorTags["UploadError"] = "upload-error";
})(ErrorTags || (ErrorTags = {}));
;
export function isExternalRequest(url) {
    const extReqUrlSet = {
        DownLoadWithToken: `${environment.api_url}cases/download?access_token=`,
    };
    let isExtReq = false;
    for (var key in extReqUrlSet) {
        if (url.indexOf(extReqUrlSet[key]) !== -1) {
            isExtReq = true;
        }
    }
    return isExtReq;
}
export function removeNonASCII(value) {
    return value ? value.replace(/[^\x00-\x7F]/g, '') : '';
}
export const paginatorConfig = {
    pageSizeOptions: [25, 50, 75, 100],
    defaultPageSize: 25,
    oldPageSizeOptions: [10, 30]
};
// file name encoding/decoding for unique file signature
// keeping another copy of "decodeFileUploadName" func in the
// worker/uploader/Util.ts file
const fileEncodingString = '@';
export function getEncodedFileUploadName(name) {
    return `${name}${fileEncodingString}${uuidv4()}`;
}
export function getDecodedFileUploadName(name) {
    const last_pos = name.lastIndexOf(fileEncodingString);
    if (last_pos < 0) {
        return name;
    }
    else {
        return name.substr(0, last_pos);
    }
}
export var FileViewerError;
(function (FileViewerError) {
    FileViewerError["onLoadError"] = "onLoadError";
    FileViewerError["noPreview"] = "noPreview";
})(FileViewerError || (FileViewerError = {}));
export function getTitleCase(input) {
    return input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}
export var invalidUploadReason;
(function (invalidUploadReason) {
    invalidUploadReason["zeroByteFile"] = "zeroByteFile";
    invalidUploadReason["invalidName"] = "inValidName";
})(invalidUploadReason || (invalidUploadReason = {}));
export const errorMsgConfig = {
    closeButton: true,
    positionClass: 'toast-bottom-right',
    disableTimeOut: true,
    tapToDismiss: true
};
export const successMsgConfig = {
    closeButton: true,
    positionClass: 'toast-bottom-center',
    // disableTimeOut: true
    timeOut: 4000
};
export const weeklyWebinarLink = 'https://docs.google.com/forms/d/e/1FAIpQLSfcd8l4mKk2bWs3pkUaxGh5N39vmV4lelleXJapwMWmzCem9w/viewform?usp=sf_link';
const copyRightStringPatterns = [
    'icrime',
    'icf',
    'icrimefighter',
    'atscene',
    'at-scene',
];
const charRepetitionRegex = /(.)\1{4,}/; // detect the occurence of any character more than 4 times
export function passwordValidator(password, confirmed_password, user) {
    if (password.trim() === '') {
        return { 'whiteSpace': true };
    }
    else if (password.length < pwdCharLength) { // password charecters length check
        return { 'smallPwd': true };
    }
    else if ((password.toLowerCase().indexOf(user.first_name.toLowerCase()) > -1
        || password.toLowerCase().indexOf(user.last_name.toLowerCase()) > -1
        || password.toLowerCase().indexOf(user.email_address.toLowerCase()) > 1)) { // password string should not contain username or email
        return { 'nameEmailMatch': true };
    }
    else if (copyRightStringPatterns.some(val => password.toLowerCase().indexOf(val) > -1)) { // password contails service terms
        return { 'serviceTermUsed': true };
    }
    else if (charRepetitionRegex.test(password)) { // repetitive characters in password
        return { 'repetitiveChar': true };
    }
    else if (confirmed_password.trim() === '' || (confirmed_password.trim() !== '' && password !== confirmed_password)) {
        return { 'mismatch': true };
    }
    else {
        return null;
    }
}
export { ɵ0 };
