import { successMsgConfig } from './../../app.utils';
import { ToastrService } from 'ngx-toastr';
import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { LocalStorage } from 'ngx-webstorage';
import { MatSnackBar } from '@angular/material';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  @LocalStorage('anyNewUpdate', false) anyNewUpdate;

  constructor(
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    private snackBar: MatSnackBar,
    private toastr: ToastrService) {
    if (!swUpdate.isEnabled) { return; }

    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const timeIntervalOnceAppIsStable$ = concat(appIsStable$, interval(environment.update_check_interval));
    timeIntervalOnceAppIsStable$.subscribe(() => {
      console.log('checking for updates....')
      this.swUpdate.checkForUpdate().then(() => {
        this.checkForUpdates();
      });
    });

    if (this.anyNewUpdate) { this.displayUpdateMessage(); }
  }

  public checkForUpdates(): void {
    if (!this.swUpdate.isEnabled) { return; }
    this.swUpdate.available.subscribe((event: UpdateAvailableEvent) => {
      if (event.available.hash !== event.current.hash) {
        this.swUpdate.activateUpdate().then(() => {
          this.anyNewUpdate = true;
          this.displayUpdateMessage();
        });
      }
    });
  }

  private displayUpdateMessage(): void {
    // this.snackBar.open('Update Available!', 'RELOAD', {
    // }).onAction().subscribe(() => {
    //   this.snackBar.dismiss();
    //   this.anyNewUpdate = false;
    //   window.location.reload(true);
    // });

    /* instead of displaying the prompt, just reload the page */
    this.anyNewUpdate = false;
    window.location.reload();
  }
}
