import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { each } from 'lodash';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  @Input() private allowed_extensions: Array<string> = [];
  @Input() private enabled = true;
  @Output() private onFolderDropped: EventEmitter<any> = new EventEmitter();
  @Output() private onFileDropped: EventEmitter<File[]> = new EventEmitter();
  @Output() private onInvalidFileDropped: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = '#FFFFFF';

  constructor() { }

  @HostListener('dragover', ['$event']) public onDragOver(evt) {
    if (!this.enabled) { return; }

    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#EEEEEE';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    if (!this.enabled) { return; }

    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FFFFFF'
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {
    if (!this.enabled) { return; }

    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FFFFFF';

    const items = evt.dataTransfer.items;
    const folderEntries = [];
    const fileEntries = [];
    let isFileOnly: boolean = true;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === 'file') {
        const entry = item.webkitGetAsEntry();
        if (entry.isDirectory) {
          isFileOnly = false;
          break;
        }
      }
    }

    if (isFileOnly) { // only files dropped
      const files = evt.dataTransfer.files;
      this.onFileDropped.emit(files);
      /* ext restriction for upload is removed, keeping it for future ref */
      // if (this.allowed_extensions.length > 0) {
      //   const valid_files: Array<File> = [];
      //   const invalid_files: Array<File> = [];
      //   if (files.length > 0) {
      //     each(files, (file: File) => {
      //       const ext = file.name.split('.')[file.name.split('.').length - 1];
      //       if (this.allowed_extensions.lastIndexOf(ext) !== -1) {
      //         valid_files.push(file);
      //       } else {
      //         invalid_files.push(file);
      //       }
      //     });
      //     this.onFileDropped.emit(valid_files);
      //     this.onInvalidFileDropped.emit(invalid_files);
      //   }
      // } else {
      //   this.onFileDropped.emit(files);
      // }

    } else { // folders or folders + files dropped
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file') {
          const entry = item.webkitGetAsEntry();
          if (entry.isFile) {
            fileEntries.push(entry);
          } else if (entry.isDirectory) {
            folderEntries.push(entry);
          }
        }
      }

      if (fileEntries.length) {
        this.onFileDropped.emit(fileEntries);
      }
      if (folderEntries.length) {
        this.onFolderDropped.emit(folderEntries);
      }
    }
  }
}
