<div class="container">
  <div class="container-header">
    <img src="../../../../assets/icons/account_circle.svg" alt="account-icon" />
    <div class="user-section">
      <div class="user-section-name">
        {{ user?.first_name | truncate:[15] }}&nbsp;{{ user?.last_name | truncate:[15] }}
      </div>
      <div class="user-section-email">
        {{user?.email_address}}
      </div>
      <div class="user-section-agency">
        {{user?.agency?.name}}
      </div>
    </div>
  </div>
  <!-- <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar> -->
  <div class="container-body">
    <mat-tab-group class="my-profile-tab">
      <mat-tab [label]="firstTabName" style="height: 120px;">
        <div align="center" style="margin: 90px;" *ngIf="isLoading">
          <mat-spinner color="primary" diameter="60"></mat-spinner>
        </div>
        <div class="my-permissions" *ngIf="!isLoading">
          <div class="permission" *ngIf="casePermissions.length > 0">
            <mat-form-field class="width-100">
              <mat-chip-list #chipList aria-label="Permission selection" placeholder="Case Permissions">
                <mat-chip *ngFor="let permission of casePermissions" selectable=true>
                  {{permission.desc}}
                  <mat-icon *ngIf="permission.name === CasePermissions.CASE_DOWNLOAD" matChipRemove>cloud_download
                  </mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.CASE_EDIT" matChipRemove>edit</mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.CASE_MERGE" matChipRemove>call_merge
                  </mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.CASE_PRINT" matChipRemove>print</mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.DELETE_RESTORE" matChipRemove>delete
                  </mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.ARCHIVE_UNARCHIVE" matChipRemove>archive
                  </mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.CASE_UPLOAD" matChipRemove>cloud_upload
                  </mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.RESTRICT_CASE" matChipRemove>lock</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="permission" *ngIf="sharingpermissions.length > 0">
            <mat-form-field class="width-100">
              <mat-chip-list #chipList aria-label="Permission selection" placeholder="Sharing Permissions">
                <mat-chip *ngFor="let permission of sharingpermissions" selectable=true removable=true
                  (removed)="remove(permission)">
                  {{permission.desc}}
                  <mat-icon *ngIf="permission.name === CasePermissions.VIEW_SHARED_CASE" matChipRemove>visibility
                  </mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.CASE_SHARE_AGENCY" matChipRemove>share
                  </mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.CASE_SHARE_EMAIL" matChipRemove>email
                  </mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="permission" *ngIf="adminPermissions.length > 0">
            <mat-form-field class="width-100">
              <mat-chip-list #chipList aria-label="Permission selection" placeholder="Admin Permissions">
                <mat-chip *ngFor="let permission of adminPermissions" selectable=true removable=true>
                  {{permission.desc}}
                  <mat-icon *ngIf="permission.name === CasePermissions.USER_MANAGEMENT" matChipRemove>people
                  </mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.AGENCY_UPDATE" matChipRemove>update
                  </mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.SUPER_ADMIN" matChipRemove>admin_panel_settings
                  </mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="permission" *ngIf="loginPermissions.length > 0">
            <mat-form-field class="width-100">
              <mat-chip-list #chipList aria-label="Permission selection" placeholder="Access Permissions">
                <mat-chip *ngFor="let permission of loginPermissions" selectable=true removable=true>
                  {{permission.desc}}
                  <mat-icon *ngIf="permission.name === CasePermissions.CLOUD_LOGIN" matChipRemove>language</mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.MOBILE_LOGIN" matChipRemove>phone_iphone
                  </mat-icon>
                  <mat-icon *ngIf="permission.name === CasePermissions.DESKTOP_LOGIN" matChipRemove>desktop_windows
                  </mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="permission" *ngIf="userGroups.length > 0">
            <mat-form-field class="width-100">
              <mat-chip-list #chipList aria-label="Permission selection" placeholder="My Groups">
                <mat-chip *ngFor="let group of userGroups" selectable=true removable=true>
                  {{group}}
                </mat-chip>
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="My Settings">
        <mat-progress-bar mode="indeterminate" *ngIf="settingChanging"></mat-progress-bar>
        <mat-table #table [dataSource]="dataSource">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let setting">
              <span *ngIf="setting.type !== 'notification'">{{setting.desc}}</span>
              <span *ngIf="setting.type === 'notification'">{{setting.desc}} Notification</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let setting">
              <mat-slide-toggle [checked]="setting.status" color="primary"
                (change)="onToggleNotifChange($event, setting)" [disabled]="settingChanging">
              </mat-slide-toggle>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>