import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
if (!navigator.cookieEnabled) {
  alert('Please enable cookies and site data in your browser settings and reload the page')
} else {
  platformBrowserDynamic().bootstrapModule(AppModule);
}
