import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { extend } from 'lodash';
import { HttpService } from '../http.service';
import { ServerConstants } from '../constants';
import { IFile } from '../../models/file';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class FileService {
  private baseUrl = ServerConstants.Files;
  private _files: BehaviorSubject<IFile[]>;
  public readonly files: Observable<IFile[]>;
  private dataStore: {
    files: IFile[]
  };
  constructor(
    private httpService: HttpService,
    private authService: AuthService) {
    this.dataStore = { files: [] };
    this._files = <BehaviorSubject<IFile[]>>new BehaviorSubject([]);
    this.files = this._files.asObservable();
  }

  get data(): IFile[] { return this._files.value; }

  getFileUrl(id: string) {
    return `${environment.api_url}${ServerConstants.Files}/${id}/object?access_token=${this.authService.token}`
  }

  get(id: string) {
    return this.files.pipe(map(cases => cases.find(item => item.id.toString() === id.toString())));
  }

  getFileStreams(stored_name: string) {
    return this.httpService.get(`${this.baseUrl}/${stored_name}/streaming`);
  }

  getNoteContent(id: string) {
    return this.httpService.get(`${this.baseUrl}/${id}/object`);
  }

  createStream(stored_name: string) {
    return this.httpService.post(`${this.baseUrl}/${stored_name}/createstream`);
  }

  loadAll(caseId: string, file_filter: string): any {

    const params = { case_id: caseId, filter: file_filter };

    return this.httpService.get(this.baseUrl, { params }).pipe(map(response => {
      this.dataStore.files = response['data'];
      this._files.next(Object.assign({}, this.dataStore).files);
      return response['data'];
    }));
  }

  load(id: string): Observable<IFile> {
    return this.httpService.get(`${this.baseUrl}/${id}`).pipe(map(response => {
      let notFound = true;

      this.dataStore.files.forEach((item, index) => {
        if (item.id === response['data'].id) {
          this.dataStore.files[index] = response['data'];
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.files.push(response['data']);
      }

      this._files.next(Object.assign({}, this.dataStore).files);
      return response['data'];
    }));
  }

  loadExternalFile(id: string) {
    return this.httpService.get(`${this.baseUrl}/${id}/external`).pipe(map(response => {
      let notFound = true;

      this.dataStore.files.forEach((item, index) => {
        if (item.id === response['data'].id) {
          this.dataStore.files[index] = response['data'];
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.files.push(response['data']);
      }

      this._files.next(Object.assign({}, this.dataStore).files);
      return response['data'];
    }));
  }

  create(obj: IFile) {
    return this.httpService.post(this.baseUrl, obj).pipe(map(response => {
      obj.id = response['data'].id;
      this.dataStore.files.push(obj);
      this._files.next(Object.assign({}, this.dataStore).files);
      return response;
    }));
  }

  update(obj: IFile) {
    return this.httpService.post(`${this.baseUrl}/${obj.id}`, obj).pipe(map(response => {
      this.dataStore.files.forEach((c, i) => {
        if (c.id === obj.id) { this.dataStore.files[i] = obj; }
      });

      this._files.next(Object.assign({}, this.dataStore).files);
      return response;
    }));
  }

  updateNoteContent(id: string, data) {
    return this.httpService.post(`${this.baseUrl}/${id}/note`, { data: data });
  }

  remove(id: string) {
    return this.httpService.delete(`${this.baseUrl}/${id}`).pipe(map(response => {
      this.dataStore.files.forEach((c, i) => {
        if (c.id === id) { this.dataStore.files.splice(i, 1); }
      });

      this._files.next(Object.assign({}, this.dataStore).files);
    }));
  }

  localRemove(id: string) {
    this.dataStore.files.forEach((c, i) => {
      if (c.id === id) { this.dataStore.files.splice(i, 1); }
    });

    this._files.next(Object.assign({}, this.dataStore).files);
  }

  copyToCase(src_case_id: string, dest_case_id: string, file_ids: string[]) {
    const params = { from_case: src_case_id, to_case: dest_case_id, file_ids: file_ids };
    return this.httpService.post(`${this.baseUrl}/copy`, params);
  }

  moveToCase(src_case_id: string, dest_case_id: string, file_ids: string[]) {
    const params = { from_case: src_case_id, to_case: dest_case_id, file_ids: file_ids, action: 'move' };
    return this.httpService.post(`${this.baseUrl}/copy`, params);
  }

  copyToFolder(src_case_id: string, dest_case_id: string, file_ids: string[], folder_id?: string) {
    const params: any = { from_case: src_case_id, to_case: dest_case_id, file_ids: file_ids };
    if (folder_id) {
      params.folder_id = folder_id;
    }
    return this.httpService.post(`${this.baseUrl}/copy`, params);
  }

  moveToFolder(src_case_id: string, dest_case_id: string, file_ids: string[], folder_id?: string) {
    const params: any = { from_case: src_case_id, to_case: dest_case_id, file_ids: file_ids, action: 'move' };
    if (folder_id) {
      params.folder_id = folder_id;
    }
    return this.httpService.post(`${this.baseUrl}/copy`, params);
  }

  bulkUpdates(fileIds: string[], action: string) {
    return this.httpService.patch(this.baseUrl, { file_ids: fileIds, action });
  }

  archiveFiles(f: IFile[]) {
    f.forEach((item, index) => {
      this.update(extend(item, { action: 'archive' })).subscribe();
    });
  }

  restoreFiles(f: IFile[]) {
    f.forEach((item, index) => {
      this.update(extend(item, { action: 'restore' })).subscribe();
    });
  }

  deleteFiles(f: IFile[]) {
    f.forEach((item, index) => {
      this.update(extend(item, { action: 'delete' })).subscribe();
    });
  }
}
