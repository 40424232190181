import { Pipe, PipeTransform } from '@angular/core';
import { getDecodedFileUploadName } from 'app/app.utils';

@Pipe({
  name: 'decodeFilename'
})
export class DecodeFilenamePipe implements PipeTransform {

  transform(value: string): any {
    if (value) {
      return getDecodedFileUploadName(value);
    } else {
      return '';
    }
  }

}
