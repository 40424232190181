import { Injectable } from '@angular/core';
import { find, isEmpty } from 'lodash'
import * as md5 from 'md5';
import * as Sentry from '@sentry/browser';
import { SessionStorage, LocalStorage } from 'ngx-webstorage';
import { User } from '../models/user';
import { UserService } from '../shared/api.services/user.service';
import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { HttpService } from 'app/shared/http.service';
import { ServerConstants } from 'app/shared/constants';
import * as moment from 'moment';

@Injectable()
export class AuthService {
  @LocalStorage('users') users;
  @SessionStorage('authToken', null) private authToken;
  @SessionStorage('access_token') access_token;
  @SessionStorage('entry') case_entry;
  redirectUrl: string;
  loadedUrl: string;
  user: User = null;
  private baseUrl = ServerConstants.Auth;

  constructor(private userService: UserService,
    private httpService: HttpService) { }

  register(res): Observable<boolean> {
    return of(res.data).pipe(tap(data => {
      this.user = data.user;
      this.authToken = data.session_token;
      this.handleSentryError();
      return data.session_token;
    }));
  }

  get status(): Observable<boolean> {
    if (!isEmpty(this.authToken)) {
      if (this.user !== null) {
        return of(true);
      } else {
        return this.userService.profile().pipe(map((response: any) => {
          this.user = response.data;
          this.handleSentryError();
          return true;
        }), catchError((err) => {
          this.authToken = null;
          this.user = null;
          return of(false);
        }));
      }
    } else {
      return of(false);
    }
  }

  get hash(): string {
    return md5(this.user.id);
  }

  get token(): string {
    return this.authToken;
  }

  /* update existing auth user */
  updateAuthUser() {
    this.userService.profile().subscribe((res: any) => {
      this.user = res.data;
      this.handleSentryError();
    }, err => {
      this.authToken = null;
      this.user = null;
    });
  }

  handleSentryError() {
    Sentry.configureScope((scope) => {
      scope.setUser({ 'username': this.user.email_address });
    });
  }

  setToken(token) {
    this.authToken = token;
  }

  verifyConfirmationCode(code, token) {
    const params = { code: code, access_token: token };
    return this.httpService.post(`${this.baseUrl}/validatecode`, params);
  }

  resendVerificationCode(token, action) {
    const params = { access_token: token, action: action };
    return this.httpService.post(`${this.baseUrl}/resendcode`, params);
  }

  clear() {
    this.authToken = null;

    if (this.user && this.users && this.users.some(user => user.email === this.user.email_address)) {
      const user = find(this.users, user => user.email === this.user.email_address);
      if (user.hasOwnProperty('search') && user.search.str.trim() !== ''
        || (user.search.from && moment(user.search.from).isValid())
        || (user.search.to && moment(user.search.to).isValid())) {
        user['search']['str'] = '';
        user['search']['from'] = null;
        user['search']['to'] = null;
        this.users = this.users;
      }
    }

    this.user = null;
    if (this.access_token) {
      this.access_token = null;
      this.case_entry = null;
    }
  }

  logout(): Observable<any> {
    const payload = { access_token: this.authToken };
    this.clear();
    return this.userService.logout(payload);
  }
}
