import { MatSnackBar } from '@angular/material';
import { AuthStatus, passwordValidator } from './../../app.utils';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../shared/api.services/user.service';
import { SessionStorage } from 'ngx-webstorage';
import { AuthService } from '../../auth/auth.service';
import * as md5 from 'md5';
import { EmailService } from 'app/shared/api.services/email.service';
import { appVersion } from 'app/app.version';
import { take, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MyErrorStateMatcher, getTitleCase } from 'app/app.utils';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  @SessionStorage('redirect_to', null) private redirectTo;
  version = appVersion;
  now = new Date();
  loading = true;
  activationError = false;
  activationSuccess = false;
  visiblePassword = false;
  visibleConfirmation = false;
  user = null;
  accessToken = null;
  form: FormGroup;
  pwd = null;
  token;
  tokenFlag = '';
  appstoreUrl = environment.appstore_url;
  playstoreUrl = environment.playstore_url;

  matcher = new MyErrorStateMatcher();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private authService: AuthService,
    private emailService: EmailService) {

    this.form = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      password_confirmation: new FormControl('', [])
    });

    this.password.valueChanges.subscribe(value => {
      this.pwd = value
    });
  }

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      const token = params['token'];
      this.token = token;
      if (!token) {
        return this.router.navigateByUrl('/');
      }

      this.verifyToken(token);
    });
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get passwordConfirmation(): AbstractControl {
    return this.form.get('password_confirmation');
  }

  verifyToken(token: string) {
    this.authService.logout();
    this.userService.verify(token).pipe(catchError((err) => {
      this.loading = false;
      this.activationError = true;
      return throwError(err);
    })).subscribe((res: any) => {
      this.loading = false;
      this.user = res.data.user;
      this.accessToken = res.data.user_token;

      // add the password validatior in the form
      this.form.setValidators([Validators.required, (g: FormGroup) => {
        const pwd_value = g.get('password').value;
        const cnf_value = g.get('password_confirmation').value;
        return passwordValidator(pwd_value, cnf_value, this.user);
      }]);
    });
  }

  onSubmit() {
    if (!this.form.valid) { return; }

    this.loading = true;
    this.userService.passwordUpdate({
      id: this.user.id,
      password: this.password.value,
      access_token: this.accessToken,
      activation_hash: md5(this.accessToken)
    }).pipe(catchError((err) => {
      this.loading = false;
      this.activationError = true;
      return throwError(err);
    })).subscribe((res: any) => {
      this.loading = false;
      if (res.data && res.data.status === AuthStatus.RESET_PASSWORD) {
        this.snackBar.open(`${getTitleCase(`This password is part of hacked passwords. please choose a stronger password.`)}`, 'CLOSE', {
          panelClass: 'snackbar-error',
          horizontalPosition: 'end',
        }).onAction().subscribe(() => {
          this.snackBar.dismiss();
        });
      } else {
        this.activationSuccess = true;
        this.redirectTo = '/';
      }
    });
  }

  onResend() {
    const token = { 'token': this.token }
    this.emailService.postData(token).pipe(catchError((err) => {
      this.tokenFlag = 'failure';
      return throwError(err);
    })).subscribe((res: any) => {
      if (res.status === true) {
        this.tokenFlag = 'success';
      } else {
        this.tokenFlag = 'failure';
      }
    })
  }
}
