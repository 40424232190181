import { HttpService } from './../http.service';
import { ServerConstants } from 'app/shared/constants';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private baseUrl = ServerConstants.Email;

  constructor(private http: HttpService) { }

  postData(data) {
    return this.http.post(`${this.baseUrl}/resend`, data);
  }
}
