export const environment = {
  mode: 'staging',
  production: true,
  isATS: false,
  api_url: 'https://api-stg.icrimefighter.com/v2/',
  deploy_url: 'https://cloud-stg.icrimefighter.com/',
  edp_url: 'https://test.icrimefighter.com/emp/',
  streaming_url: 'https://streaming-stg.icrimefighter.com',
  sentry_dsn: 'https://2dcef5370f85495abe1185980fe0cda7@o34544.ingest.sentry.io/1294381',
  google_api_key: 'AIzaSyACOQ1PWxpfSpJ8svjWc7ph1seyNLzvPm8',
  product_id: 'f99b2d9f-d5c9-43dd-9f26-e34563c3a95b',
  support_email: 'icfsupport@getnada.com',
  appstore_url: 'https://apps.apple.com/us/app/icrimefighter-pro/id1459355579',
  playstore_url: 'https://play.google.com/store/apps/details?id=com.icrimefighter',
  icf_classic_url: 'https://www.icrimefighter.com/edp/index.php/home/login',
  app_site_key: '6LetzbUUAAAAAFo1jF7UejtBaXwx7zPnxpYMyFBz',
  aws_region: 'us-east-1',
  update_check_interval: 1 * 60 * 1000,
  server_status_url: 'https://at-scene.github.io/status/status.json'
};