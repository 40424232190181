import { NotauthorizedComponent } from './notauthorized/notauthorized.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotfoundComponent } from './notfound/notfound.component';
import { DownloadComponent } from './download/download.component';
import { UploadComponent } from './upload/upload.component';
import { NotsupportedComponent } from './notsupported/notsupported.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LicenseComponent } from './license/license.component';

const routes: Routes = [
  { path: 'download', component: DownloadComponent },
  { path: 'upload', component: UploadComponent },
  { path: 'notauthorized', component: NotauthorizedComponent },
  { path: 'notsupported', component: NotsupportedComponent },
  { path: 'legal/privacypolicy', component: PrivacyComponent },
  { path: 'legal/licenseagreement', component: LicenseComponent },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticRoutingModule { }
