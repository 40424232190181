/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./app.component";
import * as i5 from "./auth/auth.service";
import * as i6 from "./shared/update.service/update.service";
import * as i7 from "ngx-device-detector";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./shared/api.services/upload.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵprd(512, null, i3.LocationStrategy, i3.PathLocationStrategy, [i3.PlatformLocation, [2, i3.APP_BASE_HREF]]), i1.ɵprd(512, null, i3.Location, i3.Location, [i3.LocationStrategy, i3.PlatformLocation]), i1.ɵdid(3, 49152, null, 0, i4.AppComponent, [i3.Location, i5.AuthService, i6.UpdateService, i7.DeviceDetectorService, i2.Router, i8.Title, i9.UploadService, i3.DOCUMENT], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i4.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
