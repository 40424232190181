/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subscription-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./subscription-dialog.component";
var styles_SubscriptionDialogComponent = [i0.styles];
var RenderType_SubscriptionDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubscriptionDialogComponent, data: {} });
export { RenderType_SubscriptionDialogComponent as RenderType_SubscriptionDialogComponent };
export function View_SubscriptionDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["Your ", " subscription has expired"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.isATS ? "At-Scene" : "iCrimeFighter"); _ck(_v, 2, 0, currVal_1); }); }
export function View_SubscriptionDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-subscription-dialog", [], null, null, null, View_SubscriptionDialogComponent_0, RenderType_SubscriptionDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.SubscriptionDialogComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubscriptionDialogComponentNgFactory = i1.ɵccf("app-subscription-dialog", i3.SubscriptionDialogComponent, View_SubscriptionDialogComponent_Host_0, {}, {}, []);
export { SubscriptionDialogComponentNgFactory as SubscriptionDialogComponentNgFactory };
