import { ToastrService } from 'ngx-toastr';
import { paginatorConfig, Status, getTitleCase } from 'app/app.utils';
import { Group } from './../../../models/group';
import { includes, orderBy } from 'lodash';
import { Case } from './../../../models/case';
import { SelectionState, successMsgConfig, errorMsgConfig } from './../../../app.utils';
import {
  MatSort, MatTableDataSource,
  MatCheckboxChange, MatCheckbox, MAT_DIALOG_DATA, MatSnackBar, MatDialogRef, MatDialog, MatPaginator
} from '@angular/material';
import { User } from './../../../models/user';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { UserService } from 'app/shared/api.services/user.service';
import { Observable } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CaseService } from 'app/shared/api.services/case.service';
import { SessionStorage } from 'ngx-webstorage';
import { GroupService } from 'app/shared/api.services/group.service';

@Component({
  selector: 'app-restrict-dialog',
  templateUrl: './restrict-dialog.component.html',
  styleUrls: ['./restrict-dialog.component.scss']
})
export class RestrictDialogComponent implements OnInit {
  agencyId: string;
  groupId: string;
  groupName: string;
  users: Observable<User[]>;
  groups: Observable<Group[]>;
  case: Case;
  is_restricted;
  restrictedTo;
  hasGroups = false;
  dataSource;
  restrictedUserIds;
  restrictedUsers;
  displayedColumns = ['select', 'first_name', 'last_name', 'email_address', 'role'];
  sortColumn = 'first_name';
  groupUserIds = [];
  selection = new SelectionModel<any>(true, []);
  paginator: MatPaginator;
  private sort: MatSort;
  readonly SelectionState = SelectionState;
  readonly Status = Status;
  readonly paginatorConfig = paginatorConfig;
  filterChoice = 'users';
  @SessionStorage('isLoading', false) isLoading;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  };
  @ViewChild('ref', { static: false }) ref: MatCheckbox;
  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<RestrictDialogComponent>,
    private userService: UserService,
    private authService: AuthService,
    private caseService: CaseService,
    private groupService: GroupService,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.agencyId = this.authService.user.agency_id;
    this.users = this.userService.users;
    if (this.data && this.data.case) {
      this.case = this.data.case;
    }

    if (this.data && this.data.is_restricted) {
      this.is_restricted = this.data.is_restricted;
    }

    if (this.data && this.data.groupId) {
      this.groupId = this.data.groupId;
      this.groupName = this.data.groupName;
    }

    // choose based on case restriction field
    if (this.data.field === 'groups') {
      this.filterChoice = 'groups';
      setTimeout(() => {
        this.loadGroups();
      });
    } else {
      this.filterChoice = 'users';
      setTimeout(() => {
        this.loadUsers();
      });
    }
  }

  setDataSourceAttributes() {
    if (this.dataSource) {
      setTimeout(() => { // using settimeout to resolve 'ExpressionChangedAfterItHasBeenCheckedError' error
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    }
  }

  onFilterChange(event) {
    this.selection.clear();
    switch (event.value) {
      case 'groups':
        this.filterChoice = 'groups';
        this.loadGroups();
        break;
      case 'users':
        this.filterChoice = 'users';
        this.loadUsers();
        break;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private loadGroups() {
    this.groups = this.groupService.groups;
    if (this.is_restricted && this.case.restricted_to.groups.length) {
      this.groupService.loadAll(this.agencyId, 'all');
    } else {
      this.groupService.loadAll(this.agencyId);
    }
    this.groups.subscribe(groups => {
      this.hasGroups = groups.length > 0;
      let filteredGroups = groups;
      if (this.is_restricted && this.case.restricted_to.groups.length) {
        this.selection.clear();
        // hiding the logged in user from the list
        const restrictedGroupIds = this.case.restricted_to.groups;
        let restrictedGroups = groups.filter(u => restrictedGroupIds.indexOf(u.id) !== -1);
        let nonRestrictedGroups = groups.filter(u => restrictedGroupIds.indexOf(u.id) === -1);

        // filter groups array in asc order by name
        restrictedGroups = orderBy(restrictedGroups, ['name'], ['asc']);
        nonRestrictedGroups = orderBy(nonRestrictedGroups, ['name'], ['asc']);
        filteredGroups = [...restrictedGroups].concat(nonRestrictedGroups);
        
        this.selection.select(...restrictedGroups);

        setTimeout(() => {
          if (this.ref) {
            if (this.selection.selected.length === 0) {
              this.ref.indeterminate = false;
              this.ref.checked = false;
            } else if (this.selection.selected.length < groups.length) {
              this.ref.indeterminate = true;
            } else if (this.selection.selected.length === groups.length) {
              this.ref.checked = true;
            }
          }
        });
      } else {
        setTimeout(() => {
          if (this.ref) {
            this.ref.checked = false;
            this.ref.indeterminate = false;
          }
        });
      }
      this.dataSource = new MatTableDataSource(filteredGroups);
    });
    this.displayedColumns = ['select', 'name', 'desc', 'users'];
    this.sortColumn = 'name';
  }

  private loadUsers() {
    this.userService.loadAll(this.agencyId);
    this.displayedColumns = ['select', 'first_name', 'last_name', 'email_address', 'role'];
    this.sortColumn = 'first_name';
    this.users.subscribe(res => {
      let filteredUsers = [];
      // show all users in manage group users panel
      if (this.data.groupId) {
        filteredUsers = res;
      }

      // hide logged in user from the user list if the case is not restricted
      if (!this.is_restricted) {
        filteredUsers = res.filter((u: User) => u.email_address !== this.authService.user.email_address);
      }

      // keep logged in user in the user list if the case is restricted to groups and logged in user is a group user
      if (this.is_restricted && this.case.restricted_to.group_users.length) {
        if (this.case.restricted_to.group_users.includes(this.authService.user.id)) {
          filteredUsers = res;
        } else {
          filteredUsers = res.filter((u: User) => u.email_address !== this.authService.user.email_address);
        }
      }

      if (this.is_restricted) { // restriction panel
        this.selection.clear();
        // this.restrictedUserIds = this.case.restricted_to.users.filter(uId => uId !== this.authService.user.id);
        // concatinating both group users and extra users
        this.restrictedUserIds = this.case.restricted_to.users.concat(this.case.restricted_to.group_users);
        this.groupUserIds = this.case.restricted_to.group_users;
        this.restrictedUsers = res.filter(u => this.restrictedUserIds.indexOf(u.id) !== -1);
        let nonRestrictedUsers = res.filter(u => this.restrictedUserIds.indexOf(u.id) === -1);

        // filter users array in asc order by name
        nonRestrictedUsers = orderBy(nonRestrictedUsers, ['first_name'], ['asc']);
        this.restrictedUsers = orderBy(this.restrictedUsers, ['first_name'], ['asc']);

        // keeping selected users at the top of the list
        filteredUsers = [... this.restrictedUsers].concat(nonRestrictedUsers);
        this.selection.select(...this.restrictedUsers);

        setTimeout(() => {
          if (this.ref) {
            if (this.selection.selected.length === 0) {
              this.ref.indeterminate = false;
              this.ref.checked = false;
            } else if (this.selection.selected.length < res.length) {
              this.ref.indeterminate = true;
            } else if (this.selection.selected.length === res.length) {
              this.ref.checked = true;
            }
          }
        });
      } else if (this.data.groupId) { // manage group users
        this.selection.clear();
        const groupUserIds = this.data.groupUsers.map(u => u.id);
        const groupUsers = res.filter(u => groupUserIds.includes(u.id));
        const nonGroupUsers = res.filter(u => !groupUserIds.includes(u.id));
        filteredUsers = groupUsers.concat(nonGroupUsers);
        this.selection.select(...groupUsers);
        // make header checkbox indeterminate
        setTimeout(() => {
          if (this.ref) {
            if (this.selection.selected.length === 0) {
              this.ref.indeterminate = false;
              this.ref.checked = false;
            } else if (this.selection.selected.length < res.length) {
              this.ref.indeterminate = true;
            } else if (this.selection.selected.length === res.length) {
              this.ref.checked = true;
            }
          }
        });
      } else {
        setTimeout(() => {
          if (this.ref) {
            this.ref.checked = false;
            this.ref.indeterminate = false;
          }
        });
      }
      this.dataSource = new MatTableDataSource(filteredUsers);
      this.dataSource.sortingDataAccessor = (data, property) => {
        switch (property) {
          case 'first_name': {
            return data['first_name'].toLowerCase();
          }
          case 'last_name': {
            return data['last_name'].toLowerCase();
          }
          case 'email_address': {
            return data['email_address'].toLowerCase();
          }
          case 'role': {
            return data['role']['desc'].toLowerCase();
          }
          default: {
            return data['first_name'].toLowerCase();
          }
        }
      };
    });
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: User, filter: string) => {
      const userRole = (data.role_id === 4) ? 'Agency Admin' : 'Officer';
      const searchStr = (data.first_name + data.last_name + data.email_address + userRole).toLowerCase();
      return searchStr.indexOf(filter) !== -1;
    };
  }

  isGroupUser(id: string) {
    return includes(this.groupUserIds, id);
  }

  onSelect(event: MatCheckboxChange) {
    if (!event) { return; }
    if (this.selection.hasValue()) {
      this.selection.clear();
      this.ref.checked = false;
      this.ref.indeterminate = false;
    } else {
      this.selection.select(...this.dataSource.connect().value);
    }
  }

  onSelectRow() {
    if (this.selection.selected.length === 0) {
      if (this.ref) {
        this.ref.checked = false;
        this.ref.indeterminate = false;
      }
    } else if (this.selection.selected.length < this.dataSource.data.length) {
      if (this.ref) {
        this.ref.checked = true;
        this.ref.indeterminate = true;
      }
    } else if (this.selection.selected.length === this.dataSource.data.length) {
      if (this.ref) {
        this.ref.checked = true;
        this.ref.indeterminate = false;
      }
    }
  }

  onUnlockCase() {
    const userIds = [];
    const grpIds = [];
    this.caseService.restrict(userIds, this.case.id, grpIds).subscribe((res: any) => {
      this.dialogRef.close({ caseId: this.case.id });
      this.snackBar.open('Case Unlocked Succcessfully', null, {
        duration: 2000,
      });
    }, err => {
      this.dialogRef.close();
      this.snackBar.open(`${getTitleCase('Something went wrong while unlocking the case')}`, null, {
        duration: 2000,
      });
      console.log(err);
    });
  }

  onSave() {
    if (this.data.groupId) {
      this.onManageGroupUsers();
    } else {
      this.onRestrictCase();
    }
  }

  private onManageGroupUsers() {
    let userIds = this.selection.selected.map(u => u.id);
    userIds = [...new Set(userIds)];
    this.groupService.updateUsers(this.groupId, userIds).subscribe(res => {
      this.dialogRef.close({ res: true });
      this.snackBar.open(`${getTitleCase('Group users updated successfully')}`, null, {
        duration: 2000,
      });
    }, err => {
      this.snackBar.open(`${getTitleCase('Something went wrong! Please try again later.')}`, null, {
        duration: 2000,
      });
      console.log(err);
    });
  }

  onRestrictCase() {
    if (this.filterChoice === 'users') {
      let userIds = this.selection.selected.map(u => u.id);
      userIds.push(this.authService.user.id);
      userIds = [...new Set(userIds)];
      userIds = [...userIds.filter(id => !this.isGroupUser(id))];
      let groupIds = [];
      if (this.is_restricted && this.case.restricted_to.groups.length) {
        groupIds = this.case.restricted_to.groups;
      }
      this.caseService.restrict(userIds, this.case.id, groupIds).subscribe(res => {
        this.dialogRef.close({ caseId: this.case.id });
        this.snackBar.open('Case Restricted Succcessfully', null, {
          duration: 2000,
        });
      }, err => {
        this.dialogRef.close();
        this.snackBar.open(`${getTitleCase('Something went wrong while restricting the case')}`, null, {
          duration: 2000,
        });
        console.log(err);
      });
    } else {
      let grpIds = this.selection.selected.map(g => g.id);
      grpIds = [...new Set(grpIds)];
      let userIds = [];
      if (this.is_restricted && this.case.restricted_to.users.length) {
        userIds = this.case.restricted_to.users;
      }
      this.caseService.restrictToGroups(grpIds, this.case.id, userIds).subscribe(res => {
        this.dialogRef.close({ caseId: this.case.id });
        this.snackBar.open('Case Restricted Succcessfully', null, {
          duration: 2000,
        });
      }, err => {
        this.dialogRef.close();
        this.snackBar.open(`${getTitleCase('Something went wrong while restricting the case')}`, null, {
          duration: 2000,
        });
        console.log(err);
      });
    }
  }
}
