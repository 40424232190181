import { ElementRef, Injectable } from '@angular/core';

import { HttpService } from '../http.service';
import { ServerConstants } from '../constants';
import { MatPaginator, MatSort } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { Group } from '../../models/group';
import { BehaviorSubject, Observable, fromEvent, merge } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';

@Injectable()
export class GroupService {
  private baseUrl = ServerConstants.Groups;
  private _groups: BehaviorSubject<Group[]>;
  public readonly groups: Observable<Group[]>;
  private dataStore: {
    groups: Group[]
  };

  constructor(private httpService: HttpService) {
    this.dataStore = { groups: [] };
    this._groups = <BehaviorSubject<Group[]>>new BehaviorSubject([]);
    this.groups = this._groups.asObservable();
  }

  get data(): Group[] { return this._groups.value; }

  get(id: string) {
    return this.groups.pipe(map(agencies => agencies.find(item => item.id.toString() === id.toString())));
  }

  loadAll(agencyId: string, type?: string): any {
    const params: any = { agency_id: agencyId };

    if (type) {
      params.type = type;
    }

    return this.httpService.get(this.baseUrl, { params }).pipe(map(response => {
      this.dataStore.groups = response['data'];
      this._groups.next(Object.assign({}, this.dataStore).groups);
    })).subscribe();
  }

  load(id: string): any {
    return this.httpService.get(`${this.baseUrl}/${id}`).pipe(map(response => {
      let notFound = true;

      this.dataStore.groups.forEach((item, index) => {
        if (item.id === response['data'].id) {
          this.dataStore.groups[index] = response['data'];
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.groups.push(response['data']);
      }

      this._groups.next(Object.assign({}, this.dataStore).groups);
    })).subscribe();
  }

  create(obj: Group) {
    return this.httpService.post(this.baseUrl, obj).pipe(map(response => {
      obj.id = response['data'].id;
      this.dataStore.groups.push(obj);
      this._groups.next(Object.assign({}, this.dataStore).groups);
    }));
  }

  update(obj: Group) {
    return this.httpService.post(`${this.baseUrl}/${obj.id}`, obj).pipe(map(response => {
      this.dataStore.groups.forEach((c, i) => {
        if (c.id === obj.id) { this.dataStore.groups[i] = obj; }
      });

      this._groups.next(Object.assign({}, this.dataStore).groups);
    }));
  }

  remove(id: string) {
    return this.httpService.delete(`${this.baseUrl}/${id}`).pipe(map(response => {
      this.dataStore.groups.forEach((c, i) => {
        if (c.id === id) { this.dataStore.groups.splice(i, 1); }
      });

      this._groups.next(Object.assign({}, this.dataStore).groups);
    }));
  }

  updateUsers(groupId: string, userIds) {
    return this.httpService.post(`${this.baseUrl}/${groupId}/users`, { user_ids: userIds });
  }

  toDataSource(paginator: MatPaginator, sort: MatSort, filter: ElementRef): GroupDataSource {
    return new GroupDataSource(this, paginator, sort, filter);
  }
}

export class GroupDataSource extends DataSource<any> {
  constructor(private _service: GroupService,
    private _paginator: MatPaginator,
    public _sort: MatSort,
    public _filter: ElementRef) {
    super();
  }

  connect(): Observable<Group[]> {
    const displayDataChanges = [
      this._service.groups,
      this._paginator.page,
      this._sort.sortChange,
      fromEvent(this._filter.nativeElement, 'keyup').pipe(debounceTime(150), distinctUntilChanged())
    ];

    // If the user changes the sort order, reset back to the first page.
    this._sort.sortChange.subscribe(() => {
      this._paginator.pageIndex = 0;
    });

    return merge(...displayDataChanges).pipe(startWith(null), map(() => {
      // TODO: Sorting is not active
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      const data = this._service.data.slice();
      return data.splice(startIndex, this._paginator.pageSize).filter((item: Group) => {
        const searchStr = (item.name + item.desc + item.users).toLowerCase();
        return searchStr.indexOf(this._filter.nativeElement.value.toLowerCase()) !== -1;
      });
    }));
  }

  disconnect() { }
}
