import { ToastrService } from 'ngx-toastr';
import { getTitleCase, UserNotificationDescription, UserNotificationName, errorMsgConfig, successMsgConfig } from './../../../app.utils';
import { MatTableDataSource, MatSlideToggleChange, MatSnackBar, MatDialogRef } from '@angular/material';
import { throwError } from 'rxjs';
import { User } from './../../../models/user';
import { CasePermissions, Groups } from './../../../models/permissions';
import { includes } from 'lodash';
import { Agency } from 'app/models/agency';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { UserService } from 'app/shared/api.services/user.service';
import { UserPreferenceName, UserPreferenceDescription } from 'app/app.utils';
import { NotificationModel } from 'app/models/notification';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileDialogComponent implements OnInit {
  user: User;
  agency: Agency;
  hasGroups = false;
  firstTabName = 'My Permissions';
  userGroups = [];
  casePermissions;
  adminPermissions;
  sharingpermissions;
  loginPermissions;
  dataSource;
  data: NotificationModel[] = [];
  displayedColumns = ['name', 'status'];
  isLoading = true;
  settingChanging = false;
  readonly CasePermissions = CasePermissions;

  constructor(private authService: AuthService,
    private userService: UserService,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ProfileDialogComponent>) {
    const defaultNotificationList = [
      'NewCaseNotification',
      'NewAudioNotification',
      'NewVideoNotification',
      'NewDocumentNotification',
      'NewDictationNotification'
    ];

    defaultNotificationList.forEach(notif => {
      this.data.push({
        name: UserNotificationName[notif],
        desc: UserNotificationDescription[notif],
        type: 'notification',
        status: false,
      });
    });
  }

  ngOnInit() {
    this.user = this.authService.user;

    if (this.hasPermission(CasePermissions.VIEW_SHARED_CASE)) {
      this.data.push({
        name: UserNotificationName.SharedCasesNotification,
        desc: UserNotificationDescription.SharedCasesNotification,
        type: 'notification',
        status: false,
      });
    }
    if (this.hasPermission(CasePermissions.CASE_SHARE_EMAIL)) {
      this.data.push({
        name: UserNotificationName.DownloadConfirmationNotification,
        desc: UserNotificationDescription.DownloadConfirmationNotification,
        type: 'notification',
        status: false,
      });
    }

    if (this.hasPermission(CasePermissions.CASE_DOWNLOAD)) {
      this.data.push({
        name: UserPreferenceName.RetainFileName,
        desc: UserPreferenceDescription.RetainFileName,
        type: 'setting',
        status: false,
      });
    }
    // Add download zip folder preference menu
    this.data.push({
      name: UserPreferenceName.DownloadZippingMedia,
      desc: UserPreferenceDescription.DownloadZippingMedia,
      type: 'setting',
      status: false,
    });

    setTimeout(() => {
      this.getUserPreferences();
    });
    this.getUserPermissions();

    if (this.user.groups && this.user.groups.length > 0) {
      this.hasGroups = true;
      this.userGroups = this.user.groups.map((g: Groups) => g.name);
      this.firstTabName = 'My Permissions & Groups'
    }

  }

  hasPermission(searchParam) {
    return includes(this.authService.user.permissions.map(p => p.name), searchParam);
  }

  getUserPermissions() {
    this.casePermissions = this.user.permissions.filter(p => p.name === CasePermissions.CASE_EDIT
      || p.name === CasePermissions.CASE_MERGE || p.name === CasePermissions.CASE_PRINT
      || p.name === CasePermissions.DELETE_RESTORE
      || p.name === CasePermissions.ARCHIVE_UNARCHIVE
      || p.name === CasePermissions.CASE_UPLOAD
      || p.name === CasePermissions.RESTRICT_CASE
      || p.name === CasePermissions.CASE_DOWNLOAD);

    this.adminPermissions = this.user.permissions.filter(p => p.name === CasePermissions.AGENCY_UPDATE
      || p.name === CasePermissions.USER_MANAGEMENT || p.name === CasePermissions.SUPER_ADMIN);

    this.sharingpermissions = this.user.permissions.filter(p => p.name === CasePermissions.VIEW_SHARED_CASE
      || p.name === CasePermissions.CASE_SHARE_AGENCY || p.name === CasePermissions.CASE_SHARE_EMAIL);

    this.loginPermissions = this.user.permissions.filter(p => p.name === CasePermissions.MOBILE_LOGIN
      || p.name === CasePermissions.CLOUD_LOGIN || p.name === CasePermissions.DESKTOP_LOGIN);
  }

  private getUserPreferences() {
    this.userService.getPreferences(this.user.id).subscribe((res: any) => {
      const resData = res.data.map(d => {
        return {
          name: d.name,
          desc: d.description,
          status: d.status
        };
      });

      if (resData.length) {
        resData.forEach(d => {
          this.data.forEach(ds => {
            if (ds.name === d.name) {
              ds.status = d.status;
            }
          });
        });
      }
      this.dataSource = new MatTableDataSource(this.data);
      if (this.isLoading) { this.isLoading = false; }
    }, err => {
      this.snackBar.open(`${getTitleCase('Something went wrong while getting notification settings')}`, null, {
        duration: 2000,
      });
      return throwError(err);
    });
  }

  onToggleNotifChange(event: MatSlideToggleChange, notification) {
    this.settingChanging = true;
    const dataPayload = {
      name: notification.name,
      desc: notification.desc,
      status: event.checked
    };
    this.userService.updatePreferences(this.user.id, dataPayload).subscribe(res => {
      this.settingChanging = false;
      this.snackBar.open(`${getTitleCase('User preferences updated successfully')}`, null, {
        duration: 2000,
      });
    }, err => {
      this.settingChanging = false;
      event.source.checked = false;
      this.snackBar.open(`${getTitleCase('Something went wrong, please try again later.')}`, null, {
        duration: 2000,
      });
      return throwError(err);
    });
  }
}
