import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import {RecoveryComponent} from './recovery/recovery.component';
import {ResetComponent} from './reset/reset.component';
import {WelcomeComponent} from './welcome/welcome.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'account/recovery', component: RecoveryComponent },
  { path: 'reset', component: ResetComponent },
  { path: 'welcome', component: WelcomeComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
