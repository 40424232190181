import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription, fromEvent } from 'rxjs';
import { WorkerMessage } from '../../worker/app-workers/shared/worker-message.model';
import { AuthService } from './auth/auth.service';
import { WORKER_TOPIC } from '../../worker/app-workers/shared/worker.constants';

@Injectable()
export class WorkerService {
  public readonly workerPath = 'assets/workers/main.js';

  workerUpdate$: Observable<WorkerMessage>;
  private worker: Worker;
  private workerSubject: Subject<WorkerMessage>;
  private workerMessageSubscription: Subscription;

  constructor(private authService: AuthService) {
    this.workerInit();
  }

  exec(workerMessage: WorkerMessage) {
    if (this.worker) {
      this.worker.postMessage(workerMessage);
    }
  }

  workerInit(): void {
    try {
      if (!!this.worker === false) {
        this.worker = new Worker(this.workerPath);
        this.workerSubject = new Subject<WorkerMessage>();
        this.workerUpdate$ = this.workerSubject.asObservable();

        this.workerMessageSubscription = fromEvent(this.worker, 'message')
          .subscribe((response: MessageEvent) => {
            const data: any = response.data;
            if (data.topic === WORKER_TOPIC.access_token_refreshed) {
              this.authService.setToken(data.data.token)
            }
            if (this.workerSubject) {
              this.workerSubject.next(WorkerMessage.getInstance(data));
            }
          }, (error) => console.error('WORKER ERROR::', error));
      }
    } catch (exception) {
      console.error(exception);
    }
  }

}
