var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { getTitleCase } from 'app/app.utils';
import { ToastrService } from 'ngx-toastr';
import { SessionStorage } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { OnInit } from '@angular/core';
import { appVersion } from 'app/app.version';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'app/auth/auth.service';
import { MatSnackBar } from '@angular/material';
export class NotsupportedComponent {
    constructor(deviceService, router, authService, snackBar, toastr) {
        this.deviceService = deviceService;
        this.router = router;
        this.authService = authService;
        this.snackBar = snackBar;
        this.toastr = toastr;
        this.version = appVersion;
        this.now = new Date();
        this.chromeDownloadLink = 'https://www.google.com/chrome/';
        this.edgeDownloadLink = 'https://www.microsoft.com/en-us/edge';
        this.firefoxDownloadLink = 'https://www.mozilla.org/en-US/firefox/new/';
        this.edgeTargetLink = `microsoft-edge:${environment.deploy_url}`;
        this.message = '';
        this.appRedirectUri = '';
        this.directLink = '';
        this.appRedirectUri = (this.authService.loadedUrl || this.authService.redirectUrl || '/');
        if (this.appRedirectUri.startsWith('/login') || this.appRedirectUri.startsWith('/notsupported') || this.redirectTo !== null) {
            this.redirectTo = null;
            this.appRedirectUri = '/';
        }
        // If the browser is not IE, reroute back to main page
        if (this.deviceService.getDeviceInfo().browser !== 'IE') {
            this.router.navigateByUrl(this.appRedirectUri, {});
        }
        this.directLink = `${environment.deploy_url}${this.appRedirectUri.substring(1)}`;
    }
    ngOnInit() {
        this.message = `Internet Explorer does not work well with iCF. Please explore these options:`;
    }
    openChromeLink() {
        window.open(this.chromeDownloadLink);
    }
    openEdgeLink() {
        window.open(this.edgeDownloadLink);
    }
    openFirefoxLink() {
        window.open(this.firefoxDownloadLink);
    }
    openInEdge() {
        const link = `${this.edgeTargetLink}${this.appRedirectUri.substring(1)}`;
        window.open(link);
    }
    onCopyLink() {
        this.snackBar.open(`${getTitleCase('Direct link copied to clipboard!')}`, null, {
            duration: 2000,
        });
    }
}
__decorate([
    SessionStorage('redirect_to'),
    __metadata("design:type", Object)
], NotsupportedComponent.prototype, "redirectTo", void 0);
