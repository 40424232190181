<div class="container">
  <div align="center">
    <div class="app-logo" routerLink="/" style="cursor: pointer;"></div>
  </div>
  <mat-card>
    <mat-card-title style="text-align: center;">AT-SCENE PRIVACY POLICY</mat-card-title>
    <button mat-button (click)="drawer1.toggle()" *ngIf="screenWidth <= 840">Table Of Content</button>
    <br /> <br />
    <mat-card-content align="center">
      <mat-drawer-container class="data-container">
        <mat-drawer #drawer1 mode="side" [opened]="screenWidth > 840" [mode]="(screenWidth > 840) ? 'side' : 'over'">
          <ul>
            <li>
              <span (click)="scroll(overview)">Overview</span>
            </li>
            <li>
              <span (click)="scroll(collectInformation)">The Information We Collect</span>
            </li>
            <li>
              <span (click)="scroll(applications)">The Applications</span>
            </li>
            <li>
              <span (click)="scroll(blogsAndProduct)">Blogs And Product Reviews</span>
            </li>
            <li>
              <span (click)="scroll(userDataCollection)">Our Collection Of Your Data</span>
            </li>
            <li>
              <span (click)="scroll(collectAndUseInformation)">How We Collect And Use Information </span>
            </li>
            <li>
              <span (click)="scroll(dataSecurity)">Our Commitment To Data Security</span>
            </li>
            <li>
              <span (click)="scroll(children)">Use of the ATS Website and Applications by Children</span>
            </li>
            <li>
              <span (click)="scroll(privacyPreference)">Your Privacy Preferences</span>
            </li>
            <li>
              <span (click)="scroll(infoAccess)">How to Access, Correct Or Delete Your Information
              </span>
            </li>
            <li>
              <span (click)="scroll(infoDisclosure)">Disclosure Of Information</span>
            </li>
            <li>
              <span (click)="scroll(others)">Privacy And Other Websites And Applications</span>
            </li>
          </ul>
        </mat-drawer>
        <mat-drawer-content>
          <div class="policy-data">
            <span #overview>
              <p>This policy explains our information practices, defines your privacy options and describes
                how your information is collected and used.
              </p>
              <p>
                This policy covers this website (www.at-scene.com, www.icrimefighter.com, www.icrimefighterpro.com,
                www.evidenceanywhere.com, www.cloud.icrimefighter.com, or www.cloud.at-scene.com; each individually, the
                “ATS
                Website”; collectively, the “ATS Websites”) and the various web, desktop and mobile applications
                (collectively
                the “Application(s)”) made available by At-Scene, LLC (“ATS”). The ATS Websites and the Applications are
                owned
                and operated by ATS, a company organized under the laws of Minnesota. Should you have privacy questions
                or
                concerns, send an email to privacychief@at-scene.com.
              </p>

              <p>
                By using or visiting the ATS Website or using the Applications, you agree to the collection and use of
                information in the manner described in this policy. ATS shall have right at any time and without prior
                notice,
                at its sole discretion, to revise this policy. If we make material changes to this policy, we will
                notify
                you by
                email, by means of a notice the next time you log in to the ATS Website, by means of a notice on the ATS
                Website
                homepage or when you next activate the Application. Such revisions and additions shall be effective
                immediately
                upon notice. You are responsible for reviewing the ATS Website or Applications periodically for any
                modification
                to this policy. Any access or use of the ATS Website or Applications by you after notice of
                modifications
                to
                this policy shall constitute and be deemed to be your agreement to such modifications.
              </p>
            </span>
            <br />

            <h2 class="mat-title" #collectInformation>The Information We Collect</h2>

            <p>
              This policy applies to all information collected on the ATS Websites, any information you provide to ATS,
              any
              information you may submit to the Applications when you register to download the Applications or
              information
              that results from your use of Applications. You will most likely provide us personal information when you
              register as a user of ATS Website, download and then use the Applications or participate in certain ATS
              promotions or events. The personal information we may collect might include: your name, certain
              information
              about your business, passwords, usernames, addresses, email addresses, phone numbers and certain payment
              information (e.g. PayPal, credit card or debit card account or payment information).
            </p> <br />

            <h2 class="mat-title" #applications>The Applications</h2>

            <p>
              Use of the Applications is subject to the terms and conditions of ATS’s Application License Agreement
              (www.at-scene/appterms).
            </p> <br />

            <h2 class="mat-title" #blogsAndProduct>Blogs And Product Reviews</h2>

            <p>
              The ATS Website or Applications may include blogs, product reviews or other message areas that allow users
              to
              post or send information to the ATS Website or the Applications. When you post information to the ATS
              Website or
              Applications, others can also view that information. We urge you to exercise caution when providing
              personally
              identifiable information to ATS, ATS Website or the Applications.
            </p> <br />

            <h2 class="mat-title" #userDataCollection>Our Collection Of Your Data</h2>

            <p>
              In addition to the personal information you supply, we may collect certain information to (1) evaluate how
              visitors, guests, and customers use the ATS Website or the Applications; or (2) provide you with
              personalized
              information or offers. We collect data to make the ATS Websites and Applications work better for you in
              the
              following ways: to improve the design of the ATS Websites and Applications, to provide personalization on
              the
              ATS Website and Applications and to evaluate the performance of our marketing programs and products. The
              technologies we may use to gather this non-personal information may include “IP” addresses, “cookies”,
              browser
              detection, “weblogs” and various “geo-location” tools.
            </p> <br />

            <h2 class="mat-title" #collectAndUseInformation>How We Collect And Use Information</h2>

            <p>
              Our primary goal in collecting your information is to provide you with a personalized, relevant, and
              positive
              experience with the ATS Website and Applications.
            </p>
            <p>
              You can register on the ATS Website or the Applications to receive promotions and updates, or to be
              contacted
              for market research purposes. You can control your privacy preferences regarding such marketing
              communications
              (see the section below entitled “Your Privacy Preferences”).
            </p>
            <p>
              From time to time, you may be invited to participate in optional customer surveys or promotions, and ATS
              may
              request that you provide some or all of the above listed personal information in those surveys or
              promotions. We
              use information collected from surveys and promotions to learn about our customers to improve our services
              and
              develop new products and services of interest to our customers.
            </p>
            <p>
              The mobile Applications may include the ability to link certain geographical information made available by
              us
              with your physical location. When you use the Applications, the Applications may know, in very general
              terms,
              your current physical location. To the extent that your physical location can be determined by the
              Applications,
              we may use your location to make available information to you that is relevant because of your physical
              location.
            </p>
            <p>
              IP addresses define the Internet location of computers and help us better understand the geographic
              distribution
              of our visitors and customers and manage the performance of the ATS Website. Cookies are tiny files placed
              onto
              the hard drive of your computer when you visit the ATS Website, so we can immediately recognize you when
              you
              return to the ATS Website and deliver content specific to your interests. You may modify your browser
              preferences to accept all cookies, be notified when a cookie is set, or reject all cookies. Please consult
              your
              browser instructions for information on how to modify your choices about cookies. If you modify your
              browser
              preferences, certain features of the ATS Website may not be available to you.
            </p>
            <p>
              We may detect the type of web browser you are using to optimize the performance of the ATS Websites and to
              understand the mix of browsers used by our visitors, guests, and customers. To learn about how website
              visitors
              use our site, we examine logs, which show the paths people take through the ATS Websites and how long they
              spend
              in certain areas.
            </p>
            <p>
              We may also compile certain general information related to your use of the Website and Applications. You
              agree
              that we are authorized to use, reproduce and generally make such information available to third parties in
              the
              aggregate, provided that your information shall not include personally identifiable information about you
              or
              be
              attributable to you.
            </p>
            <p>
              ATS may contract with unaffiliated third parties to provide services such as website or application
              hosting,
              advertising, customer communications, data analytics and other services. When we do this, we may provide
              your
              personally identifiable information to third parties only to provide those services, and they are not
              authorized
              to use your personally identifiable information for any other purpose.
            </p> <br />

            <h2 class="mat-title" #dataSecurity>Our Commitment To Data Security </h2>

            <p>
              Access to your personal data is limited to authorized ATS staff or approved vendors. Although total
              security
              does not exist on the Internet or through mobile networks, ATS shall make commercially reasonable efforts
              to
              safeguard the information that you submit to ATS.
            </p>

            <p>
              The data you submit to or process through the Applications is stored on Amazon’s government cloud hosting
              environment, the “AWS Gov Cloud (US)” (and/or Microsoft Azure government cloud and/or Google Government
              Cloud),
              hosting and storage environments that are compliant with the Criminal Justice Information Services (CJS)
              Security Policy (Version 5.9, dated June 1, 2020).
            </p> <br />

            <h2 class="mat-title" #children>Use Of the ATS Website And Applications By Children</h2>

            <h4>The ATS Website and the Applications are not intended for use by anyone under the age of 18.
            </h4> <br />

            <h2 class="mat-title" #privacyPreference>Your Privacy Preferences</h2>

            <p>
              When you sign up as a registered user of the ATS Website or Applications you may begin receiving marketing
              communications such as e-mail newsletters, text messages, product and service updates and promotions. Our
              customers generally find this type of information useful. If you do not want to receive these updates, you
              must
              “opt-out” by unchecking the “Add me to the mailing list” box on the registration page, or should you
              choose
              to
              opt-out after registering, you can select the “unsubscribe” link at the bottom of the email or text and
              follow
              the opt-out instructions or send an email to privacychief@at-scene.com with subject:Unsubscribe.
            </p> <br />

            <h2 class="mat-title" #infoAccess>How To Access, Correct Or Delete Your Information</h2>

            <p>
              Send ATS an email at privacychief@at-scene.com if you want to access, review, correct or delete (some
              information may not be deleted, including information ATS is required to keep by law) your personally
              identifiable information collected by ATS. To protect your privacy and security, ATS requires a user ID
              and
              password to verify your identity before granting you the right to access, review, make corrections to or
              delete
              your personally identifiable information.
            </p> <br />

            <h2 class="mat-title" #infoDisclosure>Disclosure Of Information</h2>

            <p>
              We reserve the right to disclose your personally identifiable information as required by law and when we
              believe
              that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order
              or
              legal process. It is also possible that ATS would sell its business (by merger, acquisition or otherwise)
              or
              sell all or substantially all of its assets. In any transaction of this kind, customer information,
              including
              your personally identifiable information, may be among the assets that are transferred. If we decide to so
              transfer your personally identifiable information, you will be notified by an email sent to the last know
              email
              address in our files, by notice posted on the ATS Website or when you activate the Applications.
            </p> <br />

            <h2 class="mat-title" #others>Privacy And Other Websites And Applications</h2>

            <p>
              The ATS Websites or the Applications may contain links to other websites or other mobile applications. ATS
              is
              not responsible for the privacy practices of these other sites or applications. This policy only applies
              to
              information collected by ATS.
            </p>
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-card-content>
  </mat-card>

  <div align="center" class="app-footer">
    <div class="app-version">
      <span>© iCrimeFighter - At-Scene LLC {{ now.getFullYear() }}. All Rights Reserved. Cloud Version:
        {{ version }}.
      </span>
    </div>
  </div>
</div>
