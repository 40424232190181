import { NgModule } from '@angular/core';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CommonModule } from '@angular/common';
import { StaticRoutingModule } from './static-routing.module';
import { NotfoundComponent } from './notfound/notfound.component';
import { DownloadComponent } from './download/download.component';
import { UploadComponent } from './upload/upload.component';
import {
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatIconModule,
  MatInputModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatTooltipModule,
  MatDialogModule,
  MatCheckboxModule,
  MatBottomSheetModule,
  MatListModule,
  MatTableModule,
  MatRippleModule,
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotauthorizedComponent } from './notauthorized/notauthorized.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { NotsupportedComponent } from './notsupported/notsupported.component';
import { ClipboardModule } from 'ngx-clipboard';
import { PrivacyComponent } from './privacy/privacy.component';
import { LicenseComponent } from './license/license.component';

@NgModule({
  imports: [
    CommonModule,
    StaticRoutingModule,
    FlexLayoutModule,
    MatCardModule,
    MatSidenavModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatProgressBarModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    SharedModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    RecaptchaModule,
    MatListModule,
    MatTableModule,
    DeviceDetectorModule.forRoot(),
    ClipboardModule,
    MatRippleModule
  ],
  entryComponents: [
  ],
  declarations: [
    NotfoundComponent,
    DownloadComponent,
    UploadComponent,
    NotauthorizedComponent,
    NotsupportedComponent,
    PrivacyComponent,
    LicenseComponent,
  ],
})
export class StaticModule { }
