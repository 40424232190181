/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./updates-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./updates-dialog.component";
import * as i3 from "@angular/material/dialog";
var styles_UpdatesDialogComponent = [i0.styles];
var RenderType_UpdatesDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpdatesDialogComponent, data: {} });
export { RenderType_UpdatesDialogComponent as RenderType_UpdatesDialogComponent };
export function View_UpdatesDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "update"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["height", "700px"], ["src", "https://at-scene.github.io/changelogs/"], ["style", "border: none"], ["width", "650px"]], null, [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onUpdatesLoad() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_UpdatesDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-updates-dialog", [], null, null, null, View_UpdatesDialogComponent_0, RenderType_UpdatesDialogComponent)), i1.ɵdid(1, 114688, null, 0, i2.UpdatesDialogComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpdatesDialogComponentNgFactory = i1.ɵccf("app-updates-dialog", i2.UpdatesDialogComponent, View_UpdatesDialogComponent_Host_0, {}, {}, []);
export { UpdatesDialogComponentNgFactory as UpdatesDialogComponentNgFactory };
