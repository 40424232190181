import { Component, OnInit } from '@angular/core';
import { appVersion } from 'app/app.version';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotfoundComponent implements OnInit {
  version = appVersion;
  now = new Date();

  constructor() { }

  ngOnInit() {
  }

}
