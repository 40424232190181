var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { find, isEmpty } from 'lodash';
import * as md5 from 'md5';
import * as Sentry from '@sentry/browser';
import { SessionStorage, LocalStorage } from 'ngx-webstorage';
import { UserService } from '../shared/api.services/user.service';
import { of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { HttpService } from 'app/shared/http.service';
import { ServerConstants } from 'app/shared/constants';
import * as moment from 'moment';
export class AuthService {
    constructor(userService, httpService) {
        this.userService = userService;
        this.httpService = httpService;
        this.user = null;
        this.baseUrl = ServerConstants.Auth;
    }
    register(res) {
        return of(res.data).pipe(tap(data => {
            this.user = data.user;
            this.authToken = data.session_token;
            this.handleSentryError();
            return data.session_token;
        }));
    }
    get status() {
        if (!isEmpty(this.authToken)) {
            if (this.user !== null) {
                return of(true);
            }
            else {
                return this.userService.profile().pipe(map((response) => {
                    this.user = response.data;
                    this.handleSentryError();
                    return true;
                }), catchError((err) => {
                    this.authToken = null;
                    this.user = null;
                    return of(false);
                }));
            }
        }
        else {
            return of(false);
        }
    }
    get hash() {
        return md5(this.user.id);
    }
    get token() {
        return this.authToken;
    }
    /* update existing auth user */
    updateAuthUser() {
        this.userService.profile().subscribe((res) => {
            this.user = res.data;
            this.handleSentryError();
        }, err => {
            this.authToken = null;
            this.user = null;
        });
    }
    handleSentryError() {
        Sentry.configureScope((scope) => {
            scope.setUser({ 'username': this.user.email_address });
        });
    }
    setToken(token) {
        this.authToken = token;
    }
    verifyConfirmationCode(code, token) {
        const params = { code: code, access_token: token };
        return this.httpService.post(`${this.baseUrl}/validatecode`, params);
    }
    resendVerificationCode(token, action) {
        const params = { access_token: token, action: action };
        return this.httpService.post(`${this.baseUrl}/resendcode`, params);
    }
    clear() {
        this.authToken = null;
        if (this.user && this.users && this.users.some(user => user.email === this.user.email_address)) {
            const user = find(this.users, user => user.email === this.user.email_address);
            if (user.hasOwnProperty('search') && user.search.str.trim() !== ''
                || (user.search.from && moment(user.search.from).isValid())
                || (user.search.to && moment(user.search.to).isValid())) {
                user['search']['str'] = '';
                user['search']['from'] = null;
                user['search']['to'] = null;
                this.users = this.users;
            }
        }
        this.user = null;
        if (this.access_token) {
            this.access_token = null;
            this.case_entry = null;
        }
    }
    logout() {
        const payload = { access_token: this.authToken };
        this.clear();
        return this.userService.logout(payload);
    }
}
__decorate([
    LocalStorage('users'),
    __metadata("design:type", Object)
], AuthService.prototype, "users", void 0);
__decorate([
    SessionStorage('authToken', null),
    __metadata("design:type", Object)
], AuthService.prototype, "authToken", void 0);
__decorate([
    SessionStorage('access_token'),
    __metadata("design:type", Object)
], AuthService.prototype, "access_token", void 0);
__decorate([
    SessionStorage('entry'),
    __metadata("design:type", Object)
], AuthService.prototype, "case_entry", void 0);
