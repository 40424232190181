var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AuthStatus, passwordValidator } from './../../app.utils';
import { environment } from './../../../environments/environment';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../shared/api.services/user.service';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import * as md5 from 'md5';
import { SessionStorage } from 'ngx-webstorage';
import { appVersion } from 'app/app.version';
import { take, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { getTitleCase } from 'app/app.utils';
export class MyErrorStateMatcher {
    isErrorState(control, form) {
        const isSubmitted = form && form.submitted;
        return (control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}
export class ResetComponent {
    constructor(router, route, formBuilder, snackBar, userService, authService) {
        this.router = router;
        this.route = route;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.userService = userService;
        this.authService = authService;
        this.version = appVersion;
        this.now = new Date();
        this.loading = true;
        this.resetError = false;
        this.resetSuccess = false;
        this.visiblePassword = false;
        this.visibleConfirmation = false;
        this.user = null;
        this.accessToken = null;
        this.pwd = null;
        this.appstoreUrl = environment.appstore_url;
        this.playstoreUrl = environment.playstore_url;
        this.isATS = environment.isATS;
        this.matcher = new MyErrorStateMatcher();
        this.form = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            password_confirmation: ['', [Validators.required]]
        });
        this.password.valueChanges.subscribe(value => {
            this.pwd = value;
        });
    }
    ngOnInit() {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            const token = params['token'];
            if (!token) {
                return this.router.navigateByUrl('/');
            }
            this.verifyToken(token);
        });
    }
    get password() {
        return this.form.get('password');
    }
    get passwordConfirmation() {
        return this.form.get('password_confirmation');
    }
    verifyToken(token) {
        this.authService.logout();
        this.userService.verify(token).pipe(catchError((err) => {
            this.loading = false;
            this.resetError = true;
            return throwError(err);
        })).subscribe((res) => {
            this.loading = false;
            this.user = res.data.user;
            this.accessToken = res.data.user_token;
            // add the password validatior in the form
            this.form.setValidators([Validators.required, (g) => {
                    const pwd_value = g.get('password').value;
                    const cnf_value = g.get('password_confirmation').value;
                    return passwordValidator(pwd_value, cnf_value, this.user);
                }]);
        });
    }
    onSubmit() {
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        this.userService.passwordUpdate({
            id: this.user.id,
            password: this.password.value,
            access_token: this.accessToken,
            recovery_hash: md5(this.accessToken)
        }).pipe(catchError((err) => {
            this.loading = false;
            this.resetError = true;
            return throwError(err);
        })).subscribe((res) => {
            this.loading = false;
            if (res.data && res.data.status === AuthStatus.RESET_PASSWORD) {
                this.snackBar.open(`${getTitleCase(`This password is part of hacked passwords. please choose a stronger password.`)}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
            }
            else {
                this.resetSuccess = true;
                this.redirectTo = '/';
            }
        });
    }
}
__decorate([
    SessionStorage('redirect_to', null),
    __metadata("design:type", Object)
], ResetComponent.prototype, "redirectTo", void 0);
