<div mat-dialog-title>
  <span>Change Password</span>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<form class="change-password" (submit)="onSubmit()" [formGroup]="passwordForm">
  <mat-dialog-content>
    <mat-form-field class="width-100">
      <input matInput required [type]="cp_visible ? 'text' : 'password'" placeholder="Current Password"
        formControlName="current_password" data-cy="account|change_password_modal|input|current_password"/>
      <mat-icon matSuffix (click)="cp_visible=!cp_visible">{{cp_visible ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
    <mat-form-field class="width-100">
      <input matInput #password required [type]="np_visible ? 'text' : 'password'" placeholder="New Password"
        formControlName="password" autocomplete="false" data-cy="account|change_password_modal|input|new_password"/>
      <mat-icon matSuffix (click)="np_visible=!np_visible">{{np_visible ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>

    <span *ngIf="password.value && passwordForm.hasError('whiteSpace')">
      <!-- label with password whitespace hint -->
      <mat-label *ngIf="password.value && passwordForm.hasError('whiteSpace')" style="color: red;">
        <mat-icon>close</mat-icon>
        {{'password should not be whitespace characters' | titlecase}}
      </mat-label>
    </span>

    <span *ngIf="!password.value || !passwordForm.hasError('whiteSpace')">
      <!-- label with password character length hint -->
      <mat-label [ngStyle]="{'color': !password.value || passwordForm.hasError('smallPwd') ? 'red' : 'green'}">
        <mat-icon *ngIf="!password.value || passwordForm.hasError('smallPwd')">
          close</mat-icon>
        <mat-icon *ngIf="password.value && !passwordForm.hasError('smallPwd')">
          check</mat-icon>
        {{'password must be at least 20 characters long' | titlecase}}
      </mat-label>

      <!-- lebel with password containing name, email hint -->
      <mat-label [ngStyle]="{'color': !password.value || passwordForm.hasError('nameEmailMatch') ? 'red' : 'green'}">
        <mat-icon *ngIf="!password.value || passwordForm.hasError('nameEmailMatch')">
          close
        </mat-icon>
        <mat-icon *ngIf="password.value && !passwordForm.hasError('nameEmailMatch')">
          check
        </mat-icon>
        {{'password should not include your firstname, lastname or email address' | titlecase}}
      </mat-label>

      <!-- lebel with password containing copyright terms hint -->
      <mat-label [ngStyle]="{'color': !password.value || passwordForm.hasError('serviceTermUsed') ? 'red' : 'green'}">
        <mat-icon *ngIf="!password.value || passwordForm.hasError('serviceTermUsed')">
          close
        </mat-icon>
        <mat-icon *ngIf="password.value && !passwordForm.hasError('serviceTermUsed')">
          check
        </mat-icon>
        {{'password should not include service based terms like:' | titlecase}} 'icrimefighter', 'icrime', 'icf',
        'at-scene' etc
      </mat-label>

      <!-- lebel with password containing repetitive characters hint -->
      <mat-label [ngStyle]="{'color': !password.value || passwordForm.hasError('repetitiveChar') ? 'red' : 'green'}">
        <mat-icon *ngIf="!password.value || passwordForm.hasError('repetitiveChar')">
          close
        </mat-icon>
        <mat-icon *ngIf="password.value && !passwordForm.hasError('repetitiveChar')">
          check
        </mat-icon>
        {{'password should not include repetitive character pattern like:' | titlecase}} 'aaaaa', 'abccccc' etc
      </mat-label>
    </span>

    <div align="end">
      <ng2-password-strength-bar [passwordToCheck]="pwd">
      </ng2-password-strength-bar>
    </div>
    <br />
    <mat-form-field class="width-100">
      <input matInput #cnf_password required [type]="ncp_visible ? 'text' : 'password'" placeholder="Confirm Password"
        formControlName="password_confirmation" [errorStateMatcher]="matcher" data-cy="account|change_password_modal|input|confirm_password"/>
      <mat-icon matSuffix (click)="ncp_visible=!ncp_visible">{{ncp_visible ? 'visibility_off' : 'visibility'}}
      </mat-icon>
    </mat-form-field>
    <!-- confirmation password not matching with new password -->
    <mat-label style="color: red;" *ngIf="cnf_password.value !== '' && passwordForm.hasError('mismatch')">
      <mat-icon>
        close
      </mat-icon>
      {{'not matching' | titlecase}}
    </mat-label>
  </mat-dialog-content>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-dialog-actions align="end" *ngIf="loading === false">
    <button mat-button mat-dialog-close data-cy="account|change_password_modal|button|cancel">CANCEL</button>
    <button mat-button color="primary" type="submit" [disabled]="!passwordForm.valid" data-cy="account|change_password_modal|button|confirm">SAVE</button>
  </mat-dialog-actions>
</form>
