import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, AsyncSubject } from 'rxjs';
import { HttpService } from '../http.service';
import { ServerConstants } from '../constants';
import { Case } from '../../models/case';
import { Moment } from 'moment';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { logToSentry } from 'app/app.utils';

@Injectable()
export class CaseService {
  private baseUrl = ServerConstants.Cases;
  private _cases: BehaviorSubject<Case[]>;
  public readonly cases: Observable<Case[]>;
  private dataStore: {
    cases: Case[]
  };

  constructor(private httpService: HttpService) {
    this.dataStore = { cases: [] };
    this._cases = <BehaviorSubject<Case[]>>new BehaviorSubject([]);
    this.cases = this._cases.asObservable();
  }

  get data(): Case[] { return this._cases.value; }

  get(id: string) {
    return this.cases.pipe(map(cases => cases.find(item => item.id.toString() === id.toString())));
  }

  loadAll(case_filter: string, offset?, limit?, direction?, sortHeader?): any {
    const params = { filter: case_filter, offset: offset, limit: limit };
    if (direction) { params['direction'] = direction; }
    if (sortHeader) { params['sortHeader'] = sortHeader; }
    return this.httpService.get(this.baseUrl, { params });
  }

  loadAllByFilter(case_filter: string, from?: Date | Moment, to?: Date | Moment, searchStr?) {
    const params = { filter: case_filter };
    if (from && moment(from).isValid()) { params['from'] = from.toJSON(); }
    if (to && moment(to).isValid()) { params['to'] = to.toJSON(); }
    if (searchStr && searchStr !== '') { params['searchStr'] = searchStr; }

    return this.httpService.get(`${this.baseUrl}`, { params }).pipe(
      map((res: any) => res.data.cases)
    );
  }

  load(id: string, access_token?: any): any {
    const params = { access_token: access_token };
    return this.httpService.get(`${this.baseUrl}/${id}`, { params }).pipe(map(response => {
      let notFound = true;

      this.dataStore.cases.forEach((item, index) => {
        if (item.id === response['data'].id) {
          this.dataStore.cases[index] = response['data'];
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.cases.push(response['data']);
      }

      this._cases.next(Object.assign({}, this.dataStore).cases);
      return response['data'];
    }));
  }

  create(obj: Case) {
    return this.httpService.post(this.baseUrl, obj).pipe(map(response => {
      obj.id = response['data'].id;
      this.dataStore.cases.push(obj);
      this._cases.next(Object.assign({}, this.dataStore).cases);
      return response;
    }));
  }

  update(obj: Case) {
    return this.httpService.post(`${this.baseUrl}/${obj.id}`, obj).pipe(map(response => {
      this.dataStore.cases.forEach((c, i) => {
        if (c.id === obj.id) { this.dataStore.cases[i] = obj; }
      });

      this._cases.next(Object.assign({}, this.dataStore).cases);
      return response;
    }));
  }

  filter(query: string, src: string, from?: Date | Moment, to?: Date | Moment) {
    const params = { 'query': query, 'src': src };
    if (from) { params['from'] = from.toJSON(); }
    if (to) { params['to'] = to.toJSON(); }

    return this.httpService.get(this.baseUrl, { params }).pipe(map(response => {
      this.dataStore.cases = response['data'];
      this._cases.next(Object.assign({}, this.dataStore).cases);
    })).subscribe();
  }

  statusUpdate(caseId: string, action: string) {
    return this.httpService.post(`${this.baseUrl}/${caseId}`, { case_id: caseId, action }).pipe(map(response => {
      this.dataStore.cases.forEach((c, i) => {
        if (c.id === caseId) { this.dataStore.cases.splice(i, 1); }
      });

      this._cases.next(Object.assign({}, this.dataStore).cases);
      return response;
    }));
  }

  restrict(userIds, caseId, grpIds?) {
    const param: any = { user_ids: userIds };
    if (grpIds) {
      param.group_ids = grpIds
    }
    return this.httpService.post(`${this.baseUrl}/${caseId}/restrict`, param);
  }

  restrictToGroups(groupIds, caseId, userIds?) {
    const param: any = { group_ids: groupIds };
    if (userIds) {
      param.user_ids = userIds
    }
    return this.httpService.post(`${this.baseUrl}/${caseId}/restrict`, param);
  }

  getEmails(caseId) {
    return this.httpService.get(`${this.baseUrl}/${caseId}/emails`);
  }

  getPreviouslySharedToUsers(caseId: string) {
    return this.httpService.get(`${this.baseUrl}/${caseId}/previouslyShared`);
  }

  generateVerificationToken(token) {
    const params = { access_token: token };
    return this.httpService.post(`${this.baseUrl}/download/acceptInvite`, params);
  }

  externalInvite(caseId, data) {
    return this.httpService.post(`${this.baseUrl}/${caseId}/invite`, data);
  }

  remove(id: string) {
    return this.httpService.delete(`${this.baseUrl}/${id}`).pipe(map(response => {
      this.dataStore.cases.forEach((c, i) => {
        if (c.id === id) { this.dataStore.cases.splice(i, 1); }
      });

      this._cases.next(Object.assign({}, this.dataStore).cases);
    }));
  }

  bulkUpdates(caseIds: string[], action: string) {
    return this.httpService.patch(this.baseUrl, { case_ids: caseIds, action });
  }

  mergeCases(obj: Case) {
    return this.httpService.post(`${this.baseUrl}/merge`, obj).pipe(map(response => {
      obj.id = response['data'].id;
      this.dataStore.cases.push(obj);
      this._cases.next(Object.assign({}, this.dataStore).cases);
      return response;
    }));
  }

  print(obj: Case) {
    return this.httpService.post(`${this.baseUrl}/${obj.id}/print`);
  }

  downloadFiles(caseId: string, file_ids: string[]) {
    return this.httpService.post(`${this.baseUrl}/${caseId}/download`, { file_ids });
  }

  getAllLargeDownloads() {
    return this.httpService.get(`${this.baseUrl}/alldownloads`);
  }

  downloadZip(token) {
    return this.httpService.get(`${this.baseUrl}/downloadzip?download_token=${token}`).pipe(map(response => {
      const subject = new AsyncSubject();
      const iframe = document.createElement('iframe');
      document.body.appendChild(iframe);
      iframe.setAttribute('style', 'width:0;height:0display:none;');
      iframe.src = `${response['data']['download_url']}`;
      iframe.onload = function () {
        subject.next({ success: false });
        subject.complete();
        console.log('download failed');
        logToSentry(`Download failed for the url: ${response['data']['download_url']}`);
      };
      setTimeout(function () {
        subject.next({ success: true });
        subject.complete();
      }, 10 * 1000);
      return subject;
    }));
  }

  deleteDownloads(token) {
    return this.httpService.delete(`${this.baseUrl}/download?download_token=${token}`);
  }

  /* file share by email for download */
  shareFiles(data: { case_id: string, file_ids: string[], email_addresses: string, subject: string, message?: string }) {
    const { file_ids, email_addresses, subject, message } = data
    return this.httpService.post(`${this.baseUrl}/${data.case_id}/share/email`, { file_ids, email_addresses, subject, message });
  }

  /* file share for read only view */
  shareFilesReadOnly(data: any, case_id: string) {
    return this.httpService.post(`${this.baseUrl}/${case_id}/share/email`, data);
  }

  share(case_id: string, agency_id: string, active: boolean, group_ids?: string) {
    const data: any = { agency_id: agency_id };
    if (group_ids && group_ids.length) {
      data.group_ids = group_ids;
    }
    if (active) {
      return this.httpService.post(`${this.baseUrl}/${case_id}/share`, data);
    } else {
      return this.httpService.delete(`${this.baseUrl}/${case_id}/share`, data);
    }
  }

  getCollaborators(case_id: string) {
    return this.httpService.get(`${this.baseUrl}/${case_id}/share`).pipe(
      map((res: any) => res.data)
    );
  }

  createFolder(id: string, name: string, parent_id?: string) {
    const folder_obj: any = { name: name };
    if (parent_id) {
      folder_obj.parent_id = parent_id;
    }
    return this.httpService.post(`${this.baseUrl}/${id}/folder`, folder_obj);
  }

  updateFolder(id: string, folder_id: string, name: string) {
    return this.httpService.post(`${this.baseUrl}/${id}/folder/${folder_id}`, { name: name });
  }

  folderStatusUpdate(id: string, folder_id: string, action: string) {
    if (action === 'delete') {
      return this.httpService.delete(`${this.baseUrl}/${id}/folder/${folder_id}`);
    }

    if (action === 'restore') {
      return this.httpService.post(`${this.baseUrl}/${id}/folder/${folder_id}/restore`);
    }
  }

  getFolders(id: string) {
    return this.httpService.get(`${this.baseUrl}/${id}/folder`).pipe(
      map((res: any) => res.data)
    );
  }

  logDownloadError(dataObj: any) {
    return this.httpService.post(`${this.baseUrl}/download/log`, dataObj);
  }

  downloadWithToken(token) {
    return this.httpService.get(`${this.baseUrl}/download?access_token=${token}`);
  }

  assignCase(caseId, dataObj) {
    return this.httpService.post(`${this.baseUrl}/${caseId}/assign`, dataObj);
  }

  getPreviouslySharedFileIds(caseId: string, param: any) {
    const key = Object.keys(param)[0];
    return this.httpService.get(`${this.baseUrl}/${caseId}/sharedFiles?${key}=${param[key]}`).pipe(
      map((res: any) => res.data)
    );
  }

  updateShare(caseId: string, param: any) {
    return this.httpService.post(`${this.baseUrl}/${caseId}/updateShare`, param);
  }
}
