export const WORKER_TOPIC = {
  access_token_refreshed: '@worker/access_token_refreshed',
  upload_processor_init: '@worker/upload_processor_init',
  pending_uploads: '@worker/pending_uploads',
  upload: '@worker/upload',
};

export enum UploadStatus {
  QUEUED = 'queued',
  UPLOADING = 'uploading',
  PAUSED = 'paused',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  ERRORED = 'errored',
  PENDING = 'pending'
};
