var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApiError, paginatorConfig, AuthStatus, weeklyWebinarLink } from './../../app.utils';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UserService } from '../../shared/api.services/user.service';
import { appVersion } from 'app/app.version';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { SessionStorage, LocalStorage } from 'ngx-webstorage';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SelectedTab, getTitleCase } from 'app/app.utils';
import { find } from 'lodash';
import * as moment from 'moment';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { ToastrService } from 'ngx-toastr';
export class LoginComponent {
    constructor(router, formBuilder, snackBar, authService, userService, toastr) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.authService = authService;
        this.userService = userService;
        this.toastr = toastr;
        this.version = appVersion;
        this.now = new Date();
        this.isATS = environment.isATS;
        this.appstoreUrl = environment.appstore_url;
        this.playstoreUrl = environment.playstore_url;
        this.iCFClassicUrl = environment.icf_classic_url;
        this.loading = true;
        this.visiblePassword = false;
        this.sitekey = environment.app_site_key;
        this.recaptchaRequiredAccounts = new Set();
        this._state = null;
        this.selectedAccountIndex = null;
        this.activateResendOtp = false;
        this.timeout = 120;
        this.isMultipleCodeRequest = false;
        this.webinarLink = weeklyWebinarLink;
        this.loading = true;
        this.form = this.formBuilder.group({
            username: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required]],
            recaptcha: [null, [Validators.required]],
        });
        this.authService.status.subscribe(status => {
            if (status === true) {
                this.router.navigateByUrl('/');
            }
            else {
                this.loading = false;
            }
        });
        this.form.get('recaptcha').disable();
        // will be used in the otp flow
        // this.getFingerPrintId().then(id => this.form.get('fingerprint_id').patchValue(id));
    }
    ngOnInit() {
        this.getServerStatus();
    }
    get username() {
        return this.form.get('username');
    }
    get password() {
        return this.form.get('password');
    }
    get recaptcha() {
        return this.form.get('recaptcha');
    }
    get fingerprint_id() {
        return this.form.get('fingerprint_id');
    }
    get token() {
        return this.otpForm.get('token');
    }
    get confirmationCode() {
        return this.otpForm.get('confirmation');
    }
    get state() {
        if (this._state) {
            return this._state;
        }
        else if (this.users.length > 0) {
            return 'account_selection';
        }
        else {
            return 'login';
        }
    }
    set state(s) {
        this._state = s;
    }
    get selectedAccount() {
        return this.users[this.selectedAccountIndex];
    }
    /*
      collect the client machine's fingerprint signature
      will be used while building otp flow
    */
    getFingerPrintId() {
        return __awaiter(this, void 0, void 0, function* () {
            const fp = yield FingerprintJS.load();
            const result = yield fp.get();
            return result.visitorId || '';
        });
    }
    removeUser(i) {
        this.form.reset();
        this.users.splice(i, 1);
        this.users = this.users;
        if (this.users.length === 0) {
            this.state = 'login';
        }
    }
    resolved(event) {
        this.form.get('recaptcha').patchValue(event);
    }
    onAccountSelect(i) {
        this.selectedAccountIndex = i;
        if (i >= 0) {
            this.username.setValue(this.selectedAccount.email);
        }
        if (this.recaptchaRequiredAccounts.has(this.selectedAccountIndex)) {
            this.recaptcha.enable();
        }
        else {
            this.recaptcha.disable();
        }
        setTimeout(() => {
            this.state = 'login';
        }, 500);
    }
    goBack(formDirective) {
        this.state = null;
        this.selectedAccountIndex = null;
        /* reset every form field except the fingerprint id */
        this.form.get('username').reset();
        this.form.get('password').reset();
        this.form.get('recaptcha').reset();
    }
    onRemoveAccount() {
        this.state = 'manage_accounts';
    }
    onLoginSuccess(res) {
        this.loading = false;
        if (res.data.status === AuthStatus.MFA) { // otp required
            this.otpForm = this.formBuilder.group({
                confirmation: ['', [Validators.required, this.confirmationValidator()]],
                token: res.data.access_token || ''
            });
            this.state = AuthStatus.MFA;
            setTimeout(() => {
                this.activateResendOtp = true;
            }, 120000);
            this.isMultipleCodeRequest = res.data.code_requests > 1;
            this.timeCount();
        }
        else if (res.data.status === AuthStatus.ACCOUNT_LOCKED) { // temporary a/c locked msg
            this.state = AuthStatus.ACCOUNT_LOCKED;
        }
        else if (res.data.status === AuthStatus.RESET_PASSWORD) { // compromised password used
            this.state = AuthStatus.RESET_PASSWORD;
        }
        else { // regular login success
            this.loading = true;
            this.authService.register(res).subscribe();
            this.handleSuccessLogin(res);
        }
    }
    onLoginfailure(err) {
        if (err.error.error.captcha_required) {
            this.recaptchaRequiredAccounts.add(this.selectedAccountIndex);
            this.password.reset();
            this.recaptcha.setValue('');
            this.recaptcha.enable();
        }
        this.errHandler(err);
    }
    onSubmit() {
        const username = (this.username.value || '').trim();
        const password = (this.password.value || '');
        const recaptchaResponse = this.recaptcha.value;
        // const fingerprint_id = this.fingerprint_id.value;
        if (!this.form.valid || !username || !password) {
            return;
        }
        this.loading = true;
        this.userService.login(username, password, recaptchaResponse).pipe(catchError((err) => {
            this.loading = false;
            if (err.statusText === 'Unknown Error') {
                this.snackBar.open(`${getTitleCase('Server not responding! Please contact support@icrimefighter.com')}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
            }
            else {
                this.onLoginfailure(err);
            }
            return throwError(err);
        })).subscribe(res => {
            this.onLoginSuccess(res);
        });
    }
    getServerStatus() {
        fetch(environment.server_status_url).then(res => res.json()).then((data) => {
            Object.keys(data).forEach(key => {
                if (environment.mode === key) {
                    const parsedStartTime = this.parseDateTime(data[key].starttime);
                    const parsedEndTime = this.parseDateTime(data[key].endtime);
                    this.server_down_from = moment.utc(parsedStartTime).format();
                    this.server_down_to = moment.utc(parsedEndTime).format();
                    this.server_down = moment().isSameOrBefore(this.server_down_to);
                }
            });
        });
    }
    parseDateTime(date_time_stamp) {
        const timeStampValues = date_time_stamp.split(',');
        /*
          parse the date time stamp with the correct offset value
          in the desire moment format
        */
        switch (timeStampValues[2].trim().toLowerCase()) {
            case "ist":
                return `${timeStampValues[0].trim()}T${timeStampValues[1].trim()}+05:30`;
            case "cdt":
                return `${timeStampValues[0].trim()}T${timeStampValues[1].trim()}-05:00`;
            case "utc":
                return `${timeStampValues[0].trim()}T${timeStampValues[1].trim()}`;
            default:
                return `${timeStampValues[0].trim()}T${timeStampValues[1].trim()}`;
        }
    }
    confirmationValidator() {
        return (control) => {
            if (control.value.length === 6) {
                return null;
            }
            else {
                return { 'confirmation_error': true };
            }
        };
    }
    timeCount() {
        if (this.timeout !== 0) {
            this.timeout--;
            setTimeout(() => {
                this.timeCount();
            }, 1000);
        }
    }
    onResendCode() {
        this.loading = true;
        this.authService.resendVerificationCode(this.token.value, AuthStatus.MFA).subscribe((res) => {
            this.loading = false;
            this.state = AuthStatus.MFA;
            this.timeout = 120;
            this.activateResendOtp = false;
            this.isMultipleCodeRequest = res.data.code_requests > 1;
            setTimeout(() => {
                this.activateResendOtp = true;
            }, 120000);
            this.timeCount();
        }, err => {
            this.loading = false;
            this.errHandler(err);
        });
    }
    onOtpSubmit() {
        this.loading = true;
        const payload = {
            code: (this.confirmationCode.value || '').trim(),
            access_token: this.token.value
        };
        this.userService.verifyOtp(payload).subscribe(res => {
            // continue to success login
            this.authService.register(res).subscribe();
            this.handleSuccessLogin(res);
        }, err => {
            this.loading = false;
            this.errHandler(err);
            // show a/c locked screen in case of validation limit reached
            if (err.error.error.name === ApiError.ValidationLimitReached) {
                this.state = AuthStatus.ACCOUNT_LOCKED;
            }
        });
    }
    handleSuccessLogin(res) {
        // store user data and preference in the localstorage
        const current_user = {
            name: res.data.user.full_name,
            email: res.data.user.email_address,
            selectedPageSize: paginatorConfig.defaultPageSize,
            filesPageSize: paginatorConfig.defaultPageSize,
            downloadAttempts: 0,
            tab: SelectedTab.All,
            sort: 'updated_at, desc',
            search: {
                str: '',
                from: null,
                to: null
            }
        };
        const is_existing = this.users.findIndex(u => u.email === current_user.email);
        if (is_existing === -1) {
            this.users.push(current_user);
        }
        else {
            // update existing user's data and preference
            const user = find(this.users, user => user.email === current_user.email);
            if (user.hasOwnProperty('search') && user.search.str.trim() !== ''
                || (user.search.from && moment(user.search.from).isValid())
                || (user.search.to && moment(user.search.to).isValid())) {
                user['search']['str'] = '';
                user['search']['from'] = null;
                user['search']['to'] = null;
            }
            // update older page sizes
            if (user.hasOwnProperty('selectedPageSize') && paginatorConfig.oldPageSizeOptions.indexOf(user.selectedPageSize) > -1) {
                user.selectedPageSize = 25;
            }
            if (!user.hasOwnProperty('filesPageSize') ||
                (user.hasOwnProperty('filesPageSize') && paginatorConfig.oldPageSizeOptions.indexOf(user.filesPageSize) > -1)) {
                user.filesPageSize = 25;
            }
        }
        this.users = this.users;
        let redirectUri = (this.authService.loadedUrl || this.authService.redirectUrl || '/');
        if (redirectUri.startsWith('/login') || this.redirectTo !== null) {
            this.redirectTo = null;
            // check tab preference and redirect
            if (is_existing !== -1) {
                const user = this.users.filter(u => u.email === this.authService.user.email_address)[0];
                switch (user['tab']) {
                    case 'my_cases':
                        redirectUri = '/app/cases/my';
                        break;
                    case 'shared_cases':
                        redirectUri = '/app/cases/shared';
                        break;
                    case 'agency_cases':
                        redirectUri = '/app/cases/agency';
                        break;
                    case 'all_cases':
                        redirectUri = '/app/cases';
                        break;
                }
            }
            else
                redirectUri = '/';
        }
        if (this.anyNewUpdate) {
            this.anyNewUpdate = false;
            window.location.assign(redirectUri);
        }
        else {
            this.router.navigateByUrl(redirectUri, {});
        }
        this.authService.loadedUrl = this.authService.redirectUrl = null;
    }
    errHandler(err) {
        switch (err.error.error.name) {
            case ApiError.InvalidToken:
                this.snackBar.open(`${getTitleCase(`Token Is Invalid Or Expired`)}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
                break;
            case ApiError.ResendNotPermitted:
                this.snackBar.open(`${getTitleCase(`Resend Request Not Permitted Within 2 Mins`)}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
                break;
            case ApiError.ValidationLimitReached:
                this.snackBar.open(`${getTitleCase(`You have reached the maximum number of incorrect attempts. Try again later.`)}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
                break;
            case ApiError.InvalidCredentials:
                this.snackBar.open('Invalid Email Or Password!', 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
                break;
            case ApiError.LicenseExpired:
                this.snackBar.open(`${getTitleCase(`Your agency's license has expired! Please contact us at support@icrimefighter.com`)}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
                break;
            case ApiError.NotAuthorized:
                this.snackBar.open(`You Are Not Authorized`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
                break;
            case ApiError.ExpiredValidationCode:
                this.snackBar.open(`${getTitleCase(`Validation Code Is Expired`)}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
                break;
            case ApiError.InvalidValidationCode:
                this.snackBar.open(`${getTitleCase(`Validation Code Is Invalid`)}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
                break;
            default:
                this.snackBar.open(`${getTitleCase('Something went wrong! Please contact us at support@icrimefighter.com')}`, 'CLOSE', {
                    panelClass: 'snackbar-error',
                    horizontalPosition: 'end',
                }).onAction().subscribe(() => {
                    this.snackBar.dismiss();
                });
        }
    }
    openWebinarLink() {
        window.open(this.webinarLink);
    }
}
__decorate([
    SessionStorage('redirect_to', null),
    __metadata("design:type", Object)
], LoginComponent.prototype, "redirectTo", void 0);
__decorate([
    LocalStorage('users', []),
    __metadata("design:type", Object)
], LoginComponent.prototype, "users", void 0);
__decorate([
    LocalStorage('anyNewUpdate'),
    __metadata("design:type", Object)
], LoginComponent.prototype, "anyNewUpdate", void 0);
