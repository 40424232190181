var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SessionStorage } from 'ngx-webstorage';
import { ErrorTags, getDecodedFileUploadName, logToSentry } from 'app/app.utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { WorkerService } from 'app/worker.service';
import { WORKER_TOPIC, UploadStatus } from '../../../../worker/app-workers/shared/worker.constants';
import { WorkerMessage } from '../../../../worker/app-workers/shared/worker-message.model';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { ServerConstants } from '../constants';
import { FileService } from './file.service';
import { HttpService } from '../http.service';
import * as i0 from "@angular/core";
import * as i1 from "../../auth/auth.service";
import * as i2 from "./file.service";
import * as i3 from "../../worker.service";
import * as i4 from "../http.service";
export class UploadService {
    constructor(authService, fileService, workerService, httpService) {
        this.authService = authService;
        this.fileService = fileService;
        this.workerService = workerService;
        this.httpService = httpService;
        this.uploadStore = [];
        this.q = new BehaviorSubject(this.uploadStore);
        this.e = new Subject();
        this.uploads = this.q.asObservable();
        this.events = this.e.asObservable();
        this.baseUrl = ServerConstants.Uploads;
        this.folders = [];
        this.totalUploads = 0;
        this.finishedUploads = [];
        this.finishedExtUplaods = [];
        this.invalidUploads = [];
        this.targetFiles = [];
        this.workerService.workerUpdate$.subscribe((message) => {
            if (message.topic === WORKER_TOPIC.upload && message.data.state) {
                const uploadIndex = this.uploadStore.findIndex(u => u.id === message.data.id);
                if (uploadIndex !== -1) {
                    this.uploadStore[uploadIndex].state = message.data.state;
                    if (message.data.state === UploadStatus.QUEUED) {
                        this.uploadStore[uploadIndex].key = message.data.key;
                        this.uploadStore[uploadIndex].file_id = message.data.file_id;
                    }
                    else if (message.data.state === UploadStatus.UPLOADING) {
                        this.uploadStore[uploadIndex].progress = message.data.progress;
                    }
                    else if (message.data.state === UploadStatus.PROCESSING) {
                        if (message.data.action && message.data.action === 'init-ext-upload') {
                            this.token = message.data.token;
                            this.checkExtUploadStatus(this.uploadStore[uploadIndex], message.data.token);
                        }
                        else {
                            this.checkStatus(this.uploadStore[uploadIndex]);
                        }
                    }
                    else if (message.data.state === UploadStatus.ERRORED) {
                        this.handleErroredUpload(this.uploadStore[uploadIndex], message);
                    }
                }
                this.q.next(this.uploadStore);
            }
            else if (message.topic === WORKER_TOPIC.pending_uploads) {
                const pendingUploads = message.data.filter(u => u.file && u.data);
                // uploads which failed due to network error and cannot be resumed
                const erroredUploads = message.data.filter(u => !(u.file && u.data));
                pendingUploads.forEach((u) => {
                    if (u.file && u.data) {
                        const uploadObj = Object.assign({ id: u.file.lastModified, file_id: u.data.file_id, state: UploadStatus.PENDING }, u);
                        this.uploadStore.push(uploadObj); // store all pending uploads into the upload storage
                    }
                });
                if (this.uploaderCancel === true) { // remove pending uploads automatically in case the user cancels the ongoing upload
                    this.uploadStore.filter(u => u.state === UploadStatus.PENDING).forEach(u => {
                        this.delete(u);
                    });
                    this.uploaderCancel = false;
                }
                if (erroredUploads.length > 0) {
                    this.erroredUploadDelete(); // clear the storage if it is filled with errored uploads
                }
                this.q.next(this.uploadStore);
            }
        });
    }
    init() {
        const storageKey = `uploads_${this.authService.hash}`;
        this.workerService.exec(new WorkerMessage(WORKER_TOPIC.upload_processor_init, {
            awsRegion: environment.aws_region,
            storageKey: storageKey,
        }));
    }
    initExternalUpload() {
        this.workerService.exec(new WorkerMessage(WORKER_TOPIC.upload_processor_init, {
            awsRegion: environment.aws_region,
        }));
    }
    create(upload) {
        upload = Object.assign({ id: upload.id || uuidv4(), state: UploadStatus.QUEUED }, upload);
        const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, Object.assign({}, upload, { action: 'init', api_url: environment.api_url, token: this.authService.token, product_id: environment.product_id }));
        this.workerService.exec(workerMessage);
        const uploadIndex = this.uploadStore.findIndex(u => u.id === upload.id);
        if (uploadIndex === -1) {
            this.uploadStore.push(upload);
        }
        else {
            this.uploadStore[uploadIndex] = upload;
        }
        this.q.next(this.uploadStore);
    }
    createExternalUpload(upload, token) {
        upload = Object.assign({ id: uuidv4(), state: UploadStatus.QUEUED }, upload);
        const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, Object.assign({}, upload, { action: 'init-ext-upload', api_url: environment.api_url, token: token, product_id: environment.product_id }));
        this.workerService.exec(workerMessage);
        const uploadIndex = this.uploadStore.findIndex(u => u.id === upload.id);
        if (uploadIndex === -1) {
            this.uploadStore.push(upload);
        }
        else {
            this.uploadStore[uploadIndex] = upload;
        }
        this.q.next(this.uploadStore);
    }
    pause(upload) {
        const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, {
            action: 'pause',
            key: upload.key
        });
        this.workerService.exec(workerMessage);
    }
    resume(upload) {
        const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, {
            action: 'resume',
            key: upload.key
        });
        this.workerService.exec(workerMessage);
    }
    get ongoing() {
        return this.uploadStore.filter((u) => u.state === UploadStatus.UPLOADING);
    }
    saveAndNotify() {
        this.q.next(this.uploadStore);
    }
    handleErroredUpload(upload, message) {
        // update the upload bar with the error msg
        const err = message.data.errorMsg;
        upload = Object.assign({ state: UploadStatus.ERRORED, errorMsg: err.error ? err.error.error.message :
                err.message ? err.message : err, errorTitle: message.data.errorTitle }, upload);
        const uploadIndex = this.uploadStore.findIndex(u => u.id === upload.id);
        if (uploadIndex !== -1) {
            this.uploadStore[uploadIndex] = upload;
        }
        this.q.next(this.uploadStore);
        logToSentry(`${err}:caseId-${upload.case_id}fileId-${upload.file_id || 'none'}file-${getDecodedFileUploadName(upload.file.name)}size-${upload.file.size}type-${upload.type}`, ErrorTags.UploadError);
    }
    /*
      push an entry to the finishedUploads for the upload summary
      delete the upload from the upload bar
      emmit value for case refresh, when the last upload is finished
    */
    checkStatus(upload) {
        return __awaiter(this, void 0, void 0, function* () {
            if (upload.case_id)
                this.finishedUploads.push({ case_id: upload.case_id, case_name: upload.case_name });
            this.delete(upload);
            this.lastUpload = Object.assign({}, upload, { state: UploadStatus.COMPLETED });
            if (this.uploadStore.length === 0)
                this.e.next(Object.assign({}, upload, { state: UploadStatus.COMPLETED }));
        });
    }
    notifyCase() {
        this.e.next(this.lastUpload);
    }
    /*
      for external upload,
      only the uploaded count i.e finishedUploads array length is required
    */
    checkExtUploadStatus(upload, token) {
        return __awaiter(this, void 0, void 0, function* () {
            // this.finishedUploads.push(upload);
            this.finishedExtUplaods.push(upload);
            this.delete(upload);
            this.e.next(Object.assign({}, upload, { state: UploadStatus.COMPLETED }));
        });
    }
    delete(upload) {
        const ui = this.uploadStore.findIndex(u => u.id === upload.id);
        if (ui > -1) {
            const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, {
                action: 'remove',
                key: upload.key
            });
            this.workerService.exec(workerMessage);
            this.uploadStore.splice(ui, 1);
            this.saveAndNotify();
        }
    }
    erroredUploadDelete() {
        const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, {
            action: 'remove',
        });
        this.workerService.exec(workerMessage);
        this.saveAndNotify();
    }
    clear() {
        if (this.uploadStore.length > 0) {
            this.uploadStore = [];
            this.saveAndNotify();
        }
    }
    generateVerificationToken(token) {
        const params = { access_token: token };
        return this.httpService.post(`${this.baseUrl}/external/acceptinvite`, params);
    }
}
UploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.FileService), i0.ɵɵinject(i3.WorkerService), i0.ɵɵinject(i4.HttpService)); }, token: UploadService, providedIn: "root" });
__decorate([
    SessionStorage('uploaderCancel', false),
    __metadata("design:type", Object)
], UploadService.prototype, "uploaderCancel", void 0);
