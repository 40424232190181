import { successMsgConfig } from './../../app.utils';
import { getTitleCase } from 'app/app.utils';
import { ToastrService } from 'ngx-toastr';
import { SessionStorage } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { appVersion } from 'app/app.version';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'app/auth/auth.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-notsupported',
  templateUrl: './notsupported.component.html',
  styleUrls: ['./notsupported.component.scss']
})
export class NotsupportedComponent implements OnInit {
  version = appVersion;
  now = new Date();
  chromeDownloadLink = 'https://www.google.com/chrome/';
  edgeDownloadLink = 'https://www.microsoft.com/en-us/edge';
  firefoxDownloadLink = 'https://www.mozilla.org/en-US/firefox/new/';
  edgeTargetLink = `microsoft-edge:${environment.deploy_url}`;
  message = '';
  appRedirectUri = '';
  directLink = '';
  @SessionStorage('redirect_to') private redirectTo;
  constructor(private deviceService: DeviceDetectorService,
    private router: Router,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private toastr: ToastrService) {
    this.appRedirectUri = (this.authService.loadedUrl || this.authService.redirectUrl || '/');
    if (this.appRedirectUri.startsWith('/login') || this.appRedirectUri.startsWith('/notsupported') || this.redirectTo !== null) {
      this.redirectTo = null;
      this.appRedirectUri = '/';
    }

    // If the browser is not IE, reroute back to main page
    if (this.deviceService.getDeviceInfo().browser !== 'IE') {
      this.router.navigateByUrl(this.appRedirectUri, {});
    }
    this.directLink = `${environment.deploy_url}${this.appRedirectUri.substring(1)}`;
  }

  ngOnInit() {
    this.message = `Internet Explorer does not work well with iCF. Please explore these options:`;
  }

  openChromeLink() {
    window.open(this.chromeDownloadLink);
  }

  openEdgeLink() {
    window.open(this.edgeDownloadLink);
  }

  openFirefoxLink() {
    window.open(this.firefoxDownloadLink);
  }

  openInEdge() {
    const link = `${this.edgeTargetLink}${this.appRedirectUri.substring(1)}`;
    window.open(link);
  }

  onCopyLink() {
    this.snackBar.open(`${getTitleCase('Direct link copied to clipboard!')}`, null, {
      duration: 2000,
    });
  }

}
