<div class="container">
  <div align="center">
    <div class="app-logo" routerLink="/" style="cursor: pointer;"></div>
  </div>
  <mat-card>
    <mat-card-title style="text-align: center;">AT-SCENE LICENSE AGREEMENT</mat-card-title>
    <button mat-button (click)="drawer1.toggle()" *ngIf="screenWidth <= 840">Table Of Content</button>
    <br /> <br />
    <mat-card-content align="center">
      <mat-drawer-container class="data-container">
        <mat-drawer #drawer1 mode="side" [opened]="screenWidth > 840" [mode]="(screenWidth > 840) ? 'side' : 'over'">
          <ul>
            <li>
              <span (click)="scroll(important)">Important</span>
            </li>
            <li>
              <span (click)="scroll(rights)">Proprietary Rights, Licenses and Use of Data</span>
            </li>
            <li>
              <span (click)="scroll(support)">Support</span>
            </li>
            <li>
              <span (click)="scroll(compensation)">Compensation for APP Licenses</span>
            </li>
            <li>
              <span (click)="scroll(warranties)">Representations and Warranties</span>
            </li>
            <li>
              <span (click)="scroll(indemnity)">Indemnity</span>
            </li>
            <li>
              <span (click)="scroll(liability)">Limitation of Liability</span>
            </li>
            <li>
              <span (click)="scroll(term)">Term and Termination</span>
            </li>
            <li>
              <span (click)="scroll(provisions)">General Provisions</span>
            </li>
          </ul>
        </mat-drawer>
        <mat-drawer-content>
          <div class="policy-data">
            <p #important>
              <b>1. IMPORTANT:</b> THIS AT-SCENE APPLICATION LICENSE AGREEMENT (THE “LICENSE”) DESCRIBES THE TERMS AND
              CONDITIONS
              PURSUANT TO WHICH THE AT-SCENE APPLICATION DESCRIBED IN THE AT-SCENE ORDER FORM (THE “APP”) IS LICENSED TO
              YOU BY AT-SCENE, LLC (COLLECTIVELY “AT-SCENE”, “WE” OR “US” OR OUR”). THE APP, THE LICENSE FEE FOR THE APP
              AND THE TERM OF THE LICENSE ARE SET FORTH IN ONE MORE AT-SCENE ORDER FORMS (THE “ORDER FORM”). PLEASE READ
              THE LICENSE CAREFULLY. THE LICENSE IS BETWEEN YOU (“YOU” OR “YOUR”) AND US. BY DOWNLOADING OR USING THE
              APP, YOU ACKNOWLEDGE THAT YOU HAVE READ THE LICENSE, UNDERSTAND THE LICENSE, AND AGREE TO BE BOUND BY THE
              TERMS OF THE LICENSE. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS SET FORTH BELOW, DO NOT USE
              THE APP. PLEASE REVIEW SECTION 2.6 BELOW VERY CAREFULLY BEFORE USING THE APP.
            </p><br />

            <h2 class="mat-title" #rights>2. Proprietary Rights, Licenses and Use of Data</h2>

            <p>
              2.1 Subject to the terms and conditions of this License, At-Scene hereby grants to You for the Term (as
              defined below), a non-exclusive, non-transferable, worldwide license to use the APP in accordance with the
              terms and conditions of this License and those provided by the various mobile phone or network operators
              (collectively the “Mobile Operators”) that require application providers to comply with their terms and
              conditions (the “Mobile Operator Terms”).
            </p>

            <p>
              2.2 You will not: (a) modify, translate or create derivative works of the APP; (b) decompile, reverse
              engineer or reverse assemble any portion of the APP or attempt to discover any source code or underlying
              ideas or algorithms of the APP; (c) sell, assign, sublicense, rent, lease, loan, provide, distribute or
              otherwise transfer all or any portion of the APP; (d) make, have made, reproduce or copy all or any
              portion of the APP; and (e) cause or permit any other party to do any of the foregoing.
            </p>

            <p>
              2.3 You hereby grant to At-Scene for the Term a non-exclusive, worldwide license to distribute, display,
              and perform Your Content solely in connection with the operation and support of the APP. “Your Content”
              means all user-perceptible text and multimedia information, including sound, data, text, designs, audio,
              video, graphics, photographs, information and the like provided by You to At-Scene under this License.
              You, not At-Scene, shall have sole responsibility for the accuracy, quality, integrity, legality,
              reliability, appropriateness, and intellectual property ownership or right to use of all Your Content. You
              acknowledge that At-Scene may compile certain general information related to the use of the APP. You agree
              that At-Scene is authorized to use, reproduce and generally make such information available to third
              parties in the aggregate.
            </p>

            <p>
              2.4 You hereby grant to At-Scene during the Term, a nonexclusive, non-transferable, worldwide license to
              use Your trademarks, services marks, logos and designations (collectively the “Marks”) solely in
              connection with the APP.
            </p>

            <p>
              2.5 Subject to the license grants set forth herein, as between the parties: (a) all right, title and
              interest in and to the APP, any and all modifications, updates and derivatives of the APP, will remain
              with At-Scene; and (b) all right, title and interest in and to Client Content and the Client Marks remain
              with Client. Any rights not expressly granted to a licensee hereunder are hereby reserved by the licensor.
            </p>

            <p>
              2.6 <b>Use of APP and Legal Requirements.</b> YOU understand that the APP is designed and intended to be
              used to
              collect information, information which may be characterized as law enforcement investigative data,
              confidential data, non-public data, and public data (hereinafter “Data”). Accordingly, YOU must be aware
              of the applicable federal, state, and local laws, ordinances, rules and regulations (hereinafter “Data
              Rules”) pertaining to the creation, collection, storage, dissemination, and destruction of such Data.
              Certain jurisdictions classify these laws, ordinances, rules and regulations as a government data
              practices act. Likewise, the creation, collection, storage, dissemination, and destruction of such Data is
              governed by an employer or law enforcement agency’s policies, procedures, practices, and general orders
              (hereinafter “Agency Policies”). The creation, collection, storage, dissemination, and destruction of such
              Data also may be governed by a court’s rules of evidence and other relevant rules (hereinafter “Court
              Rules”). YOU acknowledge an understanding of the relevant Data Rules, Agency Policies, and Court Rules,
              and agree only to use this APP consistent with these Data Rules, Agency Policies, and Court Rules. YOU ARE
              encouraged to consult with legal counsel (e.g., municipal attorney, county attorney, state’s attorney,
              attorney general, prosecutor, or agency legal counsel) with any questions regarding the use of this APP in
              accordance with the Data Rules, Agency Policies, and Court Rules. YOU understand that using this APP in
              contravention of the Data Rules, Agency Policies, and Court Rules may subject YOU AND YOUR employer to
              criminal, civil, and administrative sanctions.
            </p><br />

            <h2 class="mat-title" #support>3. Support</h2>

            <p>
              We will provide support related to the APP as set forth in the Order Form. In addition, from time to time,
              At-Scene may make scheduled and/or unscheduled updates to the APP. During such deployments, all or
              selected portions of the APP may be unavailable.
            </p><br />

            <h2 class="mat-title" #compensation>4. Compensation for APP Licenses</h2>

            <p>
              As compensation for At-Scene’s licensing the APP to You, You shall pay At-Scene the licensing fee set
              forth in the applicable Order Form (the “License Fee”). The License Fee, and all applicable sales and use
              taxes, shall be paid by You to Us as set forth in the applicable Order Form.
            </p><br />

            <h2 class="mat-title" #warranties>5. Representations and Warranties</h2>

            <p>
              5.1 You represent and warrant to At-Scene that: (a) You have the right and power to perform its
              obligations and to grant the rights granted herein; (b) Your performance under this License will not
              violate any agreement or obligation between You and a third party or any applicable law, ordinance or
              regulation. You further warrant to At-Scene that Your Content: (i) is original to You or that You have
              secured the rights to provide and use such Your Content; (ii) does not contain any content that is
              unlawful, threatening, harassing, profane, tortuous, defamatory, libelous, deceptive, fraudulent, invasive
              of another’s privacy or publicity, or (iii) does not contain a virus or other harmful component.
            </p>

            <p>
              5.2 At-Scene represents and warrants to Client that that the APP shall operate in substantial conformance
              with the functional descriptions of the Service found in the Order Form. If the APP fails to so operate,
              then At-Scene, as its sole warranty obligation, and Your sole warranty remedy, shall make a commercially
              reasonable effort to correct the APP.
            </p>

            <p>
              5.3 THE ABOVE WARRANTIES ARE IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE IMPLIED
              WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
            </p><br />

            <h2 class="mat-title" #indemnity>6. Indemnity</h2>

            <p>
              Client shall, at its sole expense, will defend, indemnify and hold At-Scene harmless from and against any
              and all claims, damages, fines, judgments, settlements, costs, liabilities, losses and expenses
              (including, but not limited to, reasonable attorney fees) resulting from any claim (including third party
              claims), suit, action, or proceeding against At-Scene resulting from or arising in connection: (a) a claim
              alleging that use of Client Content or Marks infringes the rights of (including, but not limited to, the
              rights of privacy or publicity), or has caused harm to, a third party; (b) a claim, which if true, would
              constitute a violation by Client of the representations and warranties set forth in Section 5.1 above; and
              (c) Client’s breach of Sections 2.2 or 4 above or (d) any claim related to Client’s use of the APP in
              contravention to the requirements of Section 2.6 above.
            </p><br />

            <h2 class="mat-title" #liability>7. Limitation of Liability</h2>

            <p>
              IN NO EVENT WILL (A) AT-SCENE BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES
              ARISING OUT OF OR IN CONNECTION WITH THIS LICENSE OR THE APP, INCLUDING THE LOSS OF INCOME OR DATA; AND
              (B) AT-SCENE’S TOTAL CUMULATIVE LIABILITY TO YOU EXCEED THE AMOUNT PAID BY YOU FOR THE APP. SOME
              JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY
              FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </p>

            <h2 class="mat-title" #term>8. Term and Termination</h2>

            <p>
              This License will commence upon the date that you download the APP and remain effective for the term set
              forth on the applicable Order Form (the “Initial Term”). The Initial Term will automatically renew for
              successive one year terms (together, the “Term”), unless either party gives the other party written notice
              of non-renewal at least thirty (30) days prior to the end of the then-current Term. Either party may
              terminate this License for cause if the other party breaches this License and does not remedy such failure
              within thirty (30) days after its receipt of written notice of such breach. Upon any expiration or
              termination of this License all licenses granted under this License will immediately cease.
            </p><br />

            <h2 class="mat-title" #provisions>9. General Provisions</h2>

            <p>
              This License will be governed by the laws of State of Minnesota without regard to its conflict of law
              principles. The state or federal counts of Hennepin County, Minnesota shall have exclusive jurisdiction
              over all disputes and matters related to this License. In any action or proceeding by At-Scene to enforce
              rights under this License, At-Scene, to the extent it is the prevailing party, will be entitled to recover
              costs and attorneys’ fees. If any provision of this License is held to be invalid or unenforceable by a
              court of competent jurisdiction for any reason, the remaining provisions will continue in full force
              without being impaired or invalidated in any way. The parties will replace any invalid provision with a
              valid provision, which most closely approximates the intent and economic effect of the invalid provision.
              You may not assign or transfer Your rights under this License to any third party without the prior written
              consent of At-Scene. Subject to the foregoing, this License will be binding on and enforceable by the
              parties and their respective successors and permitted assigns. This License, including the Mobile Operator
              Terms (all of which are hereby incorporated by reference), and all Order Forms (each of which is hereby
              incorporated by reference) sets forth the entire understanding of the parties, and supersedes any and all
              prior or contemporaneous oral or written Licenses or understandings between the parties. We reserve the
              right, at our discretion, to amend this License at any time; We will provide notice of any such material
              amendment by posting a notice on the APP description found at the Mobile Operator’s application store or
              by presenting a link to such amendment when you next activate the APP. Your use of the APP after amendment
              shall be deemed your acceptance of such amendment. Any waiver of a breach of any provision contained
              herein must be in a writing signed by At-Scene, and no such waiver will be construed as a waiver of any
              other breach.
            </p>

          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-card-content>
  </mat-card>
  <div align="center" class="app-footer">
    <div class="app-version">
      <span>© iCrimeFighter - At-Scene LLC {{ now.getFullYear() }}. All Rights Reserved. Cloud Version:
        {{ version }}.
      </span>
    </div>
  </div>
</div>
