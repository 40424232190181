import { appVersion } from 'app/app.version';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  version = appVersion;
  now = new Date();
  screenWidth;
  @ViewChild('drawer1', { static: false }) drawer: MatDrawer;
  constructor() {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
  }

  ngOnInit() {
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
    if (this.screenWidth < 840) {
      this.drawer.close();
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
