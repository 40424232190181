<div mat-dialog-title>
  <span>{{ data.title || 'Confirm' }}</span>
  <div class="spacer"></div>
</div>
<form [formGroup]="form" (submit)="onSubmit()">
  <mat-dialog-content cdkInitialFocus>
    <p>{{ data.message || 'Are you sure to proceed?' }}</p>
    <div *ngIf="form.enabled" class="confirmation-wrapper">
      <mat-hint>
        <p>This action can lead to data loss. To prevent accidental actions we ask you to confirm your intention.</p>
        <p>Please type <span class="phrase">{{data.user_input.phrase || 'CONFIRM'}}</span> to proceed.</p>
      </mat-hint>
      <mat-form-field class="width-100">
        <input matInput formControlName="confirmation" cdkFocusInitial autocomplete="off">
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">{{ data.button?.negative || 'CANCEL' }}</button>
    <button mat-button [mat-dialog-close]="true" color="{{data.danger === true ? 'warn' : 'primary'}}"
      [disabled]="form.enabled && !form.valid">
      {{ data.button?.positive || 'CONFIRM' }}
    </button>
  </mat-dialog-actions>
</form>
