var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ToastrService } from 'ngx-toastr';
import { ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { LocalStorage } from 'ngx-webstorage';
import { MatSnackBar } from '@angular/material';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@angular/material/snack-bar";
import * as i3 from "ngx-toastr";
export class UpdateService {
    constructor(swUpdate, appRef, snackBar, toastr) {
        this.swUpdate = swUpdate;
        this.appRef = appRef;
        this.snackBar = snackBar;
        this.toastr = toastr;
        if (!swUpdate.isEnabled) {
            return;
        }
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const timeIntervalOnceAppIsStable$ = concat(appIsStable$, interval(environment.update_check_interval));
        timeIntervalOnceAppIsStable$.subscribe(() => {
            console.log('checking for updates....');
            this.swUpdate.checkForUpdate().then(() => {
                this.checkForUpdates();
            });
        });
        if (this.anyNewUpdate) {
            this.displayUpdateMessage();
        }
    }
    checkForUpdates() {
        if (!this.swUpdate.isEnabled) {
            return;
        }
        this.swUpdate.available.subscribe((event) => {
            if (event.available.hash !== event.current.hash) {
                this.swUpdate.activateUpdate().then(() => {
                    this.anyNewUpdate = true;
                    this.displayUpdateMessage();
                });
            }
        });
    }
    displayUpdateMessage() {
        // this.snackBar.open('Update Available!', 'RELOAD', {
        // }).onAction().subscribe(() => {
        //   this.snackBar.dismiss();
        //   this.anyNewUpdate = false;
        //   window.location.reload(true);
        // });
        /* instead of displaying the prompt, just reload the page */
        this.anyNewUpdate = false;
        window.location.reload();
    }
}
UpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i2.MatSnackBar), i0.ɵɵinject(i3.ToastrService)); }, token: UpdateService, providedIn: "root" });
__decorate([
    LocalStorage('anyNewUpdate', false),
    __metadata("design:type", Object)
], UpdateService.prototype, "anyNewUpdate", void 0);
