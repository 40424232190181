import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AccountModule } from './account/account.module';
import { AuthModule } from './auth/auth.module';
import { StaticModule } from './static/static.module';
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WorkerService } from './worker.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { appVersion } from './app.version';
import { ToastrModule } from 'ngx-toastr';

Sentry.init({
  dsn: environment.sentry_dsn,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  environment: environment.mode,
  release: appVersion,
  enabled: environment.production,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxWebstorageModule.forRoot({ prefix: 'icf' }),
    AuthModule,
    AccountModule,
    AppRoutingModule,
    StaticModule,
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    WorkerService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
