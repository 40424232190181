import { AuthStatus, passwordValidator } from './../../app.utils';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { UserService } from '../../shared/api.services/user.service';
import { ErrorStateMatcher, MatSnackBar } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import * as md5 from 'md5';
import { SessionStorage } from 'ngx-webstorage';
import { appVersion } from 'app/app.version';
import { take, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { pwdCharLength, getTitleCase } from 'app/app.utils';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  @SessionStorage('redirect_to', null) private redirectTo;
  version = appVersion;
  now = new Date();
  loading = true;
  resetError = false;
  resetSuccess = false;
  visiblePassword = false;
  visibleConfirmation = false;
  user = null;
  accessToken = null;
  form: FormGroup;
  pwd = null;
  appstoreUrl = environment.appstore_url;
  playstoreUrl = environment.playstore_url;
  readonly isATS = environment.isATS;

  matcher = new MyErrorStateMatcher();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private authService: AuthService) {

    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required]]
    });

    this.password.valueChanges.subscribe(value => {
      this.pwd = value;
    });


  }

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      const token = params['token'];
      if (!token) {
        return this.router.navigateByUrl('/');
      }
      this.verifyToken(token);
    });
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get passwordConfirmation(): AbstractControl {
    return this.form.get('password_confirmation');
  }

  verifyToken(token: string) {
    this.authService.logout();
    this.userService.verify(token).pipe(catchError((err) => {
      this.loading = false;
      this.resetError = true;
      return throwError(err);
    })).subscribe((res: any) => {
      this.loading = false;
      this.user = res.data.user;
      this.accessToken = res.data.user_token;

      // add the password validatior in the form
      this.form.setValidators([Validators.required, (g: FormGroup) => {
        const pwd_value = g.get('password').value;
        const cnf_value = g.get('password_confirmation').value;

        return passwordValidator(pwd_value, cnf_value, this.user);
      }]);
    });
  }

  onSubmit() {
    if (!this.form.valid) { return; }
    this.loading = true;
    this.userService.passwordUpdate({
      id: this.user.id,
      password: this.password.value,
      access_token: this.accessToken,
      recovery_hash: md5(this.accessToken)
    }).pipe(catchError((err) => {
      this.loading = false;
      this.resetError = true;
      return throwError(err);
    })).subscribe((res: any) => {
      this.loading = false;
      if (res.data && res.data.status === AuthStatus.RESET_PASSWORD) {
        this.snackBar.open(`${getTitleCase(`This password is part of hacked passwords. please choose a stronger password.`)}`, 'CLOSE', {
          panelClass: 'snackbar-error',
          horizontalPosition: 'end',
        }).onAction().subscribe(() => {
          this.snackBar.dismiss();
        });
      } else {
        this.resetSuccess = true;
        this.redirectTo = '/';
      }
    });
  }
}
