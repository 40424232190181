<div class="container">
  <form (submit)="onSubmit()" data-cy="account|recovery_form|form">
    <mat-card>
      <mat-card-content>
        <div align="center">
          <div class="app-logo-icon"></div>
        </div>
        <div *ngIf="!loading && !resetSuccess">
          <div align="center">
            <h4>Password Reset</h4>
            <mat-label class="mat-caption">Enter your email, and we’ll send you instructions on how to reset your
              password</mat-label>
          </div>
          <br />
          <mat-form-field class="full">
            <input matInput placeholder="Email" [formControl]="emailControl" [errorStateMatcher]="matcher" autofocus data-cy="account|recovery_form|input|email" />
            <mat-error *ngIf="emailControl.hasError('email') && !emailControl.hasError('required')">
              Please enter a valid email address
            </mat-error>
          </mat-form-field>
          <div class="actions-wrapper" fxLayout="row" fxLayoutAlign="space-between none">
            <button mat-icon-button type="button" routerLink="/login" data-cy="account|recovery_form|button|login_page">
              <mat-icon>home</mat-icon>
            </button>
            <button mat-raised-button type="submit" color="primary" data-cy="account|recovery_form|button|reset_password">RESET PASSWORD</button>
          </div>
        </div>
        <div *ngIf="loading" align="center">
          <br />
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!loading && resetSuccess" align="center">
          <h4>Password Reset</h4>
          <mat-label>
            Password reset instructions sent to <strong>{{ emailControl.value }}</strong>!
          </mat-label>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  <div align="center" class="download-links-wrapper">
    <a [href]="appstoreUrl" target="_blank" *ngIf="appstoreUrl">
      <img width="130px" src="../../../assets/images/app-store.png" alt="Download from App Store">
    </a>
    <a [href]="playstoreUrl" target="_blank" *ngIf="playstoreUrl">
      <img width="130px" src="../../../assets/images/google-play.png" alt="Download from Play Store">
    </a>
  </div>
  <div class="app-footer" align="center">
    <div class="app-version">
      <span style="margin-bottom: 10px;">
        <a [routerLink]="['/legal/privacypolicy']">
          Privacy Policy
        </a>
      </span>
      <span>© {{isATS ? '' : 'iCrimeFighter - '}}At-Scene LLC {{ now.getFullYear() }}. All Rights Reserved. Cloud
        Version:
        {{ version }}.</span>
    </div>
  </div>
</div>
