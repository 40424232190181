<div class="static-container" style="min-height: 93vh;">
  <nav>
    <div class="app-logo" [routerLink]="['/app/']"></div>
  </nav>
  <div class="app-content" *ngIf="upload?.state !== 'select' && upload?.state !== 'selected'">
    <div class=" file-list">
      <div class="card upload" appDnd *ngIf="upload?.state !== 'uploading' && upload?.state !== 'ValidationError'">
        <div class="card-main">
          <div *ngIf="upload?.state === 'errored'" fxLayout="column" fxLayoutAlign="center center">
            <mat-icon class="icon-error" style="color: red; border: 6px solid red; border-radius: 50%;">close</mat-icon>
            <div class="status-message" style="margin: 20px;">{{erroredMessage}}</div>
          </div>
          <div *ngIf="upload?.state === 'confirmation'">
            <mat-icon>vpn_key</mat-icon>
            <h2 class="mat-body-2">Enter the verification code sent to your email to proceed</h2>
            <form class="otp-form" [formGroup]="otpForm" (submit)="onSubmit()">
              <mat-dialog-content>
                <mat-form-field class="width-50" style="text-align: center">
                  <input matInput formControlName="confirmation" name="confirmation" placeholder="Verification Code"
                    autocomplete="off">
                </mat-form-field>
              </mat-dialog-content>
              <mat-dialog-actions align="end">
                <button mat-raised-button type="submit" color="accent" [disabled]="!otpForm.valid">SEND</button>
              </mat-dialog-actions>
            </form>
            <br />
            <mat-hint style="font-size: 12px;" *ngIf="!activateResendOtp">
              Not received the code? <br />Request another verification code after {{timeout}} seconds.
            </mat-hint>
            <button style="width: 50%;" mat-button color="primary" [disabled]="!activateResendOtp"
              (click)="onResendCode()">
              Request Verification Code
            </button>
          </div>
          <div *ngIf="upload?.state === 'upload_confirmation'">
            <mat-icon style="background: #fdd835; border-radius: 50%;">check</mat-icon>
            <div style="margin-top: 10px;">
              <span *ngIf="finishedExtUploads">{{ finishedExtUploads }} {{ finishedExtUploads > 1 ? 'Files' : 'File'
                }}</span>
              Uploaded Successfully
            </div>
            <div class="status-message" style="margin: 30px;">
              The confirmation code for this upload is: <b>{{confirmationNumber}}</b>.
              <br />
              <br />
              Please refer to this confirmation in all your future communications.
            </div>
          </div>

          <div *ngIf=" upload?.state==='changeDevice'" fxLayout="column" fxLayoutAlign="center center">
            <img class="no-IE" src="../../../assets/images/IE_prohibited.png" alt="IE-not-supported"
              style="height: 120px;">
            <div class="status-message" style="margin: 20px;">{{erroredMessage}}</div>
            <mat-hint class="open-link-button">
              <a href="" target="_blank" style="color: inherit; text-decoration: none; cursor: pointer;"
                (click)="openInEdge()">
                <img class="edge-logo" src="../../../assets/images/edge-logo.jpg" alt="edge-logo" style="height: 30px;">
                <span style="font-size: 16px; position: relative; bottom: 8px;">
                  Upload files from Edge
                </span>
              </a>
            </mat-hint><br />
            <mat-hint style="font-size: 14px;">
              Or
            </mat-hint>
            <div class="download-buttons">
              <span class="open-link-button">
                <a href="" target="_blank" style="color: inherit; text-decoration: none; cursor: pointer;"
                  (click)="openEdgeLink()">
                  <img class="edge-logo" src="../../../assets/images/edge-logo.jpg" alt="edge-logo"
                    style="height: 30px;">
                  <span style="font-size: 16px; position: relative; bottom: 8px;">
                    Download Edge
                  </span>
                </a>
              </span>
              <span class="open-link-button">
                <a href="" target="_blank" style="color: inherit; text-decoration: none; cursor: pointer;"
                  (click)="openFirefoxLink()">
                  <img class="edge-logo" src="../../../assets/images/firefox-logo.png" alt="edge-logo"
                    style="height: 30px;">
                  <span style="font-size: 16px; position: relative; bottom: 8px;">
                    Download Firefox
                  </span>
                </a>
              </span>
              <span class="open-link-button">
                <a href="" target="_blank" style="color: inherit; text-decoration: none; cursor: pointer;"
                  (click)="openChromeLink()">
                  <img class="edge-logo" src="../../../assets/images/chrome-logo.png" alt="chrome-logo"
                    style="height: 30px;">
                  <span style="font-size: 16px; position: relative; bottom: 8px;">
                    Download Chrome
                  </span>
                </a>
              </span>
            </div>
          </div>

          <div *ngIf=" upload?.state==='connecting'">
            <mat-progress-spinner class=" progress-spinner" [color]="color" mode='indeterminate'>
            </mat-progress-spinner>
            <div style="font-size: 16px; margin: 10px 0;">Connecting...</div>
          </div>
        </div>
      </div>
      <div *ngIf="upload?.state === 'uploading'" class="uploading" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>cloud_upload</mat-icon>
        <div class="status-message" style="margin: 20px;">
          Your files are now uploading. Please monitor the progress in the progress bar below.
        </div>
      </div>
      <div *ngIf="upload?.state === 'ValidationError'" class="uploading" fxLayout="column"
        fxLayoutAlign="center center">
        <mat-icon>report</mat-icon>
        <div class="status-message" style="margin: 20px;">
          You have reached the maximum number of incorrect attempts. Try again later.
        </div>
      </div>
    </div>
  </div>
  <div class="content-wrapper" *ngIf="upload?.state === 'select' || upload?.state === 'selected'">
    <mat-card>
      <div class="card-header" *ngIf="fileList.length !== 100">
        <div class="upload-box" (click)="fileUpload.click()">
          <mat-icon style="color: #5f5e5e;">cloud_upload</mat-icon>
          <span style="display: block; position: relative; bottom: 4px;">Browse</span>
          <input #fileUpload multiple required type="file" (change)="onFileSelect($event); fileUpload.value='';"
            [style.display]="'none'" />
        </div>
        <mat-hint style="font-size: 12px"
          [ngStyle]="{'color': limitExceeded ? 'red' : '#5f5e5e;', 'font-weight': limitExceeded ? '500' : 'inherit'}">
          <mat-icon>info</mat-icon>
          You can upload a maximum of {{maxFileNumber}} files
          <span *ngIf="fileList.length > 0 && fileList.length < (maxFileNumber + 1)">
            <span *ngIf="fileList.length === 1">({{fileList.length}} file chosen)</span>
            <span *ngIf="fileList.length > 1">({{fileList.length}} files chosen)</span>
          </span>
        </mat-hint>
      </div>

      <div class="card-body">
        <mat-chip-list class="upload-chips" #chipList
          *ngIf="upload?.state === 'selected'&& fileList.length > 0 && fileList.length < (maxFileNumber + 1)"
          aria-orientation="vertical">
          <mat-chip *ngFor="let file of fileList" (removed)="remove(file)">
            {{ file.name | truncate:[40] }}
            <mat-icon matChipRemove matTooltip="remove this file">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>

      <div class="card-footer"
        *ngIf="upload?.state === 'selected'&& fileList.length > 0 && fileList.length < (maxFileNumber + 1)">
        <div class=" card-footer-message">
          <mat-checkbox (change)="onEnableUpload($event)">
            I solemnly swear that these are original unmodified files
            related to my concern.
          </mat-checkbox>
        </div>
        <div style="text-align: center">
          <button mat-raised-button color="accent" [disabled]="!isUploadEnabled" (click)="uploadFile()">
            <mat-icon style="font-size: 21px;">cloud_upload</mat-icon>
            Upload
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<footer>
  <div class="app-version">
    <span>© iCrimeFighter - At-Scene LLC {{ now.getFullYear() }}. All Rights Reserved. Cloud Version:
      {{ version }}.</span>
  </div>
</footer>
