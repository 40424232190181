<div class="container">
  <div align="center">
    <div class="icf-logo-icon"></div>
  </div>
  <mat-card>
    <mat-card-content align="center" style="text-align: center;">
      <h1 class="mat-title">{{'Page Not Found' | titlecase}}</h1>
      <!-- <h2 class="mat-body-2">{{'The page you are trying to access is not found.' | titlecase}}</h2> -->
      <button mat-button type="button" color="primary" routerLink="/">HOME</button>
    </mat-card-content>
  </mat-card>
  <div align="center" class="download-links-wrapper">
    <a href="https://itunes.apple.com/us/app/icrimefighter/id462008847" target="_blank" class="link">
      <img src="../../../assets/images/app-store.png" alt="Download from App Store">
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.icrimefighter" target="_blank" class="link">
      <img src="../../../assets/images/google-play.png" alt="Download from Google Play">
    </a>
  </div>
  <div class="app-footer">
    <div class="app-version">
      <span>© iCrimeFighter - At-Scene LLC {{ now.getFullYear() }}. All Rights Reserved. Cloud Version:
        {{ version }}.</span>
    </div>
  </div>
</div>
