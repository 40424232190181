import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { errorMsgConfig, successMsgConfig } from './../../../app.utils';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { UploadService } from 'app/shared/api.services/upload.service';
import { MatBottomSheetRef, MatExpansionPanel, MatSnackBar, MAT_BOTTOM_SHEET_DATA, MatDialog } from '@angular/material';
import { getFileType, allowedFileExtensions, getFileSignature, ApiError, invalidUploadReason, getTitleCase } from 'app/app.utils';
import { Upload } from 'app/models/upload';
import { UploadStatus } from '../../../../../worker/app-workers/shared/worker.constants';
import { countBy, each, findIndex } from 'lodash';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {
  readonly allowedFileTypes = allowedFileExtensions;
  uploads = this.uploadService.uploads;
  caseId: any;
  isExternalUpload: boolean;
  UploadStatus = UploadStatus;
  folders = [];
  totalUploads: number;
  resumingUpload: Upload;
  uploadedFiles;
  pendingUploadArr: Upload[] = [];
  erroredUploadArr: Upload[] = [];
  uploaderStatus: string = '';
  totalUploadStats: any[] = [];
  totalFolderStats: any[] = [];
  closeTooltipText = '';
  ongoingUploads: any[] = [];

  readonly invalidUploadReason = invalidUploadReason;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatExpansionPanel;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    private bottomSheetRef: MatBottomSheetRef<UploaderComponent>,
    public dialog: MatDialog,
    private uploadService: UploadService,
    private router: Router,
    private authService: AuthService) {
    this.initUploadHandler();
  }

  /*
    handle ongoing, errored, pending uploads
    display summary
  */
  initUploadHandler() {
    this.uploadService.uploads.subscribe((uploads) => {
      this.ongoingUploads = uploads;
      if (uploads.length === 0) {
        // summary not required for the external upload
        // upload bar should be closed
        if (this.isExternalUpload) {
          this.bottomSheetRef.dismiss();
        } else {
          if (this.uploadService.finishedUploads.length) { // display summary
            this.uploaderStatus = 'summary';
            this.closeTooltipText = 'Close';
            this.totalUploadStats = this.countFinishedUploads(this.uploadService.finishedUploads);
            this.totalFolderStats = this.countFinishedUploads(this.uploadService.folders);

            if (this.uploadService.invalidUploads.length) {
              const invalidUploadSStats = this.countInvalidUploads(this.uploadService.invalidUploads);
              this.totalUploadStats = [...this.totalUploadStats, ...invalidUploadSStats];
            }
            this.expansionPanel.open();
          } else this.bottomSheetRef.dismiss();
        }

      } else { // ongoing uploads
        if (this.uploaderStatus === 'summary') // if summary panel is opened, close it
          this.expansionPanel.close();

        this.closeTooltipText = 'Cancel';
        this.uploaderStatus = 'uploading';

        this.totalUploads = this.uploadService.totalUploads;
        this.uploadedFiles = this.isExternalUpload ? this.uploadService.finishedExtUplaods.length :
          this.uploadService.finishedUploads.length;

        if (this.uploadService.folders.length) {
          this.folders = this.uploadService.folders;
        }

        if (uploads.some(u => u.state === UploadStatus.PENDING)) {
          this.uploaderStatus = 'pending';
          this.pendingUploadArr = uploads.filter(u => u.state === UploadStatus.PENDING);
        }

        /* delete paused uploads in case of cancel request */
        const paused_uploads = uploads.filter(u => u.state === UploadStatus.PAUSED);
        if (paused_uploads.length) {
          each(paused_uploads, upload => this.onDelete(upload));
        }

        /* delete errored uploads due to invalid session */
        this.erroredUploadArr = uploads.filter(u => u.state === UploadStatus.ERRORED);
        if (this.erroredUploadArr.some(upload => upload.errorTitle === ApiError.InvalidToken)) {
          this.snackBar.open(`Session Expired!`, 'CLOSE', {
            panelClass: 'snackbar-error',
            horizontalPosition: 'end',
          }).onAction().subscribe(() => {
            this.snackBar.dismiss();
          });

          each(this.erroredUploadArr, upload => this.onDelete(upload));
          this.authService.logout();
          this.router.navigateByUrl('/login');
        }
      }
    });
    if (this.data && this.data.isExternalUpload) {
      this.isExternalUpload = this.data.isExternalUpload;
    }
  }

  onSelectToResume(upload: Upload) {
    this.resumingUpload = upload;
    this.fileInput.nativeElement.click();
  }

  onFileSelect(event: { target: HTMLInputElement; }) {
    this.onFileDropped(event.target.files);
  }

  onFileDropped(files: File[] | FileList) {
    each(files, (file) => {
      const type = getFileType(file);
      if (getFileSignature(this.resumingUpload.file) !== getFileSignature(file)) {
        this.snackBar.open(`Please Select '${this.resumingUpload.file.name}' To Resume This Upload.`, null, {
          duration: 3000,
        });
      } else {
        const id = this.resumingUpload.id;
        this.resumingUpload = null;
        this.uploadService.create({
          id,
          file, type,
          case_id: null,
        });
      }
    });
  }

  onRetry(upload: Upload) {
    const file = upload.file;
    const type = upload.type;
    const case_id = upload.case_id;
    const case_name = upload.case_name;
    const folder_id = upload.folder_id;

    this.uploadService.create({
      id: upload.id,
      file, type,
      case_id, case_name, folder_id
    });
  }

  onRetryAll(event) {
    event.stopPropagation();
    each(this.erroredUploadArr, upload => this.onRetry(upload));
  }

  onPause(upload: Upload) {
    this.uploadService.pause(upload);
  }

  onResume(upload: Upload) {
    this.uploadService.resume(upload);
  }

  /*
    delete the queued/errored upload
    pause the ongoing upload, later in the initUploadHandler subscribe callback
    delete the paused upload
  */
  onPauseAndDelete(upload: Upload) {
    if (upload.state === UploadStatus.QUEUED || upload.state === UploadStatus.ERRORED) {
      this.onDelete(upload);
    } else this.onPause(upload);
  }

  onDelete(upload: Upload) {
    if (this.uploadService.totalUploads !== 0)
      this.uploadService.totalUploads--;
    this.uploadService.delete(upload);
  }

  async onClose(event) {
    event.stopPropagation();

    if (this.uploaderStatus === 'pending') {
      each(this.pendingUploadArr, upload => this.onDelete(upload));
      this.bottomSheetRef.dismiss();
    } else if (this.uploaderStatus === 'uploading') {
      /*
       workaround solution for cancelling all ongoing uploads,
       by reloading the page
       "uploaderReload" flage used to disable the alert box on reloading the page
      */
      this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        width: '500px',
        data: {
          title: 'Cancel Upload',
          message: 'Are you sure you want to cancel the ongoing upload now?',
        }
      }).afterClosed().subscribe(res => {
        if (res) {
          this.uploadService.uploaderCancel = true;
          location.reload();
        }
      });
    } else {
      this.bottomSheetRef.dismiss({ isFinished: true });
    }
  }


  /*
    get the distinctCount obj with the no. of count per case id
    from the distinctCount obj get the array with obj containing case_name, case_id and count
  */
  private countFinishedUploads(finishedUploads): Array<any> {
    const distinctCaseUploads = [];
    const distinctCount = countBy(finishedUploads, up => up.case_id);
    for (let key in distinctCount) {
      const index = findIndex(finishedUploads, (up: any) => up.case_id.toString() === key);
      distinctCaseUploads.push({ case_id: key, case_name: finishedUploads[index].case_name, count: distinctCount[key] });
    }

    return distinctCaseUploads;
  }

  /*
    get json array for distinct count of invalid uploads with reason
  */
  private countInvalidUploads(invalidUploads): Array<any> {
    let distinctInvalidUpload = [];

    for (let i = 0; i < invalidUploads.length; i++) {
      if (distinctInvalidUpload.some(u => u.case_id === invalidUploads[i].case_id && u.reason === invalidUploads[i].reason)) {
        const index = findIndex(distinctInvalidUpload, u => u.case_id === invalidUploads[i].case_id && u.reason === invalidUploads[i].reason);
        distinctInvalidUpload[index].count++;
      } else {
        /* first occurence */
        distinctInvalidUpload.push({
          case_id: invalidUploads[i].case_id,
          case_name: invalidUploads[i].case_name,
          reason: invalidUploads[i].reason,
          count: 1
        });
      }
    }

    return distinctInvalidUpload;
  }

  onViewCase(case_id: string): void {
    // trigger reload event on the sameUrlNavigation by passing a dummy navigation
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(`/app/cases/${case_id}`);
    });
  }
}
