import { Injectable } from '@angular/core';
import { each } from 'lodash';
import { HttpService } from '../http.service';
import { ServerConstants } from '../constants';
import { User } from '../../models/user';
import * as md5 from 'md5';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permissions } from 'app/models/permissions';

@Injectable()
export class UserService {
  private baseUrl = ServerConstants.Users;
  private _users: BehaviorSubject<User[]>;
  private _permissons: BehaviorSubject<Permissions[]>;
  public readonly users: Observable<User[]>;
  public readonly permissions: Observable<Permissions[]>;
  private dataStore: {
    users: User[],
    permissions: Permissions[],
  };

  constructor(private httpService: HttpService) {
    this.dataStore = { users: [], permissions: [] };
    this._users = <BehaviorSubject<User[]>>new BehaviorSubject([]);
    this._permissons = <BehaviorSubject<Permissions[]>>new BehaviorSubject([]);
    this.users = this._users.asObservable();
    this.permissions = this._permissons.asObservable();
  }

  get data(): User[] { return this._users.value; }

  get(id: string) {
    return this.users.pipe(map(users => users.find(item => item.id.toString() === id.toString())));
  }

  loadAll(agencyId: string, type?: string): any {
    const params: any = { agency_id: agencyId };
    if (type) {
      params.type = type;
    }
    return this.httpService.get(this.baseUrl, { params }).pipe(map(response => {
      this.dataStore.users = response['data'];
      this._users.next(Object.assign({}, this.dataStore).users);
    })).subscribe();
  }

  load(id: string): any {
    return this.httpService.get(`${this.baseUrl}/${id}`).pipe(map(response => {
      let notFound = true;

      this.dataStore.users.forEach((item, index) => {
        if (item.id === response['data'].id) {
          this.dataStore.users[index] = response['data'];
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.users.push(response['data']);
      }

      this._users.next(Object.assign({}, this.dataStore).users);
      return response['data'];
    }));
  }

  create(user: User) {
    return this.httpService.post(this.baseUrl, user).pipe(map(response => {
      user.id = response['data'].id;
      this.dataStore.users.push(user);
      this._users.next(Object.assign({}, this.dataStore).users);
      return response;
    }));
  }

  bulkCreate(agencyId, users: User[]) {
    const params = {
      agency_id: agencyId,
      users: users
    };
    return this.httpService.post(`${this.baseUrl}/batch`, params).pipe(map((response: any) => {
      each(response.data, user => this.dataStore.users.push(user));
      this._users.next(Object.assign({}, this.dataStore).users);
      return response;
    }));
  }

  update(user: User) {
    return this.httpService.post(`${this.baseUrl}/${user.id}/update`, user).pipe(map((response: any) => {
      this.dataStore.users.forEach((c, i) => {
        if (c.id === user.id) { this.dataStore.users[i] = response.data; }
      });

      this._users.next(Object.assign({}, this.dataStore).users);
      return response.data;
    }));
  }

  remove(id: string) {
    return this.httpService.delete(`${this.baseUrl}/${id}`).pipe(map(response => {
      this.dataStore.users.forEach((c, i) => {
        if (c.id === id) { this.dataStore.users.splice(i, 1); }
      });

      this._users.next(Object.assign({}, this.dataStore).users);
      return response;
    }));
  }

  restore(id: string) {
    return this.httpService.post(`${this.baseUrl}/${id}/restore`).pipe(map(response => {
      this.dataStore.users.forEach((c, i) => {
        if (c.id === id) { this.dataStore.users.splice(i, 1); }
      });

      this._users.next(Object.assign({}, this.dataStore).users);
      return response;
    }));
  }

  profile() {
    return this.httpService.get(`${this.baseUrl}/me`);
  }

  reset(email: string) {
    return this.httpService.post(`${this.baseUrl}/reset`, { email });
  }

  resetByUserID(id: string) {
    return this.httpService.post(`${this.baseUrl}/reset`, { id });
  }

  passwordUpdate(obj: any) {
    if (obj.password) { obj.password = obj.password }
    return this.httpService.post(`${this.baseUrl}/${obj.id}`, obj);
  }

  changePassword(data: { current_password: string, new_password: string }) {
    return this.httpService.post(`${this.baseUrl}/password`, data).pipe(map((response: any) => {
      return response;
    }));
  }

  verify(token: string) {
    return this.httpService.get(`${this.baseUrl}/verify`, { params: { token } });
  }

  getPermissions(id: string, agencyId: string) {
    return this.httpService.get(`${this.baseUrl}/${id}/permissions?agency_id=${agencyId || ''}`);
  }

  updatePermissions(id: string, data) {
    return this.httpService.put(`${this.baseUrl}/${id}/permissions`, data);
  }

  getPreferences(id: string) {
    return this.httpService.get(`${this.baseUrl}/${id}/preferences`);
  }

  updatePreferences(id: string, data) {
    return this.httpService.post(`${this.baseUrl}/${id}/preferences`, data);
  }

  login(username: string, password: string, recaptchaResponse?: string): Observable<any> {
    return this.httpService.post(`${this.baseUrl}/login`, {
      username: username,
      password: password,
      recaptcha_response: recaptchaResponse,
      role: 'user'
    });
  }

  logout(payload: any) {
    return this.httpService.post(`${this.baseUrl}/logout`, payload);
  }

  verifyOtp(payload: any) {
    return this.httpService.post(`${this.baseUrl}/verify2FA`, payload);
  }

  checkUser(data: any) {
    return this.httpService.post(`${this.baseUrl}/checkUser`, data);
  }
}

