// use when ui or db is not dependent on the error message
export async function request(req: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.open(req.method || 'GET', req.url);

    if (req.headers) {
      Object.keys(req.headers).forEach(key => {
        xhr.setRequestHeader(key, req.headers[key]);
      });
    }
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(req.body);
  });
};
