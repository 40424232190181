import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/api.services/user.service';
import { FormControl, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { appVersion } from 'app/app.version';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MyErrorStateMatcher } from 'app/app.utils';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {
  version = appVersion;
  now = new Date();
  appstoreUrl = environment.appstore_url;
  playstoreUrl = environment.playstore_url;
  readonly isATS = environment.isATS;
  loading = false;
  resetSuccess = false;
  emailControl = new FormControl('', [Validators.required, Validators.email]);
  matcher = new MyErrorStateMatcher();

  constructor(
    private userService: UserService,) { }

  ngOnInit() { }

  onSubmit() {
    if (this.emailControl.errors !== null) { return; }

    this.loading = true;
    this.userService.reset(this.emailControl.value.trim()).pipe(catchError((err) => {
      this.resetSuccess = true;
      this.loading = false;
      return throwError(err);
    })).subscribe(res => {
      this.resetSuccess = true;
      this.loading = false;
    });
  }

}
