<div class="container">
  <div align="center">
    <div class="icf-logo-icon"></div>
  </div>
  <mat-card>
    <mat-card-content align="center">
      <div fxLayout="column" fxLayoutAlign="center center" space-between>
        <img class="no-IE" src="../../../assets/images/IE_prohibited.png" alt="IE-not-supported" style="height: 120px;">
        <div class="status-message">{{ message }}</div>
        <div class="link-buttons" fxLayout="row" fxLayoutAlign="center center" space-between fxLayoutGap="8px"
          style="width: 100%;">
          <button mat-raised-button class="copy-link-button" (click)="openInEdge()" style="padding: 4px 11px;">
            <span>
              <img class="edge-logo" src="../../../assets/images/edge-logo.jpg" alt="link"
                style="height: 24px; position: relative; top: 6px;">
              <span style="position: relative; top: 0px; left: 4px;">Open in Edge</span>
            </span>
          </button>
          <button mat-raised-button class="copy-link-button" ngxClipboard [cbContent]="directLink"
            (click)="onCopyLink()" style="padding: 4px 0px;">
            <span>
              <img class="edge-logo" src="../../../assets/images/icf-link.svg" alt="link"
                style="height: 22px; position: relative; top: 6px;">
              <span style="position: relative; top: 0px; left: 4px;">Copy iCF Link</span>
            </span>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="app-footer">
    <div class="app-version">
      <span>© iCrimeFighter - At-Scene LLC {{ now.getFullYear() }}. All Rights Reserved. Cloud Version:
        {{ version }}.</span>
    </div>
  </div>
</div>
