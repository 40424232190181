<div mat-dialog-title>
  <span>My Downloads</span>
  <!-- <mat-hint *ngIf="!isLoading" style="font-size: 12px; font-weight: 500; margin-left: 10px;">
    The download links are active for 7days
  </mat-hint> -->
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<mat-card class="case-list" *ngIf="!isLoading">
  <mat-card-content>
    <div class="card-header" align="start">
      <div class="card-header-note">
        {{'*The download links are active for 30 days' | titlecase}}
      </div>
      <div class="card-header-action" align="end">
        <button mat-icon-button (click)="onRefresh()" matTooltip="refresh">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <mat-form-field floatPlaceholder="never" class="width-100">
      <input matInput #filter (keyup)="applyFilter($event.target.value)" placeholder="search by case name">
    </mat-form-field>
    <mat-table #table [dataSource]="dataSource" matSort [matSortActive]="defaultSortColumn" matSortDirection="desc">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Case Name</mat-header-cell>
        <mat-cell *matCellDef="let data" [matTooltip]="data.case.name">{{data.case.name| truncate:[10]}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="files">
        <mat-header-cell *matHeaderCellDef>Total Files</mat-header-cell>
        <mat-cell *matCellDef="let data">{{data.file_ids.length}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let data">
          {{data.status === Status.PROCESSING ? 'Processing' : 'Active'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Created At</mat-header-cell>
        <mat-cell *matCellDef="let data">{{data.created_at | amDateFormat: 'YYYY-MM-DD HH:mm'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="download">
        <mat-header-cell *matHeaderCellDef>Download</mat-header-cell>
        <mat-cell *matCellDef="let data; let i = index">
          <button mat-icon-button matTooltip="Start Download" (click)="onDownload(data.token)"
            *ngIf="data.status === Status.ACTIVE" [attr.data-cy]="'case|download_dialog|button|delete_' + i">
            <mat-icon>cloud_download</mat-icon>
          </button>
          <span class="loader" *ngIf="data.status===Status.PROCESSING" matTooltip="Processing">
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>
        <mat-cell *matCellDef="let data">
          <button mat-icon-button color="warn" matTooltip="Delete This Download" (click)="onDelete(data.token)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 30]" showFirstLastButtons>
    </mat-paginator>
  </mat-card-content>
</mat-card>
