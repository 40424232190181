import { errorMsgConfig } from './../../app.utils';
import { ToastrService } from 'ngx-toastr';
import { getFileType, ApiError, removeNonASCII, logToSentry, ErrorTags, getEncodedFileUploadName, getDecodedFileUploadName, maxFileExtLength, getTitleCase } from 'app/app.utils';
import { each } from 'lodash';
import { request } from '../../../../worker/utils';
import { appVersion } from 'app/app.version';
import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatCheckboxChange, MatDialog, MatBottomSheet, MatSnackBar } from '@angular/material';
import { UploadService } from 'app/shared/api.services/upload.service';
import { AuthService } from 'app/auth/auth.service';
import { UploaderComponent } from 'app/shared/components/uploader/uploader.component';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, AfterViewInit {
  now = new Date();
  version = appVersion;
  uploads = this.uploadService.uploads;
  otpForm: FormGroup;
  confirmationNumber;
  finishedExtUploads: number;
  fileList = [];
  @ViewChild('fileUpload', { static: false }) uploadBtn: ElementRef;
  upload: {
    can_retry?: boolean,
    input?: string,
    file?: File,
    files?: FileList | File[],
    desc?,
    state?: string,
    status?: string,
    message?: string,
    progressValue?: string,
    uploadedBytes?: number,
    totalBytes?: number,
    processor?: any,
  } = {};
  token: string;
  return_url: string;
  fileName: string;
  isUploadEnabled = false;
  color = '#333333';
  uploaderOpen = false;
  activateResendOtp = false;
  timeout = 120;
  erroredMessage: string;
  sitekey = environment.app_site_key;
  failedAttempts = 0;
  dataSource;
  displayedColumns: string[] = ['choose-file', 'name', 'desc'];
  chromeDownloadLink = 'https://www.google.com/chrome/';
  edgeDownloadLink = 'https://www.microsoft.com/en-us/edge';
  firefoxDownloadLink = 'https://www.mozilla.org/en-US/firefox/new/';
  edgeTargetLink = '';
  limitExceeded = false;
  maxFileNumber = 100;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private uploadService: UploadService,
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    private bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      this.token = params['token'];
      this.return_url = `${environment.edp_url}${params['ru'] || ''}`;
      if (!this.token) {
        return this.router.navigateByUrl('/');
      }
    });
    this.uploadService.initExternalUpload();
    this.detectDevice();
  }

  ngAfterViewInit() {
    this.uploadService.uploads.subscribe(uploads => {
      if (uploads.length > 0 && this.uploaderOpen === false) {
        this.uploaderOpen = true;
        this.upload.state = 'uploading';
        setTimeout(() => this.openUploader(), 1000);
      }
    });
  }

  private detectDevice() {
    if (this.deviceService.getDeviceInfo().browser === 'IE') {
      this.upload.state = 'changeDevice';
      this.erroredMessage = `Internet Explorer is not supported!`;
      this.edgeTargetLink = `microsoft-edge:${environment.deploy_url}upload?token=${this.token}`;
    } else {
      this.upload.state = 'connecting';
      this.getVerificationToken(this.token);
    }
  }

  private getVerificationToken(token) {
    this.uploadService.generateVerificationToken(token).subscribe(res => {
      this.otpForm = this.formBuilder.group({
        confirmation: ['', [Validators.required, this.confirmationValidator()]],
      });
      this.upload.state = 'confirmation';
      setTimeout(() => {
        this.activateResendOtp = true;
      }, 120000);
      this.timeCount();
    }, err => {
      this.router.navigateByUrl(`/upload?token=${this.token}`);
      this.upload.state = 'errored';
      this.erroredMessage = 'Sorry, the link has expired! Please contact the person who shared this link with you.';
      console.log(err);
    });
  }

  private confirmationValidator() {
    return (control: FormControl) => {
      if (control.value.length === 6) {
        return null;
      } else {
        return { 'confirmation_error': true }
      }
    };
  }

  private timeCount() {
    if (this.timeout !== 0) {
      this.timeout--;
      setTimeout(() => {
        this.timeCount();
      }, 1000);
    }
  }

  get recaptcha(): AbstractControl {
    return this.otpForm.get('recaptcha');
  }

  private openUploader() {
    const uploaderRef = this.bottomSheet.open(UploaderComponent, {
      hasBackdrop: false,
      closeOnNavigation: false,
      data: { isExternalUpload: true }
    });
    uploaderRef.afterOpened().subscribe(res => this.uploaderOpen = true);
    uploaderRef.afterDismissed().subscribe(res => {
      this.uploaderOpen = false;
      if (this.uploadService.finishedExtUplaods.length) // confirmation after successful upload
        this.uploadConfirmation();
      else { // back to "browse-file" screen
        this.upload.state = 'select';
        this.upload.files = this.fileList = [];
        this.uploadService.totalUploads = 0;
      }
    });
  }

  openChromeLink() {
    window.open(this.chromeDownloadLink);
  }

  openEdgeLink() {
    window.open(this.edgeDownloadLink);
  }

  openFirefoxLink() {
    window.open(this.firefoxDownloadLink);
  }

  openInEdge() {
    window.open(this.edgeTargetLink);
  }


  onFileSelect(event: { target: HTMLInputElement; }) {
    this.upload.state = 'selected';
    if (event.target.files.length > this.maxFileNumber) {
      this.limitExceeded = true;
    } else {
      this.limitExceeded = false;
      this.onFileDropped(event.target.files);
    }
  }

  private onFileDropped(files: File[] | FileList) {
    this.upload.files = files;
    each(this.upload.files, (file) => {
      if (this.fileList.length < (this.maxFileNumber + 1)) {
        this.limitExceeded = false;
        this.fileList.unshift(file);
      } else {
        this.limitExceeded = true;
      }
    });
  }

  remove(file) {
    const index = this.fileList.indexOf(file);
    if (index >= 0) {
      this.fileList.splice(index, 1);
    }
    this.limitExceeded = this.fileList.length < (this.maxFileNumber + 1) ? false : true;
  }

  onEnableUpload(event: MatCheckboxChange) {
    this.isUploadEnabled = event.checked;
  }

  uploadFile() {
    each(this.fileList, (fileData) => {
      // attach uuid string with the file name
      // so evaporate can generate unique file signature
      const encodedFileName: string = getEncodedFileUploadName(removeNonASCII(fileData.name));

      // clone the file blob and sanitize the name
      // pass the type and lastmodified in the syntax
      let file = new File([fileData], encodedFileName, { type: fileData.type, lastModified: fileData.lastModified });

      const upload = {
        file: file,
        type: getFileType(fileData), // get type from the file before encoding
      };

      const ext = getDecodedFileUploadName(file.name).split('.').pop();
      if (ext.length > maxFileExtLength) {
        this.snackBar.open(`Invalid File Name!`, null, {
          duration: 3000,
        });
      } else if (file.size !== 0) {
        this.uploadService.totalUploads++;
        this.uploadService.createExternalUpload(upload, this.token);
      } else {
        logToSentry(`uploading 0 byte file(ext upload),file-${file}`, ErrorTags.UploadError);
      }
    });
  }

  private async uploadConfirmation() {
    try {
      const res = await request({
        url: `${environment.api_url}uploads/external/confirm`,
        method: 'POST',
        headers: {
          authorization: this.token,
        }
      });
      if (res.status) {
        this.upload.state = 'upload_confirmation';
        this.confirmationNumber = res.data.confirmation_number;
        this.finishedExtUploads = this.uploadService.finishedExtUplaods.length;
      }
    } catch (err) {
      this.router.navigateByUrl(`/upload?token=${this.token}`);
      this.upload.state = 'errored';
      this.erroredMessage = 'Something went wrong while confirming you upload! Please contact the person who shared this link with you.';
      return throwError(err);
    }
  }

  uploadAnother() {
    this.upload = {};
  }

  resetUploadForm() {
    this.isUploadEnabled = false;
    this.fileName = '';
  }

  goToSelectState() {
    this.upload.state = 'select';
    this.upload.file = null;
    this.upload.processor = null;
    this.isUploadEnabled = false;
  }

  onSubmit() {
    this.upload.state = 'connecting';
    const confirmationCode = this.otpForm.getRawValue().confirmation;
    this.authService.verifyConfirmationCode(confirmationCode, this.token).subscribe(res => {
      this.upload.state = 'select';
    }, (err: HttpErrorResponse) => {
      if (err.error.error.name === ApiError.ValidationLimitReached) {
        this.router.navigateByUrl(`/upload?token=${this.token}`);
        this.upload.state = 'ValidationError';
      } else {
        this.upload.state = 'confirmation';
        this.otpForm = this.formBuilder.group({
          confirmation: ['', [Validators.required, this.confirmationValidator()]],
        });
        this.router.navigateByUrl(`/upload?token=${this.token}`);
        this.snackBar.open(`${getTitleCase('Code is invalid or inactive!')}`, null, {
          duration: 3000
        });
      }
      console.log(err);
    });
  }

  onResendCode() {
    this.upload.state = 'connecting';
    this.authService.resendVerificationCode(this.token, 'upload').subscribe(res => {
      this.upload.state = 'confirmation';
      this.timeout = 120;
      this.activateResendOtp = false;
      this.timeCount();
    }, err => {
      this.router.navigateByUrl(`/upload?token=${this.token}`);
      this.upload.state = 'errored';
      this.erroredMessage = 'Something went wrong! Please contact the person who shared this link with you.';
      console.log(err);
    });
  }
}
