<div class="container">
  <div align="center">
    <div class="icf-logo"></div>
  </div>

  <mat-card class="login-card" style="background: transparent; color: white;" *ngIf="server_down">
    <div class="maintanance" align="start">
      <mat-card-header style="text-align: center;">
        <div mat-card-avatar class="example-header-image">
          <mat-icon>engineering</mat-icon>
        </div>
        <mat-card-title class="mat-body-2">Upcoming Scheduled Maintenance</mat-card-title>
        <mat-card-subtitle style="color: white;padding-top: 5px; position: relative; top: 7px;">
          The system will not be available from {{server_down_from | amDateFormat: 'YYYY-MM-DD HH:mm'}} to
          {{server_down_to |
          amDateFormat: 'YYYY-MM-DD HH:mm'}} due to a scheduled
          maintenance.
          Thank you for your patience.
        </mat-card-subtitle>
      </mat-card-header>
    </div>
  </mat-card>

  <mat-card class="login-card">
    <div *ngIf="!loading">
      <!-- Account Selection -->
      <div class="accounts-selection" *ngIf="state === 'account_selection'">
        <mat-card-header>
          <mat-card-title>Choose an account</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list role="list" [ngClass]="{'existing-users-list': users.length > 3}">
            <mat-list-item role="listitem" *ngFor="let user of users; let i = index; let last = last;"
              (click)="onAccountSelect(i)" [attr.data-cy]="'account|login_form|list_item|' + i">
              <mat-icon mat-list-icon>person</mat-icon>
              <h3 mat-line>{{ user.name }}</h3>
              <p mat-line>{{ user.email }}</p>
              <span class="status-text">Signed out</span>
              <mat-divider></mat-divider>
              <mat-progress-bar mode="indeterminate" *ngIf="selectedAccountIndex === i"></mat-progress-bar>
            </mat-list-item>
          </mat-list>
          <mat-list role="list">
            <mat-list-item role="listitem" (click)="onAccountSelect(-1)" data-cy="account|login_form|list_item|add_account">
              <mat-icon mat-list-icon>person_add</mat-icon>
              <h3 mat-line>Use another account</h3>
              <mat-divider></mat-divider>
              <mat-progress-bar mode="indeterminate" *ngIf="selectedAccountIndex === -1"></mat-progress-bar>
            </mat-list-item>
            <mat-list-item role="listitem" (click)="onRemoveAccount()" data-cy="account|login_form|list_item|remove_account">
              <mat-icon mat-list-icon>remove_circle</mat-icon>
              <h3 mat-line>Remove an account</h3>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </div>
      <!-- Account Management -->
      <div class="manage-accounts" *ngIf="state === 'manage_accounts'">
        <div fxLayout="row" class="mat-card-header-with-actions">
          <button mat-icon-button mat-card-avatar type="button">
            <mat-icon (click)="goBack(formDirective)" matTooltip="Back">arrow_back</mat-icon>
          </button>
          <div fxFlex fxLayoutAlign="center center" class="title-wrapper">
            <mat-card-title>Remove account</mat-card-title>
          </div>
        </div>
        <mat-card-content [ngClass]="{'remove-users-list': users.length > 3}">
          <mat-list role="list" *ngFor="let user of users; let i = index; let last = last;">
            <mat-list-item role="listitem">
              <mat-icon mat-list-icon>person</mat-icon>
              <h3 mat-line>{{ user.name }}</h3>
              <p mat-line>{{ user.email }}</p>
              <button mat-icon-button class="delete-button" type="button" color="warn" matTooltip="Remove"
                (click)="removeUser(i)" data-cy="account|login_form|button|remove_account">
                <mat-icon>remove_circle</mat-icon>
              </button>
              <mat-divider *ngIf="!last"></mat-divider>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </div>
      <!-- Login Form -->
      <div class="login" *ngIf="state === 'login'">
        <div fxLayout="row" class="mat-card-header-with-actions">
          <button mat-icon-button mat-card-avatar type="button" *ngIf="selectedAccountIndex !== null" >
            <mat-icon (click)="goBack(formDirective)" matTooltip="Back" data-cy="account|login_form|button|go_back">arrow_back</mat-icon>
          </button>
          <div fxFlex fxLayoutAlign="center center" class="title-wrapper" *ngIf="selectedAccountIndex === -1">
            <mat-card-title>Login</mat-card-title>
          </div>
          <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="title-wrapper" *ngIf="selectedAccount">
            <mat-card-title>{{selectedAccount.name}}</mat-card-title>
            <mat-card-subtitle>{{selectedAccount.email}}</mat-card-subtitle>
          </div>
        </div>
        <mat-card-content>
          <form [formGroup]="form" (submit)="onSubmit()" #formDirective="ngForm" data-cy="account|login_form|form">
            <mat-form-field class="width-100" *ngIf="!selectedAccount">
              <input matInput placeholder="Email address" formControlName="username" cdkFocusInitial data-cy="account|login_form|input|email"/>
            </mat-form-field>
            <mat-form-field class="width-100">
              <input matInput placeholder="Password" [type]="visiblePassword ? 'text' : 'password'"
                formControlName="password" data-cy="account|login_form|input|password"/>
              <mat-icon matSuffix (click)="visiblePassword = !visiblePassword" data-cy="account|login_form|icon|visible_password">
                {{visiblePassword ? "visibility_off" : "visibility"}}</mat-icon>
            </mat-form-field>
            <div *ngIf="recaptcha.enabled">
              <mat-label>
                Enter Captcha to continue
              </mat-label>
              <re-captcha (resolved)="resolved($event)" [siteKey]="sitekey" class="recaptcha">
              </re-captcha>
            </div>
            <div class="actions-wrapper" fxLayout="row" fxLayoutAlign="space-between none">
              <button mat-button type="button" routerLink="/account/recovery" style="font-weight: normal;" data-cy="account|login_form|button|forgot_password">
                Forgot password?
              </button>
              <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid" data-cy="account|login_form|button|login">
                Login
              </button>
            </div>
          </form>
        </mat-card-content>
      </div>

      <!-- ***** opt screen ***** -->
      <div class="verification" *ngIf="state === 'mfa'" style="text-align: center;">
        <mat-icon style="font-size: 80px; height: 80px; width: 80px; color: rgb(63, 159, 255);">vpn_key</mat-icon>
        <div class="status-message" style="margin: 14px;">
          Enter the <span id="step1">verification code</span> sent to your email to proceed
        </div>
        <mat-hint class="status-warning" style="font-size: 10px; color: #ff0000; font-weight: 500;"
          *ngIf="isMultipleCodeRequest">
          Note: Multiple codes have been requested for this login. Please enter the LATEST code to proceed.
        </mat-hint>
        <form class="otp-form" [formGroup]="otpForm" (submit)="onOtpSubmit()">
          <mat-dialog-content>
            <mat-form-field class="width-50" style="text-align: center">
              <input matInput formControlName="confirmation" name="confirmation" placeholder="Verification Code"
                autocomplete="off" maxlength="6">
            </mat-form-field>
          </mat-dialog-content>
          <div class="width-100">
            <button mat-raised-button type="submit" color="primary" [disabled]="!otpForm.valid" id="step3"
              data-tooltipClass>
              SEND
            </button>
          </div>
        </form>
        <br />
        <mat-hint style="font-size: 12px;" *ngIf="!activateResendOtp">
          Not received the code? <br />Request another verification code after {{timeout}} seconds.
        </mat-hint>
        <button style="width: 50%;" mat-button color="primary" [disabled]="!activateResendOtp" (click)="onResendCode()">
          Request Verification Code
        </button>
      </div>

      <!-- ***** account locked screen ***** -->
      <div class="locked" *ngIf="state === 'account_locked'" style="text-align: center;">
        <button mat-icon-button mat-card-avatar class="locked-back_button" type="button">
          <mat-icon (click)="goBack(formDirective)" matTooltip="Back">arrow_back</mat-icon>
        </button>
        <mat-icon style="font-size: 80px; height: 80px; width: 80px; color: rgb(190, 36, 36);">no_accounts</mat-icon>
        <div class="status-message" style="margin: 14px;">
          Account Suspended
        </div>
        <mat-hint class="status-warning" style="font-size: 12px; font-weight: 500;">
          Please contact support@{{isATS ? 'at-scene' : 'icrimefighter'}}.com for more information.
        </mat-hint>
      </div>

      <!-- ***** reset password screen ***** -->
      <div class="reset" *ngIf="state === 'reset_password'" style="text-align: center;">
        <button mat-icon-button mat-card-avatar class="reset-back_button" type="button">
          <mat-icon (click)="goBack(formDirective)" matTooltip="Back">arrow_back</mat-icon>
        </button>
        <mat-icon style="font-size: 80px; height: 80px; width: 80px; color: rgb(190, 36, 36);">privacy_tip</mat-icon>
        <div class="status-message" style="margin: 14px;">
          Change Your Compromised Password
        </div>
        <mat-label>
          <mat-icon>
            password
          </mat-icon>
          You are using a password which has been found in a hacked passwords list.
          Please change the password using the reset link which has been sent to your email.
        </mat-label>
      </div>
    </div>
    <div *ngIf="loading" align="center">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card>
  <div align="center" class="download-links-wrapper" [ngStyle]="{'min-height': server_down ? '8vh' : '12vh'}">
    <a [href]="appstoreUrl" target="_blank" *ngIf="appstoreUrl" data-cy="account|login_form|link|app_store">
      <img width="130px" src="../../../assets/images/app-store.png" alt="Download from App Store">
    </a>
    <a [href]="playstoreUrl" target="_blank" *ngIf="playstoreUrl && !isATS" data-cy="account|login_form|link|play_store">
      <img width="130px" src="../../../assets/images/google-play.png" alt="Download from Play Store">
    </a>
  </div>
  <div class="app-footer" align="center">
    <div class="app-version">
      <span style="margin-bottom: 10px;">
        Join us to discuss your feedback, comments and ideas at our weekly webinar. <br />Sign up
        <span (click)="openWebinarLink()" class="link">here.</span>
      </span>
      <span style="margin-bottom: 10px;">
        <a [routerLink]="['/legal/privacypolicy']">
          Privacy Policy
        </a>
        <a [routerLink]="['/legal/licenseagreement']" style="margin-left: 5px;">
          License Agreement
        </a>
      </span>
      <span>© {{isATS ? '' : 'iCrimeFighter - '}}At-Scene LLC {{ now.getFullYear() }}. All Rights Reserved. Cloud
        Version:
        {{ version }}.</span>
    </div>
  </div>
</div>
