<div mat-dialog-title>
  <span *ngIf="!groupName">
    <span *ngIf="filterChoice === 'users'">Select users who should see the case</span>
    <span *ngIf="filterChoice === 'groups'">Select groups who should see the case</span>
  </span>
  <span *ngIf="groupName">Manage users in {{groupName}} group</span>
  <span class="case-selection" [ngPlural]="selection.selected.length"
    *ngIf="selection.selected.length > 0 && !groupName && filterChoice === 'users'">
    <ng-template ngPluralCase="=1">{{selection.selected.length}} user selected</ng-template>
    <ng-template ngPluralCase="other">{{selection.selected.length}} users selected</ng-template>
  </span>
  <span class="case-selection" [ngPlural]="selection.selected.length"
    *ngIf="selection.selected.length > 0 && !groupName && filterChoice === 'groups'">
    <ng-template ngPluralCase="=1">{{selection.selected.length}} group selected</ng-template>
    <ng-template ngPluralCase="other">{{selection.selected.length}} groups selected</ng-template>
  </span>
  <span class="case-selection" [ngPlural]="selection.selected.length"
    *ngIf="selection.selected.length > 0 && groupName">
    <ng-template ngPluralCase="=1">{{selection.selected.length}} user in this group</ng-template>
    <ng-template ngPluralCase="other">{{selection.selected.length}} users in this group</ng-template>
  </span>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-card class="card-list" *ngIf="!isLoading">
  <!-- <mat-button-toggle-group (change)="onFilterChange($event)" *ngIf="!groupName && hasGroups">
    <mat-button-toggle value="users" [checked]="filterChoice === 'users'">
      Users
    </mat-button-toggle>
    <mat-button-toggle value="groups" [checked]="filterChoice === 'groups'">
      Groups
    </mat-button-toggle>
  </mat-button-toggle-group> -->
  <mat-card-content>
    <mat-form-field floatPlaceholder="never" class="width-100">
      <input matInput #filter (keyup)="applyFilter($event.target.value)" placeholder="Search" data-cy="cases|restrict_dialog|input|search">
    </mat-form-field>

    <mat-table #table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="select">
        <mat-header-cell class="select" *matHeaderCellDef>
          <mat-checkbox #ref (change)="onSelect($event)">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell class="select" *matCellDef="let row; let i=index;">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null; onSelectRow();" [checked]="selection.isSelected(row)"
            [disabled]="(groupName && row.group_limit_reached && !selection.isSelected(row)) || (filterChoice === 'groups' && row.status === Status.ARCHIVED) ||
            (filterChoice === 'users' && isGroupUser(row.id))" [attr.data-cy]="'cases|restrict_dialog|checkbox|' + i">
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="first_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>First Name</mat-header-cell>
        <mat-cell *matCellDef="let user">
          {{user.first_name}}
          <mat-icon *ngIf="groupName && user.group_limit_reached" matTooltip="Max group limit reached for this user"
            style="font-size: 15px; position: relative; top: 4px; left: 4px;">
            info_outline
          </mat-icon>
          <mat-icon *ngIf="filterChoice === 'users' && isGroupUser(user.id)" matTooltip="Group User"
            style="font-size: 15px; position: relative; top: 4px; left: 4px;">
            info_outline
          </mat-icon>
          <mat-icon *ngIf="user.is_super_admin" matTooltip="Super Admin"
            style="font-size: 15px; position: relative; top: 4px; left: 4px;">
            admin_panel_settings
          </mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="last_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.last_name}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="email_address">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.email_address}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="mobile_number">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile Number</mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.mobile_number}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
        <mat-cell *matCellDef="let user">
          <div class="user-role">
            <span>{{user.role.desc}}</span>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let group">
          {{group.name}}
          <mat-icon *ngIf="filterChoice === 'groups' && group.status === Status.ARCHIVED" matTooltip="Archived Group"
            style="font-size: 15px; position: relative; top: 4px; left: 4px;">
            info_outline
          </mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="desc">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let group">{{group.desc}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="users">
        <mat-header-cell *matHeaderCellDef mat-sort-header>No of Users</mat-header-cell>
        <mat-cell *matCellDef="let group">{{group.users?.length}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #paginator [pageSize]="paginatorConfig.defaultPageSize"
      [pageSizeOptions]="paginatorConfig.pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
  </mat-card-content>
</mat-card>
<mat-dialog-actions align="end" *ngIf="!isLoading">
  <button mat-button [mat-dialog-close]="false">CANCEL</button>
  <button mat-button color="primary" (click)="onUnlockCase()" *ngIf="is_restricted" data-cy="cases|restrict_dialog|input|unlock_case">
    UNLOCK CASE
  </button>
  <button mat-button color="primary" (click)="onSave()" [disabled]="selection.selected.length > 0 ? false : true" data-cy="cases|restrict_dialog|button|save">
    SAVE
  </button>
</mat-dialog-actions>
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
