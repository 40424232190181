import { getTitleCase } from 'app/app.utils';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { logToSentry, Status, ErrorTags, errorMsgConfig, successMsgConfig } from './../../../app.utils';
import { throwError } from 'rxjs';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CaseService } from 'app/shared/api.services/case.service';

@Component({
  selector: 'app-downloads-dialog',
  templateUrl: './downloads-dialog.component.html',
  styleUrls: ['./downloads-dialog.component.scss']
})
export class DownloadsDialogComponent implements OnInit, OnDestroy {
  dataSource;
  displayedColumns = ['name', 'files', 'created_at', 'status', 'download', 'delete'];
  paginator: MatPaginator;
  private sort: MatSort;
  defaultSortColumn = 'created_at';
  isLoading = true;
  timeOut;
  downloads;
  readonly Status = Status;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  constructor(private caseService: CaseService, public snackBar: MatSnackBar, private toastr: ToastrService) { }

  ngOnInit() {
    this.getAllDownloads();
  }

  private getAllDownloads() {
    setTimeout(() => {
      this.caseService.getAllLargeDownloads().subscribe((res: any) => {
        this.downloads = res.data;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'name':
              return item['case']['name'];
            case 'status':
              const status = item['status'] === Status.PROCESSING ? 'Processing' : 'Active'
              return status.toLowerCase();
            case 'created_at':
              return item['created_at'];
            default: {
              return item['created_at'];
            }
          }
        }
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const searchStr = (data.case.name).toLowerCase();
          return searchStr.indexOf(filter) !== -1;
        };
        this.isLoading = false;
        // if any download is in processing mode, auto refresh the downloads list in every 15 secs
        if (res.data.length && res.data.some(d => d.status === Status.PROCESSING)) {
          this.timeOut = setTimeout(() => {
            this.getAllDownloads();
          }, 15000);
        }
      }, err => {
        this.snackBar.open(`${getTitleCase('Something went wrong while getting the downloads!')}`, null, {
          duration: 2000,
        });
        logToSentry(err, ErrorTags.DownloadError);
        return throwError(err);
      });
    });
  }

  setDataSourceAttributes() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onDownload(token) {
    this.snackBar.open('Starting Download...', null, {});
    this.caseService.downloadZip(token).subscribe(res => {
      this.snackBar.open('Downloading...', null, {
        duration: 3000,
      });
    }, async (err: HttpErrorResponse) => {
      this.snackBar.open(`${getTitleCase('Something went wrong while downloading!')}`, null, {
        duration: 2000,
      });
      logToSentry(err, ErrorTags.DownloadError);
      const err_msg = err.error ? err.error.error.message :
        err.message ? err.message : err;
      await this.logDownloadError(token, err_msg);
      return throwError(err);
    });

    setTimeout(() => {
      this.onRefresh();
    }, 1000);

  }

  onDelete(token) {
    this.snackBar.open('Deleting...', null, {});
    this.caseService.deleteDownloads(token).subscribe(res => {
      this.snackBar.open(`${getTitleCase('Download link deleted sucessfully')}`, null, {
        duration: 3000,
      });
      // clear async api call
      if (this.downloads && this.downloads.some(d => d.status !== Status.PROCESSING)) {
        if (this.timeOut) {
          clearTimeout(this.timeOut);
        }
      } else if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
    }, err => {
      this.snackBar.open(`${getTitleCase('Something went wrong while downloading!')}`, null, {
        duration: 2000,
      });
      return throwError(err);
    });

    setTimeout(() => {
      this.onRefresh();
    }, 1000);
  }

  onRefresh() {
    this.isLoading = true;
    this.getAllDownloads();
  }

  private logDownloadError(token, error_message) {
    const data = { download_token: token, message: error_message };
    this.caseService.logDownloadError(data).subscribe(() => { }, err => {
      return throwError(err);
    });
  }

  ngOnDestroy() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }
}
