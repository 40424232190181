import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatSeconds'
})
export class FormatSecondsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // value = Number(value);
    // const pad = function (input) { return input < 10 ? '0' + input : input; };
    // return [
    //   pad(Math.floor(value / 3600)),
    //   pad(Math.floor(value % 3600 / 60)),
    //   pad(Math.floor(value % 60)),
    // ].join(':');
    return moment.duration(value, 'seconds').humanize();
  }

}
