<div class="container">
  <form [formGroup]="form" (submit)="onSubmit()">
    <mat-card>
      <mat-card-content>
        <div align="center">
          <div class="app-logo-icon"></div>
          <h4>Change Password</h4>
        </div>
        <div *ngIf="!loading && !resetError && !resetSuccess && user">
          <div align="center">
            <mat-label class="mat-caption">
              {{ user.first_name }},
              {{'please select a strong password (min 20 characters)' | titlecase}}
            </mat-label>
          </div>
          <br />
          <mat-form-field class="full">
            <input matInput #password placeholder="Enter your password" [type]="visiblePassword ? 'text' : 'password'"
              formControlName="password" data-cy="account|reset|input|password">
            <mat-icon matSuffix (click)="visiblePassword=!visiblePassword">
              {{visiblePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>

          <span *ngIf="password.value && form.hasError('whiteSpace')">
            <!-- label with password whitespace hint -->
            <mat-label *ngIf="password.value && form.hasError('whiteSpace')" style="color: red;">
              <mat-icon>close</mat-icon>
              {{'password should not be whitespace characters' | titlecase}}
            </mat-label>
          </span>

          <span *ngIf="!password.value || !form.hasError('whiteSpace')">
            <!-- label with password character length hint -->
            <mat-label [ngStyle]="{'color': !password.value || form.hasError('smallPwd') ? 'red' : 'green'}">
              <mat-icon *ngIf="!password.value || form.hasError('smallPwd')">
                close</mat-icon>
              <mat-icon *ngIf="password.value && !form.hasError('smallPwd')">
                check</mat-icon>
              {{'password must be at least 20 characters long' | titlecase}}
            </mat-label>

            <!-- lebel with password containing name, email hint -->
            <mat-label [ngStyle]="{'color': !password.value || form.hasError('nameEmailMatch') ? 'red' : 'green'}">
              <mat-icon *ngIf="!password.value || form.hasError('nameEmailMatch')">
                close
              </mat-icon>
              <mat-icon *ngIf="password.value && !form.hasError('nameEmailMatch')">
                check
              </mat-icon>
              {{'password should not include your firstname, lastname or email address' | titlecase}}
            </mat-label>

            <!-- lebel with password containing copyright terms hint -->
            <mat-label [ngStyle]="{'color': !password.value || form.hasError('serviceTermUsed') ? 'red' : 'green'}">
              <mat-icon *ngIf="!password.value || form.hasError('serviceTermUsed')">
                close
              </mat-icon>
              <mat-icon *ngIf="password.value && !form.hasError('serviceTermUsed')">
                check
              </mat-icon>
              {{'password should not include service based terms like:' | titlecase}} 'icrimefighter', 'icrime', 'icf',
              'at-scene' etc
            </mat-label>

            <!-- lebel with password containing repetitive characters hint -->
            <mat-label [ngStyle]="{'color': !password.value || form.hasError('repetitiveChar') ? 'red' : 'green'}">
              <mat-icon *ngIf="!password.value || form.hasError('repetitiveChar')">
                close
              </mat-icon>
              <mat-icon *ngIf="password.value && !form.hasError('repetitiveChar')">
                check
              </mat-icon>
              {{'password should not include repetitive character pattern like:' | titlecase}} 'aaaaa', 'abccccc' etc
            </mat-label>
          </span>
          <div align="end">
            <ng2-password-strength-bar [passwordToCheck]="pwd">
            </ng2-password-strength-bar>
          </div>
          <br />
          <mat-form-field class="full">
            <input matInput #cnf_password placeholder="Re-type password" [type]="visibleConfirmation ? 'text' : 'password'"
              formControlName="password_confirmation" [errorStateMatcher]="matcher" data-cy="account|reset|input|retype_password" />
            <mat-icon matSuffix (click)="visibleConfirmation=!visibleConfirmation">
              {{visibleConfirmation ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
          <!-- confirmation password not matching with new password -->
          <mat-label style="color: red;" *ngIf="cnf_password.value !== '' && form.hasError('mismatch')">
            <mat-icon>
              close
            </mat-icon>
            {{'not matching' | titlecase}}
          </mat-label>
          <div class="actions-wrapper" fxLayoutAlign="end">
            <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid" data-cy="account|reset|button|save">SAVE</button>
          </div>
        </div>
        <div *ngIf="loading" align="center">
          <br />
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!loading && resetError" align="center">
          <mat-label class="mat-caption">
            Oops, looks like the link is expired or invalid.
          </mat-label>
          <div class="actions-wrapper" fxLayout="row" fxLayoutAlign="space-between none">
            <button mat-button type="button" style="font-weight: normal;" routerLink="/login">Login</button>
            <button mat-raised-button type="button" color="primary" routerLink="/account/recovery">REQUEST
              AGAIN</button>
          </div>
        </div>
        <div *ngIf="!loading && resetSuccess" align="center">
          <mat-label class="mat-caption" style="display: block;">
            Success! you can now login to your account using the new credentials.
          </mat-label>
          <div class="actions-wrapper" align="center">
            <button mat-raised-button type="button" color="primary" routerLink="/login">LOGIN</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>

  <div align="center" class="download-links-wrapper">
    <a [href]="appstoreUrl" target="_blank" *ngIf="appstoreUrl">
      <img width="130px" src="../../../assets/images/app-store.png" alt="Download from App Store">
    </a>
    <a [href]="playstoreUrl" target="_blank" *ngIf="playstoreUrl">
      <img width="130px" src="../../../assets/images/google-play.png" alt="Download from Play Store">
    </a>
  </div>

  <div class="app-footer" align="center">
    <div class="app-version">
      <span style="margin-bottom: 10px;">
        <a [routerLink]="['/legal/privacypolicy']">
          Privacy Policy
        </a>
      </span>
      <span>© {{isATS ? '' : 'iCrimeFighter - '}}At-Scene LLC {{ now.getFullYear() }}. All Rights Reserved. Cloud
        Version:
        {{ version }}.</span>
    </div>
  </div>
</div>
