import { HttpService } from './../http.service';
import { ServerConstants } from 'app/shared/constants';
import * as i0 from "@angular/core";
import * as i1 from "../http.service";
export class EmailService {
    constructor(http) {
        this.http = http;
        this.baseUrl = ServerConstants.Email;
    }
    postData(data) {
        return this.http.post(`${this.baseUrl}/resend`, data);
    }
}
EmailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmailService_Factory() { return new EmailService(i0.ɵɵinject(i1.HttpService)); }, token: EmailService, providedIn: "root" });
