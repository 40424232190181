import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {

  transform(value: any, decimel_places = 2, args?: any): any {
    if (value === 0 || isNaN(parseFloat(value)) || !isFinite(value)) { return '-'; }
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'],
      number = Math.floor(Math.log(value) / Math.log(1024));
    return (value / Math.pow(1024, Math.floor(number))).toFixed(decimel_places) + ' ' + units[number];
  }

}
