import { SessionStorage } from 'ngx-webstorage';
import { ErrorTags, getDecodedFileUploadName, logToSentry } from 'app/app.utils';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Upload, FileUploadQueue, invalidUpload } from 'app/models/upload';
import { WorkerService } from 'app/worker.service';
import { WORKER_TOPIC, UploadStatus } from '../../../../worker/app-workers/shared/worker.constants';
import { WorkerMessage } from '../../../../worker/app-workers/shared/worker-message.model';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { ServerConstants } from '../constants';
import { FileService } from './file.service';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private token;
  private uploadStore = [];
  private q: BehaviorSubject<Upload[]> = new BehaviorSubject<Upload[]>(this.uploadStore);
  private e: Subject<Upload> = new Subject<Upload>();
  public readonly uploads: Observable<Upload[]> = this.q.asObservable();
  public readonly events: Observable<Upload> = this.e.asObservable();
  private baseUrl = ServerConstants.Uploads;
  public folders: any[] = [];
  public totalUploads = 0;
  public finishedUploads: any[] = [];
  public finishedExtUplaods: any[] = [];
  public invalidUploads: invalidUpload[] = [];
  public targetFiles: FileUploadQueue[] = [];
  private lastUpload: Upload;
  @SessionStorage('uploaderCancel', false) uploaderCancel;

  constructor(
    private authService: AuthService,
    private fileService: FileService,
    private workerService: WorkerService,
    private httpService: HttpService) {

    this.workerService.workerUpdate$.subscribe((message: WorkerMessage) => {
      if (message.topic === WORKER_TOPIC.upload && message.data.state) {
        const uploadIndex = this.uploadStore.findIndex(u => u.id === message.data.id);
        if (uploadIndex !== -1) {
          this.uploadStore[uploadIndex].state = message.data.state;
          if (message.data.state === UploadStatus.QUEUED) {
            this.uploadStore[uploadIndex].key = message.data.key;
            this.uploadStore[uploadIndex].file_id = message.data.file_id;
          } else if (message.data.state === UploadStatus.UPLOADING) {
            this.uploadStore[uploadIndex].progress = message.data.progress;
          } else if (message.data.state === UploadStatus.PROCESSING) {
            if (message.data.action && message.data.action === 'init-ext-upload') {
              this.token = message.data.token;
              this.checkExtUploadStatus(this.uploadStore[uploadIndex], message.data.token);
            } else {
              this.checkStatus(this.uploadStore[uploadIndex]);
            }
          } else if (message.data.state === UploadStatus.ERRORED) {
            this.handleErroredUpload(this.uploadStore[uploadIndex], message);
          }
        }
        this.q.next(this.uploadStore);
      } else if (message.topic === WORKER_TOPIC.pending_uploads) {
        const pendingUploads = message.data.filter(u => u.file && u.data);
        // uploads which failed due to network error and cannot be resumed
        const erroredUploads = message.data.filter(u => !(u.file && u.data));

        pendingUploads.forEach((u) => {
          if (u.file && u.data) {
            const uploadObj = {
              id: u.file.lastModified,
              file_id: u.data.file_id,
              state: UploadStatus.PENDING,
              ...u,
            }
            this.uploadStore.push(uploadObj); // store all pending uploads into the upload storage
          }
        });

        if (this.uploaderCancel === true) { // remove pending uploads automatically in case the user cancels the ongoing upload
          this.uploadStore.filter(u => u.state === UploadStatus.PENDING).forEach(u => {
            this.delete(u);
          });
          this.uploaderCancel = false;
        }

        if (erroredUploads.length > 0) {
          this.erroredUploadDelete(); // clear the storage if it is filled with errored uploads
        }
        this.q.next(this.uploadStore);
      }
    });
  }

  init() {
    const storageKey = `uploads_${this.authService.hash}`;
    this.workerService.exec(new WorkerMessage(WORKER_TOPIC.upload_processor_init, {
      awsRegion: environment.aws_region,
      storageKey: storageKey,
    }));
  }

  initExternalUpload() {
    this.workerService.exec(new WorkerMessage(WORKER_TOPIC.upload_processor_init, {
      awsRegion: environment.aws_region,
    }));
  }

  create(upload: Upload) {
    upload = {
      id: upload.id || uuidv4(), // id must be unique even if lastmodified is same for multiple files
      state: UploadStatus.QUEUED,
      ...upload,
    }
    const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, {
      ...upload,
      action: 'init',
      api_url: environment.api_url,
      token: this.authService.token,
      product_id: environment.product_id
    });
    this.workerService.exec(workerMessage);

    const uploadIndex = this.uploadStore.findIndex(u => u.id === upload.id);
    if (uploadIndex === -1) {
      this.uploadStore.push(upload);
    } else {
      this.uploadStore[uploadIndex] = upload;
    }
    this.q.next(this.uploadStore);
  }

  createExternalUpload(upload, token) {
    upload = {
      id: uuidv4(), // id must be unique even if lastmodified is same for multiple files
      state: UploadStatus.QUEUED,
      ...upload,
    }
    const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, {
      ...upload,
      action: 'init-ext-upload',
      api_url: environment.api_url,
      token: token,
      product_id: environment.product_id
    });
    this.workerService.exec(workerMessage);

    const uploadIndex = this.uploadStore.findIndex(u => u.id === upload.id);
    if (uploadIndex === -1) {
      this.uploadStore.push(upload);
    } else {
      this.uploadStore[uploadIndex] = upload;
    }
    this.q.next(this.uploadStore);
  }

  pause(upload: Upload) {
    const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, {
      action: 'pause',
      key: upload.key
    });
    this.workerService.exec(workerMessage);
  }

  resume(upload: Upload) {
    const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, {
      action: 'resume',
      key: upload.key
    });
    this.workerService.exec(workerMessage);
  }

  get ongoing() {
    return this.uploadStore.filter((u) => u.state === UploadStatus.UPLOADING);
  }

  private saveAndNotify() {
    this.q.next(this.uploadStore);
  }

  handleErroredUpload(upload: Upload, message: any) {
    // update the upload bar with the error msg
    const err = message.data.errorMsg;
    upload = {
      state: UploadStatus.ERRORED,
      errorMsg: err.error ? err.error.error.message :
        err.message ? err.message : err,
      errorTitle: message.data.errorTitle,
      ...upload
    };
    const uploadIndex = this.uploadStore.findIndex(u => u.id === upload.id);
    if (uploadIndex !== -1) {
      this.uploadStore[uploadIndex] = upload;
    }
    this.q.next(this.uploadStore);

    logToSentry(`${err}:caseId-${upload.case_id}fileId-${upload.file_id || 'none'}file-${getDecodedFileUploadName(upload.file.name)}size-${upload.file.size}type-${upload.type}`
      , ErrorTags.UploadError);
  }

  /*
    push an entry to the finishedUploads for the upload summary
    delete the upload from the upload bar
    emmit value for case refresh, when the last upload is finished
  */
  private async checkStatus(upload: Upload) {
    if (upload.case_id)
      this.finishedUploads.push({ case_id: upload.case_id, case_name: upload.case_name });
    this.delete(upload);

    this.lastUpload = { ...upload, state: UploadStatus.COMPLETED };
    if (this.uploadStore.length === 0)
      this.e.next({ ...upload, state: UploadStatus.COMPLETED });
  }

  notifyCase() {
    this.e.next(this.lastUpload);
  }

  /*
    for external upload,
    only the uploaded count i.e finishedUploads array length is required
  */
  private async checkExtUploadStatus(upload: Upload, token) {
    // this.finishedUploads.push(upload);
    this.finishedExtUplaods.push(upload)
    this.delete(upload);
    this.e.next({ ...upload, state: UploadStatus.COMPLETED });
  }

  delete(upload: Upload) {
    const ui = this.uploadStore.findIndex(u => u.id === upload.id);
    if (ui > -1) {
      const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, {
        action: 'remove',
        key: upload.key
      });
      this.workerService.exec(workerMessage);
      this.uploadStore.splice(ui, 1);
      this.saveAndNotify();
    }
  }

  erroredUploadDelete() {
    const workerMessage = new WorkerMessage(WORKER_TOPIC.upload, {
      action: 'remove',
    });
    this.workerService.exec(workerMessage);
    this.saveAndNotify();
  }

  clear() {
    if (this.uploadStore.length > 0) {
      this.uploadStore = [];
      this.saveAndNotify();
    }
  }

  generateVerificationToken(token) {
    const params = { access_token: token };
    return this.httpService.post(`${this.baseUrl}/external/acceptinvite`, params);
  }
}
