var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ToastrService } from 'ngx-toastr';
import { ErrorTags } from './../../app.utils';
import { OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { appVersion } from 'app/app.version';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ServerConstants } from 'app/shared/constants';
import { request } from '../../../../worker/utils';
import { take } from 'rxjs/operators';
import { CaseService } from 'app/shared/api.services/case.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';
import { MatSnackBar } from '@angular/material';
import { ApiError, logToSentry, getTitleCase } from 'app/app.utils';
import { throwError } from 'rxjs';
export class DownloadComponent {
    constructor(router, route, deviceService, caseService, authService, formBuilder, snackBar, toastr) {
        this.router = router;
        this.route = route;
        this.deviceService = deviceService;
        this.caseService = caseService;
        this.authService = authService;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.toastr = toastr;
        this.now = new Date();
        this.version = appVersion;
        this.appstoreUrl = environment.appstore_url;
        this.playstoreUrl = environment.playstore_url;
        this.download = {};
        this.activateResendOtp = false;
        this.timeout = 120;
        this.isMultipleCodeRequest = false;
        this.getBrowserData();
    }
    ngOnInit() {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.token = params['token'];
            if (!this.token) {
                return this.router.navigateByUrl('/');
            }
        });
        this.download.state = 'loading';
        this.download.message = `Connecting...`;
        this.getVerificationToken(this.token);
    }
    getVerificationToken(token) {
        this.caseService.generateVerificationToken(token).subscribe((res) => {
            this.otpForm = this.formBuilder.group({
                confirmation: ['', [Validators.required, this.confirmationValidator()]]
            });
            this.download.state = 'verification';
            this.isMultipleCodeRequest = res.data.code_requests > 1;
            setTimeout(() => {
                this.activateResendOtp = true;
            }, 120000);
            this.timeCount();
        }, (err) => {
            this.router.navigateByUrl(`/download?token=${this.token}`);
            this.download.state = 'errored';
            if (err.error.error.name === ApiError.InvalidToken) {
                this.download.message = 'Invalid download! Please contact the person who shared this link with you.';
            }
            else if (err.error.error.name === ApiError.DownloadNotFound) {
                this.download.message = 'Sorry, the requested download is not found! Please contact the person who shared this link with you.';
            }
            else if (err.error.error.name === ApiError.ResendNotPermitted) { // request made before resend time limit
                this.router.navigateByUrl(`/download?token=${this.token}`);
                this.otpForm = this.formBuilder.group({
                    confirmation: ['', [Validators.required, this.confirmationValidator()]]
                });
                this.download.state = 'verification';
                this.isMultipleCodeRequest = this.isMultipleCodeRequest;
                setTimeout(() => {
                    this.activateResendOtp = true;
                }, 120000);
                this.timeCount();
            }
            else {
                this.download.message = 'Something went wrong! Please contact the person who shared this link with you.';
            }
            logToSentry(err, ErrorTags.DownloadError);
            const err_msg = err.error ? err.error.error.message :
                err.message ? err.message : err;
            this.logExternalDownloadError(this.token, err_msg);
        });
    }
    confirmationValidator() {
        return (control) => {
            if (control.value.length === 6) {
                return null;
            }
            else {
                return { 'confirmation_error': true };
            }
        };
    }
    timeCount() {
        if (this.timeout !== 0) {
            this.timeout--;
            setTimeout(() => {
                this.timeCount();
            }, 1000);
        }
    }
    getBrowserData() {
        const deviceInfo = this.deviceService.getDeviceInfo();
        this.browserName = deviceInfo.browser;
    }
    onSubmit() {
        this.download.state = 'loading';
        const confirmationCode = this.otpForm.getRawValue().confirmation;
        this.authService.verifyConfirmationCode(confirmationCode, this.token).subscribe(res => {
            // this.download.state = 'click';
            this.downloadFile();
        }, (err) => {
            if (err.error.error.name === ApiError.ValidationLimitReached) {
                this.router.navigateByUrl(`/download?token=${this.token}`);
                this.download.state = 'ValidationError';
            }
            else {
                this.router.navigateByUrl(`/download?token=${this.token}`);
                this.download.state = 'verification';
                this.snackBar.open(`${getTitleCase('Code is invalid or inactive!')}`, null, {
                    duration: 3000
                });
            }
            logToSentry(err, ErrorTags.DownloadError);
            const err_msg = err.error ? err.error.error.message :
                err.message ? err.message : err;
            this.logExternalDownloadError(this.token, err_msg);
            console.log(err);
        });
    }
    onResendCode() {
        this.download.state = 'loading';
        this.authService.resendVerificationCode(this.token, 'download').subscribe((res) => {
            this.download.state = 'verification';
            this.timeout = 120;
            this.activateResendOtp = false;
            this.isMultipleCodeRequest = res.data.code_requests > 1;
            setTimeout(() => {
                this.activateResendOtp = true;
            }, 120000);
            this.timeCount();
        }, err => {
            this.router.navigateByUrl(`/download?token=${this.token}`);
            this.download.state = 'errored';
            if (err.error.error.name === ApiError.ResendNotPermitted) { // request made before resend time limit
                this.router.navigateByUrl(`/download?token=${this.token}`);
                this.otpForm = this.formBuilder.group({
                    confirmation: ['', [Validators.required, this.confirmationValidator()]]
                });
                this.download.state = 'verification';
                this.isMultipleCodeRequest = this.isMultipleCodeRequest;
                setTimeout(() => {
                    this.activateResendOtp = true;
                }, 120000);
                this.timeCount();
            }
            else {
                this.download.message = 'Something went wrong! Please contact the person who shared this link with you.';
            }
            logToSentry(err, ErrorTags.DownloadError);
            const err_msg = err.error ? err.error.error.message :
                err.message ? err.message : err;
            this.logExternalDownloadError(this.token, err_msg);
            console.log(err);
        });
    }
    downloadFile() {
        return __awaiter(this, void 0, void 0, function* () {
            this.caseService.downloadWithToken(this.token).subscribe((res) => {
                if (res.status) {
                    if (res.data.download_url) {
                        this.downloadWithUrl(res.data.download_url);
                    }
                    else {
                        this.download.state = 'loading';
                        this.download.message = 'Preparing your download, please wait. The download will start automatically.';
                        this.downloadByZip();
                    }
                }
            }, (err) => __awaiter(this, void 0, void 0, function* () {
                this.router.navigateByUrl(`/download?token=${this.token}`);
                this.download.status = 'failed';
                this.download.state = 'errored';
                // this.download.message = 'File already downloaded or not found';
                switch (err.error.error.name) {
                    case ApiError.InvalidToken:
                        this.download.message = 'Provided token is invalid or expired! Please contact the person who shared this link with you.';
                        break;
                    case ApiError.DownloadNotFound:
                        this.download.message = 'Requested download is not found! Please contact the person who shared this link with you.';
                        break;
                    case ApiError.FileDeleted:
                        this.download.message = 'Requested file has been deleted! Please contact the person who shared this link with you.';
                        break;
                    default:
                        this.download.message = 'File already downloaded or not found';
                }
                logToSentry(err, ErrorTags.DownloadError);
                const err_msg = err.error ? err.error.error.message :
                    err.message ? err.message : err;
                yield this.logExternalDownloadError(this.token, err_msg);
                return throwError(err);
            }));
        });
    }
    // not being used
    downloadByZip() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield request({
                    url: `${environment.api_url}${ServerConstants.Cases}/downloadzip/external`,
                    headers: {
                        authorization: this.token,
                    }
                });
                if (res.status) {
                    if (res.data.download_url) {
                        this.downloadWithUrl(res.data.download_url);
                    }
                    else {
                        this.download.state = 'loading';
                        this.download.message = 'Preparing your download, please wait. The download will start automatically.';
                        setTimeout(() => {
                            this.downloadByZip();
                        }, 2000);
                    }
                }
            }
            catch (err) {
                this.router.navigateByUrl(`/download?token=${this.token}`); // to-do handle in a better way
                this.download.status = 'failed';
                this.download.state = 'errored';
                this.download.message = 'File already downloaded or not found';
                logToSentry(err, ErrorTags.DownloadError);
                const err_msg = err.error ? err.error.error.message :
                    err.message ? err.message : err;
                yield this.logExternalDownloadError(this.token, err_msg);
                return throwError(err);
            }
        });
    }
    downloadWithUrl(url) {
        const timeoutId = setTimeout(() => {
            this.download.state = 'connecting';
            if (this.browserName === 'Chrome') {
                this.download.message = `The files are currently being downloaded to your computer. The download progress can be seen in the bottom
          left corner
          of the browser.`;
            }
            else if (this.browserName === 'Firefox') {
                this.download.message = `The files are currently being downloaded to your computer. The download progress can be seen in the top
          right corner
          of the browser.`;
            }
            else if (this.browserName === 'IE' || this.browserName === 'MS-Edge') {
                this.download.message = `The files are currently being downloaded to your computer.
        The download progress can be seen at the bottom of the browser.`;
            }
            else {
                this.download.message = `The files are currently being downloaded to your computer.
        The download progress can be seen in the download section of the browser settings.`;
            }
        }, 2000);
        // this.caseService.downloadWithLink(this.authService.token);
        // this.download.processor
        const iframe = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.setAttribute('style', 'width:0;height:0;display:none;');
        iframe.src = url;
        iframe.onload = () => {
            // failed
            clearTimeout(timeoutId);
            this.download.state = 'completed';
            this.download.status = 'failed';
            this.download.message = 'Sorry, download failed! Please try again later.';
            logToSentry(`Download failed for the url: ${url}`);
            this.logExternalDownloadError(this.token, `Download failed, url: ${url}`);
        };
    }
    logExternalDownloadError(token, message) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield request({
                    url: `${environment.api_url}${ServerConstants.Cases}/download/external/log`,
                    method: 'POST',
                    headers: {
                        authorization: token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        message: message
                    })
                });
            }
            catch (err) {
                this.router.navigateByUrl(`/download?token=${this.token}`); // to-do handle in a better way
                logToSentry(err, ErrorTags.DownloadError);
                return throwError(err);
            }
        });
    }
}
