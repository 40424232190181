var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ToastrService } from 'ngx-toastr';
import { getFileType, ApiError, removeNonASCII, logToSentry, ErrorTags, getEncodedFileUploadName, getDecodedFileUploadName, maxFileExtLength, getTitleCase } from 'app/app.utils';
import { each } from 'lodash';
import { request } from '../../../../worker/utils';
import { appVersion } from 'app/app.version';
import { ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatBottomSheet, MatSnackBar } from '@angular/material';
import { UploadService } from 'app/shared/api.services/upload.service';
import { AuthService } from 'app/auth/auth.service';
import { UploaderComponent } from 'app/shared/components/uploader/uploader.component';
import { throwError } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
export class UploadComponent {
    constructor(router, formBuilder, route, uploadService, deviceService, authService, bottomSheet, dialog, snackBar, toastr) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.route = route;
        this.uploadService = uploadService;
        this.deviceService = deviceService;
        this.authService = authService;
        this.bottomSheet = bottomSheet;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.toastr = toastr;
        this.now = new Date();
        this.version = appVersion;
        this.uploads = this.uploadService.uploads;
        this.fileList = [];
        this.upload = {};
        this.isUploadEnabled = false;
        this.color = '#333333';
        this.uploaderOpen = false;
        this.activateResendOtp = false;
        this.timeout = 120;
        this.sitekey = environment.app_site_key;
        this.failedAttempts = 0;
        this.displayedColumns = ['choose-file', 'name', 'desc'];
        this.chromeDownloadLink = 'https://www.google.com/chrome/';
        this.edgeDownloadLink = 'https://www.microsoft.com/en-us/edge';
        this.firefoxDownloadLink = 'https://www.mozilla.org/en-US/firefox/new/';
        this.edgeTargetLink = '';
        this.limitExceeded = false;
        this.maxFileNumber = 100;
    }
    ngOnInit() {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.token = params['token'];
            this.return_url = `${environment.edp_url}${params['ru'] || ''}`;
            if (!this.token) {
                return this.router.navigateByUrl('/');
            }
        });
        this.uploadService.initExternalUpload();
        this.detectDevice();
    }
    ngAfterViewInit() {
        this.uploadService.uploads.subscribe(uploads => {
            if (uploads.length > 0 && this.uploaderOpen === false) {
                this.uploaderOpen = true;
                this.upload.state = 'uploading';
                setTimeout(() => this.openUploader(), 1000);
            }
        });
    }
    detectDevice() {
        if (this.deviceService.getDeviceInfo().browser === 'IE') {
            this.upload.state = 'changeDevice';
            this.erroredMessage = `Internet Explorer is not supported!`;
            this.edgeTargetLink = `microsoft-edge:${environment.deploy_url}upload?token=${this.token}`;
        }
        else {
            this.upload.state = 'connecting';
            this.getVerificationToken(this.token);
        }
    }
    getVerificationToken(token) {
        this.uploadService.generateVerificationToken(token).subscribe(res => {
            this.otpForm = this.formBuilder.group({
                confirmation: ['', [Validators.required, this.confirmationValidator()]],
            });
            this.upload.state = 'confirmation';
            setTimeout(() => {
                this.activateResendOtp = true;
            }, 120000);
            this.timeCount();
        }, err => {
            this.router.navigateByUrl(`/upload?token=${this.token}`);
            this.upload.state = 'errored';
            this.erroredMessage = 'Sorry, the link has expired! Please contact the person who shared this link with you.';
            console.log(err);
        });
    }
    confirmationValidator() {
        return (control) => {
            if (control.value.length === 6) {
                return null;
            }
            else {
                return { 'confirmation_error': true };
            }
        };
    }
    timeCount() {
        if (this.timeout !== 0) {
            this.timeout--;
            setTimeout(() => {
                this.timeCount();
            }, 1000);
        }
    }
    get recaptcha() {
        return this.otpForm.get('recaptcha');
    }
    openUploader() {
        const uploaderRef = this.bottomSheet.open(UploaderComponent, {
            hasBackdrop: false,
            closeOnNavigation: false,
            data: { isExternalUpload: true }
        });
        uploaderRef.afterOpened().subscribe(res => this.uploaderOpen = true);
        uploaderRef.afterDismissed().subscribe(res => {
            this.uploaderOpen = false;
            if (this.uploadService.finishedExtUplaods.length) // confirmation after successful upload
                this.uploadConfirmation();
            else { // back to "browse-file" screen
                this.upload.state = 'select';
                this.upload.files = this.fileList = [];
                this.uploadService.totalUploads = 0;
            }
        });
    }
    openChromeLink() {
        window.open(this.chromeDownloadLink);
    }
    openEdgeLink() {
        window.open(this.edgeDownloadLink);
    }
    openFirefoxLink() {
        window.open(this.firefoxDownloadLink);
    }
    openInEdge() {
        window.open(this.edgeTargetLink);
    }
    onFileSelect(event) {
        this.upload.state = 'selected';
        if (event.target.files.length > this.maxFileNumber) {
            this.limitExceeded = true;
        }
        else {
            this.limitExceeded = false;
            this.onFileDropped(event.target.files);
        }
    }
    onFileDropped(files) {
        this.upload.files = files;
        each(this.upload.files, (file) => {
            if (this.fileList.length < (this.maxFileNumber + 1)) {
                this.limitExceeded = false;
                this.fileList.unshift(file);
            }
            else {
                this.limitExceeded = true;
            }
        });
    }
    remove(file) {
        const index = this.fileList.indexOf(file);
        if (index >= 0) {
            this.fileList.splice(index, 1);
        }
        this.limitExceeded = this.fileList.length < (this.maxFileNumber + 1) ? false : true;
    }
    onEnableUpload(event) {
        this.isUploadEnabled = event.checked;
    }
    uploadFile() {
        each(this.fileList, (fileData) => {
            // attach uuid string with the file name
            // so evaporate can generate unique file signature
            const encodedFileName = getEncodedFileUploadName(removeNonASCII(fileData.name));
            // clone the file blob and sanitize the name
            // pass the type and lastmodified in the syntax
            let file = new File([fileData], encodedFileName, { type: fileData.type, lastModified: fileData.lastModified });
            const upload = {
                file: file,
                type: getFileType(fileData),
            };
            const ext = getDecodedFileUploadName(file.name).split('.').pop();
            if (ext.length > maxFileExtLength) {
                this.snackBar.open(`Invalid File Name!`, null, {
                    duration: 3000,
                });
            }
            else if (file.size !== 0) {
                this.uploadService.totalUploads++;
                this.uploadService.createExternalUpload(upload, this.token);
            }
            else {
                logToSentry(`uploading 0 byte file(ext upload),file-${file}`, ErrorTags.UploadError);
            }
        });
    }
    uploadConfirmation() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield request({
                    url: `${environment.api_url}uploads/external/confirm`,
                    method: 'POST',
                    headers: {
                        authorization: this.token,
                    }
                });
                if (res.status) {
                    this.upload.state = 'upload_confirmation';
                    this.confirmationNumber = res.data.confirmation_number;
                    this.finishedExtUploads = this.uploadService.finishedExtUplaods.length;
                }
            }
            catch (err) {
                this.router.navigateByUrl(`/upload?token=${this.token}`);
                this.upload.state = 'errored';
                this.erroredMessage = 'Something went wrong while confirming you upload! Please contact the person who shared this link with you.';
                return throwError(err);
            }
        });
    }
    uploadAnother() {
        this.upload = {};
    }
    resetUploadForm() {
        this.isUploadEnabled = false;
        this.fileName = '';
    }
    goToSelectState() {
        this.upload.state = 'select';
        this.upload.file = null;
        this.upload.processor = null;
        this.isUploadEnabled = false;
    }
    onSubmit() {
        this.upload.state = 'connecting';
        const confirmationCode = this.otpForm.getRawValue().confirmation;
        this.authService.verifyConfirmationCode(confirmationCode, this.token).subscribe(res => {
            this.upload.state = 'select';
        }, (err) => {
            if (err.error.error.name === ApiError.ValidationLimitReached) {
                this.router.navigateByUrl(`/upload?token=${this.token}`);
                this.upload.state = 'ValidationError';
            }
            else {
                this.upload.state = 'confirmation';
                this.otpForm = this.formBuilder.group({
                    confirmation: ['', [Validators.required, this.confirmationValidator()]],
                });
                this.router.navigateByUrl(`/upload?token=${this.token}`);
                this.snackBar.open(`${getTitleCase('Code is invalid or inactive!')}`, null, {
                    duration: 3000
                });
            }
            console.log(err);
        });
    }
    onResendCode() {
        this.upload.state = 'connecting';
        this.authService.resendVerificationCode(this.token, 'upload').subscribe(res => {
            this.upload.state = 'confirmation';
            this.timeout = 120;
            this.activateResendOtp = false;
            this.timeCount();
        }, err => {
            this.router.navigateByUrl(`/upload?token=${this.token}`);
            this.upload.state = 'errored';
            this.erroredMessage = 'Something went wrong! Please contact the person who shared this link with you.';
            console.log(err);
        });
    }
}
