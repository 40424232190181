import * as mime from 'mime';
import * as Sentry from '@sentry/browser';
import { v4 as uuidv4 } from 'uuid';
import { ErrorStateMatcher } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { environment } from 'environments/environment';

// adding any new file extension needs a db check to covert any of the existing file to be in that category
const fileExtensionsByType = {
  photo: [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.bmp',
    '.heic',
    '.heif',
    '.svg',
    '.cr2',
    '.cr3',
    '.dng',
    '.rw2',
    '.raw'
  ],
  audio: [
    '.mp3',
    '.wav',
    '.aac',
    '.m4a',
    '.dss',
    '.ds2',
    '.wma'
  ],
  video: [
    '.mp4',
    '.mov',
    '.m4v',
    '.avi',
    '.qt',
    '.wmv',
    '.webm',
    '.ogg',
    '.3gp',
    '.g64x',
    '.asf',
    '.flv',
    '.swf',
    '.mkv',
    '.ts',
    '.mpg',
    '.mpeg',
    '.vob',
    '.dav'
  ],
  note: ['.txt'],
  document: [
    '.csv',
    '.pdf',
    '.doc',
    '.xls',
    '.ppt',
    '.docx',
    '.xlsx',
    '.pptx',
    '.tif',
    '.tiff',
    '.rtf'
  ],
  misc: [
    '.zip',
    '.iso',
    '.7z'
  ]
}

export enum FileType {
  Photo = 'photo',
  Video = 'video',
  Interview = 'interview',
  Dictation = 'dictation',
  Audio = 'audio', // Audio type for files uploaded from desktop
  Note = 'note',
  Document = 'document',
}

export enum SelectionState {
  NONE = 'none',
  FEW = 'few',
  PAGE = 'page',
  ALL = 'all',
};

export enum Status {
  ACTIVE = 1,
  DELETED = 2,
  ARCHIVED = 3,
  INACTIVE = 4,
  FAILED = 6,
  MERGED = 7,
  UPLOADING = 8,
  PROCESSING = 9,
  VERIFIED = 10,
  MALICIOUS = 11
};

export enum AuthStatus {
  MFA = 'mfa',
  ACCOUNT_LOCKED = 'account_locked',
  RESET_PASSWORD = 'reset_password'
};

export enum UserPreferenceName {
  DownloadZippingMedia = 'download_zip_with_media_type_folders',
  RetainFileName = 'retain_file_name',
};

export enum UserNotificationName {
  SharedCasesNotification = 'shared_cases',
  NewCaseNotification = 'new_case',
  NewAudioNotification = 'new_audio_file',
  NewVideoNotification = 'new_video_file',
  NewDocumentNotification = 'new_doc_file',
  NewDictationNotification = 'new_dictation',
  DownloadConfirmationNotification = 'download_confirmation',
}

export enum UserPreferenceDescription {
  DownloadZippingMedia = 'Download Zip With Media Type Folders',
  RetainFileName = 'Retain File Name for Downloads',
};

export enum UserNotificationDescription {
  SharedCasesNotification = 'Shared Cases',
  NewCaseNotification = 'New Case',
  NewAudioNotification = 'New Audio File',
  NewVideoNotification = 'New Video File',
  NewDocumentNotification = 'New Document',
  NewDictationNotification = 'New Dictation',
  DownloadConfirmationNotification = 'Shared Case Downloaded',
}

export enum ApiError {
  AccountLimitReached = 'AccountLimitReached',
  ValidationLimitReached = 'ValidationLimitReached',
  StreamRunning = 'StreamRunning',
  InvalidToken = 'InvalidToken',
  DownloadNotFound = 'DownloadNotFound',
  ResendNotPermitted = 'ResendNotPermitted',
  FolderAlreadyExists = 'FolderAlreadyExists',
  FolderLimitReached = 'FolderLimitReached',
  InvalidCredentials = 'InvalidCredentials',
  LicenseExpired = 'LicenseExpired',
  FolderNotFound = 'FolderNotFound',
  CaseNotFound = 'CaseNotFound',
  FileNotFound = 'FileNotFound',
  SourceFileNotFoun = 'SourceFileNotFoun',
  NotAuthorized = 'NotAuthorized',
  ParameterError = 'ParameterError',
  FileDeleted = 'FileDeleted',
  AccountNotFound = 'AccountNotFound',
  GroupNotFound = 'GroupNotFound',
  ExpiredValidationCode = 'ExpiredValidationCode',
  InvalidValidationCode = 'InvalidValidationCode',
  PasswordRequirementNotSatisfied = 'PasswordRequirementNotSatisfied',
  InvalidEmailAddress = 'InvalidEmailAddress',
  InvalidRequestData = 'InvalidRequestData'
}

export enum SelectedTab {
  All = 'all_cases',
  SHARED = 'shared_cases',
  AGENCY = 'agency_cases',
  MY = 'my_cases',
};

export const allowedFileExtensions = Array.prototype.concat.apply(
  [], Object.keys(fileExtensionsByType).map(a => fileExtensionsByType[a])
);

function getTypeByExt(ext) {
  return Object.keys(fileExtensionsByType)
    .find((group) => fileExtensionsByType[group].includes(`.${ext.toLowerCase()}`)) as any || 'misc';
}

export function getFileType(file: File): 'photo' | 'video' | 'audio' | 'note' | 'document' | 'misc' {
  const dotpos = file.name.lastIndexOf('.');
  const ext = file.name.split('.').pop();
  const file_type = (dotpos < 0 || !ext) ? 'misc' : getTypeByExt(ext);

  if (file_type === 'unknown') {
    Sentry.setExtra('data', { mimetype: file.type, name: file.name, size: file.size, ext: mime.getExtension(file.type) });
    Sentry.captureMessage('UNKNOWN_FILE_TYPE', Sentry.Severity.Info);
  }

  return file_type;
}

export function getFileExtensionLength(fileName: string): number {
  const dotpos = fileName.lastIndexOf('.');
  const ext = fileName.split('.').pop();
  return (dotpos < 0 || !ext) ? 0 : ext.length;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export async function request(req: any) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(req.method || 'GET', req.url);
    if (req.headers) {
      Object.keys(req.headers).forEach(key => {
        xhr.setRequestHeader(key, req.headers[key]);
      });
    }
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(req.body);
  });
};

export function getFileSignature(file: File) {
  // The key tries to give a signature to a file in the absence of its path.
  // "<filename>-<mimetype>-<modifieddate>-<filesize>"
  return [
    file.name,
    file.type,
    file.lastModified,
    file.size
  ].join('-');
}

export function logToSentry(error, tag?: string) {
  if (tag) {
    Sentry.configureScope((scope) => {
      scope.setTag('error-name', tag);
    });
    Sentry.captureException(error.error || error.message || error.originalError || error);
  } else {
    if (error.indexOf('Download failed') !== -1) {
      Sentry.configureScope((scope) => {
        scope.setTag('error-name', 'download-error');
        scope.setLevel(Sentry.Severity.Error);
      });
    } else {
      Sentry.configureScope((scope) => {
        scope.setLevel(Sentry.Severity.Error);
      });
    }
    Sentry.captureMessage(error);
  }
}

export interface CaseSearchOption {
  str: string,
  from: any,
  to: any
}

export const asciiCheck = /^[ -~\t\n\r]+$/;

export const urlSafeCheck = /[^a-z0-9\-_.]/gi;

export const maxCharLength = 255;

export const maxFileExtLength = 16;

export const pwdCharLength = 20;

export function decodeName(nameString: string, returnVal: string) {
  const last_pos = nameString.lastIndexOf('$');

  if (returnVal === 'name') {
    if (last_pos < 0) {
      return nameString;
    } else {
      return nameString.substr(0, last_pos);
    }
  }

  if (returnVal === 'id') {
    if (last_pos >= 0) {
      const id_info = nameString.split('$')[(nameString.split('$')).length - 1];
      return id_info.split('.')[0];
    }
  }

  if (returnVal === 'parent_id') {
    if (last_pos >= 0) {
      const id_info = nameString.split('$')[(nameString.split('$')).length - 1];
      return id_info.split('.')[1];
    }
  }

  if (returnVal === 'files') {
    if (last_pos >= 0) {
      const id_info = nameString.split('$')[(nameString.split('$')).length - 1];
      return id_info.split('.')[2] !== 'null' ? id_info.split('.')[2] : null;
    }
  }
}

export function URLSafeName(name: string) {
  // Remove multiple spaces
  let safe_name = name.replace(/[ ]{2,}/gi, '_');

  // Remove unsafe characters from name
  safe_name = safe_name.replace(/[^a-z0-9\-_.]/gi, '_');
  return safe_name;
};

export enum ErrorTags {
  DownloadError = 'download-error',
  CaseLoadError = 'case-load-error',
  NoteError = 'note-error',
  StreamingError = 'streaming-error',
  DeleteError = 'delete-error',
  ServerStatusError = 'server-status-error',
  UploadError = 'upload-error'
};

export function isExternalRequest(url: string) { // requests that do not require session token for authorization
  const extReqUrlSet = {
    DownLoadWithToken: `${environment.api_url}cases/download?access_token=`,
  }

  let isExtReq = false;
  for (var key in extReqUrlSet) {
    if (url.indexOf(extReqUrlSet[key]) !== -1) {
      isExtReq = true;
    }
  }

  return isExtReq;
}

export function removeNonASCII(value: string): string {
  return value ? value.replace(/[^\x00-\x7F]/g, '') : '';
}

export const paginatorConfig = {
  pageSizeOptions: [25, 50, 75, 100],
  defaultPageSize: 25,
  oldPageSizeOptions: [10, 30]
};


// file name encoding/decoding for unique file signature
// keeping another copy of "decodeFileUploadName" func in the
// worker/uploader/Util.ts file
const fileEncodingString = '@';
export function getEncodedFileUploadName(name: string): string {
  return `${name}${fileEncodingString}${uuidv4()}`;
}

export function getDecodedFileUploadName(name: string): string {
  const last_pos = name.lastIndexOf(fileEncodingString);
  if (last_pos < 0) {
    return name;
  } else {
    return name.substr(0, last_pos);
  }
}

export enum FileViewerError {
  onLoadError = 'onLoadError',
  noPreview = 'noPreview'
}

export function getTitleCase(input: string): string {
  return input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

export enum invalidUploadReason {
  zeroByteFile = 'zeroByteFile',
  invalidName = 'inValidName'
}

export const errorMsgConfig = {
  closeButton: true,
  positionClass: 'toast-bottom-right',
  disableTimeOut: true,
  tapToDismiss: true
};

export const successMsgConfig = {
  closeButton: true,
  positionClass: 'toast-bottom-center',
  // disableTimeOut: true
  timeOut: 4000
};

export const weeklyWebinarLink = 'https://docs.google.com/forms/d/e/1FAIpQLSfcd8l4mKk2bWs3pkUaxGh5N39vmV4lelleXJapwMWmzCem9w/viewform?usp=sf_link';

const copyRightStringPatterns = [
  'icrime',
  'icf',
  'icrimefighter',
  'atscene',
  'at-scene',
];

const charRepetitionRegex = /(.)\1{4,}/; // detect the occurence of any character more than 4 times

export function passwordValidator(password: string, confirmed_password: string, user: any): any {
  if (password.trim() === '') {
    return { 'whiteSpace': true };
  } else if (password.length < pwdCharLength) { // password charecters length check
    return { 'smallPwd': true };
  } else if (
    (password.toLowerCase().indexOf(user.first_name.toLowerCase()) > -1
      || password.toLowerCase().indexOf(user.last_name.toLowerCase()) > -1
      || password.toLowerCase().indexOf(user.email_address.toLowerCase()) > 1)) { // password string should not contain username or email
    return { 'nameEmailMatch': true };
  } else if (copyRightStringPatterns.some(val => password.toLowerCase().indexOf(val) > -1)) { // password contails service terms
    return { 'serviceTermUsed': true };
  } else if (charRepetitionRegex.test(password)) { // repetitive characters in password
    return { 'repetitiveChar': true }
  } else if (confirmed_password.trim() === '' || (confirmed_password.trim() !== '' && password !== confirmed_password)) {
    return { 'mismatch': true };
  } else {
    return null;
  }
}
