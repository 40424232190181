var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { HttpResponse, HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { SessionStorage } from 'ngx-webstorage';
import { filter, tap } from 'rxjs/operators';
import { isExternalRequest } from 'app/app.utils';
export class CustomHttpInterceptor {
    constructor(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    intercept(request, next) {
        this.isLoading = true;
        if (!isExternalRequest(request.url)) {
            request = request.clone({
                setHeaders: {
                    Authorization: this.authService.token || ''
                }
            });
        }
        if (request.url.split('/').indexOf('streaming') !== -1) { // intercepting req body with 'withCredentials'
            request = request.clone({
                withCredentials: true
            });
        }
        return next.handle(request).pipe(filter((event) => event instanceof HttpResponseBase), tap((event) => {
            if (event instanceof HttpResponse) {
                if (event.body.refresh_access_token) {
                    this.authService.setToken(event.body.refresh_access_token);
                }
                this.isLoading = false;
                // do stuff with response if you want
            }
        }, (err) => {
            if (err instanceof HttpErrorResponse) {
                this.isLoading = false;
                if (err.status === 401 && !isExternalRequest(request.url)) {
                    this.authService.logout();
                    this.router.navigateByUrl('/login');
                    // return err;
                }
            }
        }));
    }
}
__decorate([
    SessionStorage('isLoading', false),
    __metadata("design:type", Object)
], CustomHttpInterceptor.prototype, "isLoading", void 0);
