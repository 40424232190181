import { User } from './../../models/user';
import { AuthService } from 'app/auth/auth.service';
import { errorMsgConfig, pwdCharLength, successMsgConfig, AuthStatus, passwordValidator } from './../../app.utils';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import * as md5 from 'md5';
import { UserService } from 'app/shared/api.services/user.service';
import { throwError } from 'rxjs';
import { SessionStorage } from 'ngx-webstorage';
import { catchError } from 'rxjs/operators';
import { MyErrorStateMatcher, getTitleCase, ApiError } from 'app/app.utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @SessionStorage('isLoading', false) loading;
  public passwordForm: FormGroup;
  public cp_visible = false;
  public np_visible = false;
  public ncp_visible = false;
  user: User;
  pwd = null;
  matcher = new MyErrorStateMatcher();

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authservice: AuthService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      current_password: [null, [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', []],
    });
    this.user = this.authservice.user;

    this.password.valueChanges.subscribe(val => this.pwd = val);

    this.passwordForm.setValidators([Validators.required, (g: FormGroup) => {
      const pwd_value = g.get('password').value;
      const cnf_value = g.get('password_confirmation').value;

      return passwordValidator(pwd_value, cnf_value, this.user);
    }]);
  }

  get passwordConfirmation(): AbstractControl {
    return this.passwordForm.get('password_confirmation');
  }

  get password(): AbstractControl {
    return this.passwordForm.get('password');
  }

  onSubmit() {
    const formData = this.passwordForm.getRawValue();

    this.userService.changePassword({
      current_password: formData.current_password,
      new_password: formData.password
    }).pipe(catchError((err) => {
      this.dialogRef.close();
      if (err.status === 401) {
        this.snackBar.open(`${getTitleCase(`Your current password is incorrect, please login to continue!`)}`, 'CLOSE', {
          panelClass: 'snackbar-error',
          horizontalPosition: 'end',
        }).onAction().subscribe(() => {
          this.snackBar.dismiss();
        });
      } else if (err.error.error.name === ApiError.PasswordRequirementNotSatisfied) {
        this.snackBar.open(`${getTitleCase(`Password Requirements not Satisfied. Please try again.`)}`, 'CLOSE', {
          panelClass: 'snackbar-error',
          horizontalPosition: 'end',
        }).onAction().subscribe(() => {
          this.snackBar.dismiss();
        });
      } else {
        this.snackBar.open(`${getTitleCase(`Something went wrong while updating your password!`)}`, null, {
          duration: 3000,
        });
      }
      return throwError(err);
    })).subscribe(res => {
      if (res.data && res.data.status === AuthStatus.RESET_PASSWORD) {
        this.snackBar.open(`${getTitleCase(`This password is part of hacked passwords. please choose a stronger password.`)}`, 'CLOSE', {
          panelClass: 'snackbar-error',
          horizontalPosition: 'end',
        }).onAction().subscribe(() => {
          this.snackBar.dismiss();
        });
      } else {
        this.dialogRef.close();
        this.snackBar.open(`${getTitleCase(`Your password has been changed successfully!`)}`, null, {
          duration: 3000,
        });
      }
    });
  }
}
