export class AppConstants { }

export class ServerConstants {
  public static readonly Agencies = 'agencies';
  public static readonly Auth = 'auth';
  public static readonly Groups = 'groups';
  public static readonly Licenses = 'licenses';
  public static readonly Users = 'users';
  public static readonly Cases = 'cases';
  public static readonly Files = 'files';
  public static readonly Uploads = 'uploads';
  public static readonly Activities = 'events';
  public static readonly Email = 'email';
  public static readonly Permissions = 'permissions';
  public static readonly AppSetting = 'appsetting';
}
