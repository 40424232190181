import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defaultsDeep } from 'lodash';
import { environment } from '../../environments/environment';
import { Observable, merge, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HttpService {
  private baseUrl = environment.api_url;

  constructor(protected http: HttpClient) { }

  get connectivityUpdate(): Observable<boolean> {
    return merge(
      fromEvent(window, 'online').pipe(map((e) => true)),
      fromEvent(window, 'offline').pipe(map((e) => false))
    );
  }

  private _reqInterceptor(reqOptions: any) {
    return defaultsDeep(reqOptions, {
      params: {
        // add any global params here, which can be overwritten in the call
      },
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    });
  }

  get(url: string, options?: any): Observable<ArrayBuffer> {
    url = this.baseUrl + url;
    options = this._reqInterceptor(options);
    return this.http.get(url, options);
  }

  put(url: string, options?: any) {
    url = this.baseUrl + url;
    options = this._reqInterceptor(options);
    return this.http.put(url, options);
  }

  post(url: string, body?: any, options?: any): Observable<ArrayBuffer> {
    url = this.baseUrl + url;
    options = this._reqInterceptor(options);
    return this.http.post(url, body, options);
  }

  patch(url: string, body?: any, options?: any): Observable<ArrayBuffer> {
    url = this.baseUrl + url;
    options = this._reqInterceptor(options);
    return this.http.patch(url, body, options);
  }

  delete(url: string, body?: any, options: any = {}): Observable<ArrayBuffer> {
    url = this.baseUrl + url;
    options = this._reqInterceptor({ ...options, body: body });
    return this.http.delete(url, options);
  }
}
