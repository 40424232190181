import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { HttpService } from './http.service';
import { AgencyService } from './api.services/agency.service';
import { UserService } from './api.services/user.service';
import { GroupService } from './api.services/group.service';
import {
  MatButtonModule, MatDialogModule, MatIconModule,
  MatListModule, MatProgressSpinnerModule, MatTooltipModule,
  MatInputModule, MatTableModule, MatCardModule, MatSortModule,
  MatCheckboxModule, MatChipsModule, MatPaginatorModule, MatExpansionModule, MatSlideToggleModule, MatTabsModule, MatButtonToggleModule,
} from '@angular/material';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomHttpInterceptor } from './http.interceptor';
import { CaseService } from './api.services/case.service';
import { BytesPipe } from './pipes/bytes.pipe';
import { FileService } from './api.services/file.service';
import { ActivityNamePipe } from './pipes/activity-name.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { ActivityService } from './api.services/activity.service';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DndDirective } from './directives/dnd.directive';
import { FullscreenDirective } from './directives/fullscreen.directive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { SafePipe } from './pipes/safe.pipe';
import { UploaderComponent } from './components/uploader/uploader.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ObjdumpPipe } from './pipes/objdump.pipe';
import { DialogRouterComponent } from './components/dialog-router.component';
import { RouterModule } from '@angular/router';
import { UpdatesDialogComponent } from './components/updates-dialog/updates-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormatSecondsPipe } from './pipes/format-seconds.pipe';
import { EventDetailsPipe } from './pipes/event-details.pipe';
import { RestrictDialogComponent } from './components/restrict-dialog/restrict-dialog.component';
import { ProfileDialogComponent } from './components/profile-dialog/profile-dialog.component';
import { VideoViewerComponent } from './components/video-viewer/video-viewer.component';
import { DownloadsDialogComponent } from './components/downloads-dialog/downloads-dialog.component';
import { MomentModule } from 'ngx-moment';
import { SortByPipe } from './pipes/sort-by.pipe';
import { DecodeNamePipe } from './pipes/decode-name.pipe';
import { ActiveFolderNodePipe } from './pipes/active-folder-node.pipe';
import { DecodeFilenamePipe } from './pipes/decode-filename.pipe';
import { SubscriptionDialogComponent } from './components/subscription-dialog/subscription-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressBarModule,
    MatTableModule,
    MatCardModule,
    MatSortModule,
    MatCheckboxModule,
    MatChipsModule,
    MatPaginatorModule,
    MatExpansionModule,
    MomentModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatButtonToggleModule
  ],
  declarations: [
    ImageViewerComponent,
    ConfirmDialogComponent,
    DndDirective,
    CapitalizePipe,
    BytesPipe,
    ActivityNamePipe,
    KeysPipe,
    TruncatePipe,
    FullscreenDirective,
    SafePipe,
    UploaderComponent,
    ProgressSpinnerComponent,
    ObjdumpPipe,
    DialogRouterComponent,
    UpdatesDialogComponent,
    FormatSecondsPipe,
    EventDetailsPipe,
    RestrictDialogComponent,
    ProfileDialogComponent,
    VideoViewerComponent,
    DownloadsDialogComponent,
    SortByPipe,
    DecodeNamePipe,
    ActiveFolderNodePipe,
    DecodeFilenamePipe,
    SubscriptionDialogComponent,
  ],
  providers: [
    HttpService,
    AgencyService,
    UserService,
    GroupService,
    ActivityService,
    FileService,
    CaseService, {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    }
  ],
  exports: [
    DndDirective,
    FullscreenDirective,
    CapitalizePipe,
    BytesPipe,
    ActivityNamePipe,
    KeysPipe,
    TruncatePipe,
    SafePipe,
    ObjdumpPipe,
    ImageViewerComponent,
    VideoViewerComponent,
    UploaderComponent,
    ProgressSpinnerComponent,
    DialogRouterComponent,
    EventDetailsPipe,
    SortByPipe,
    DecodeNamePipe,
    ActiveFolderNodePipe,
    DecodeFilenamePipe
  ],
  entryComponents: [
    ConfirmDialogComponent,
    UploaderComponent,
    UpdatesDialogComponent,
    RestrictDialogComponent,
    ProfileDialogComponent,
    DownloadsDialogComponent,
    SubscriptionDialogComponent
  ]
})
export class SharedModule { }
