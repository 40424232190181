<div class="static-container">
  <nav>
    <div class="app-logo" [routerLink]="['/app/']"></div>
    <mat-icon class="firefox-download" *ngIf="download?.state === 'connecting' && browserName === 'Firefox'">
      arrow_upward
    </mat-icon>
  </nav>
  <div class="content-wrapper">
    <mat-card fxLayoutAlign="center center">
      <div *ngIf="download?.state === 'loading'">
        <mat-progress-spinner class="progress-spinner" [color]="color" mode='indeterminate'
          style="font-size: 80px; height: 80px; width: 80px;">
        </mat-progress-spinner>
        <div style="font-size: 16px; margin: 20px;">{{download?.message}}</div>
        <div style="position: absolute; bottom: 8px; width: 100%;" align="center"
          *ngIf="download?.message !== 'Connecting...'">
          <mat-hint style="font-size: 14px; font-weight: 500;">*Don't reload the page</mat-hint>
        </div>
      </div>
      <div *ngIf="download?.state === 'errored'" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon class="icon-error">close</mat-icon>
        <div class="status-message">{{ download?.message }}</div>
      </div>
      <div *ngIf="download?.state === 'ValidationError'" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>report</mat-icon>
        <div class="status-message">
          You have reached the maximum number of incorrect attempts. Try again later.
        </div>
      </div>
      <div *ngIf="download?.state === 'verification'" style="text-align: center;">
        <mat-icon style="font-size: 80px; height: 80px; width: 80px;">vpn_key</mat-icon>
        <!-- <h2 class="mat-body-2">Enter the verification code sent to your email to proceed</h2> -->
        <div class="status-message" style="margin: 14px;">
          Enter the <span id="step1">verification code</span> sent to your email to proceed
        </div>
        <mat-hint class="status-warning" style="font-size: 10px; color: #ff0000; font-weight: 500;"
          *ngIf="isMultipleCodeRequest">
          Note: Multiple Codes Have Been Requested For This Download. Please Enter The LATEST Code To Proceed.
        </mat-hint>
        <form class="otp-form" [formGroup]="otpForm" (submit)="onSubmit()">
          <mat-dialog-content>
            <mat-form-field class="width-50" style="text-align: center">
              <input matInput formControlName="confirmation" name="confirmation" placeholder="Verification Code"
                autocomplete="off" id="step2">
            </mat-form-field>
          </mat-dialog-content>
          <div class="width-100">
            <button mat-raised-button type="submit" color="accent" [disabled]="!otpForm.valid" id="step3"
              data-tooltipClass>SEND</button>
          </div>
        </form>
        <br />
        <!-- <mat-hint style="font-size: 12px;">
          <a [routerLink]="" (click)="onStartGuide()" style="color: inherit; text-decoration: none;"><b>Show me</b></a>
          how to
        </mat-hint> -->
        <!-- <br /><br /> -->
        <mat-hint style="font-size: 12px;" *ngIf="!activateResendOtp">
          Not received the code? <br />Request another verification code after {{timeout}} seconds.
        </mat-hint>
        <button style="width: 50%;" mat-button color="primary" [disabled]="!activateResendOtp" (click)="onResendCode()">
          Request Verification Code
        </button>
      </div>
      <div *ngIf="download?.state === 'click'" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon class="icon-download">arrow_downward</mat-icon>
        <button mat-raised-button color="accent" (click)="downloadFile()">Download</button>
      </div>
      <div *ngIf="download?.state === 'connecting'" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>cloud_download</mat-icon>
        <div class="status-message">{{ download?.message }}</div>
      </div>
      <div *ngIf="download?.state === 'completed'">
        <div *ngIf="download?.status === 'failed'" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon class="icon-error">close</mat-icon>
          <div class="status-message">{{ download?.message }}</div>
        </div>
      </div>
    </mat-card>
    <div class="store-links">
      <a [href]="appstoreUrl" target="_blank" *ngIf="appstoreUrl">
        <img width="130px" src="../../../assets/images/app-store.png" alt="Download from App Store" />
      </a>
      <a [href]="playstoreUrl" target="_blank" *ngIf="playstoreUrl">
        <img width="130px" src="../../../assets/images/google-play.png" alt="Download from Play Store" />
      </a>
    </div>
  </div>

  <div class="app-footer" align="end">
    <mat-icon class="chrome-download" *ngIf="download?.state === 'connecting' && browserName === 'Chrome'">
      arrow_downward
    </mat-icon>
    <div class="app-version">
      <span>© iCrimeFighter - At-Scene LLC {{ now.getFullYear() }}. All Rights Reserved. Cloud Version:
        {{ version }}.</span>
    </div>
  </div>
</div>
