<mat-nav-list>
  <mat-accordion mat-list-item>
    <mat-expansion-panel #expansionPanel style="box-shadow: none;">
      <mat-expansion-panel-header>
        <mat-panel-title style="flex-grow: 0;">
          <span *ngIf="uploaderStatus === 'uploading'">
            <span *ngIf="totalUploads">
              {{ uploadedFiles }}
              <span *ngIf="totalUploads !== 0">of {{ totalUploads }} {{ totalUploads > 1 ? 'Files' : 'File'}}
              </span>
              Uploaded
            </span>
            <span *ngIf="!totalUploads">
              Uploading
            </span>
          </span>
          <span *ngIf="uploaderStatus === 'pending'">
            Pending Uploads
          </span>
          <span *ngIf="uploaderStatus === 'summary'">
            Upload Completed
          </span>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon *ngIf="erroredUploadArr.length && !isExternalUpload" matTooltip="Retry All"
            style="position: relative; top: 2px; left: 88%; cursor: pointer;" (click)="onRetryAll($event)">
            refresh
          </mat-icon>
          <mat-icon [matTooltip]="closeTooltipText" style="position: relative; top: 2px; left: 90%; cursor: pointer;"
            (click)="onClose($event)">close</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <span *ngIf="uploaderStatus === 'summary'">
        <mat-list-item *ngFor="let folderStat of totalFolderStats; let first=first; let last=last;"
          (click)="onViewCase(folderStat.case_id)">
          <div matLine fxLayout="column" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="item">
              <div class="left">
                <span matTooltip="Folder">
                  <mat-icon style="position: relative; top: 2px;">folder</mat-icon>
                </span>
              </div>
              <div fxFlex="grow" fxLayout="column" class="body">
                <span class="title">
                  {{ folderStat.count }} {{ folderStat.count > 1 ? 'Folders' : 'Folder' }} Created In
                  <span class="case-link">{{ folderStat.case_name }}</span>
                </span>
              </div>
              <div class="right">
                <span matTooltip="Uploaded">
                  <mat-icon class="success" style="position: relative; top: 2px;">cloud_done</mat-icon>
                </span>
              </div>
            </div>
          </div>
          <mat-divider>
          </mat-divider>
        </mat-list-item>
        <mat-list-item *ngFor="let uploadStat of totalUploadStats; let first=first; let last=last;"
          (click)="onViewCase(uploadStat.case_id)">
          <div matLine fxLayout="column" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="item">
              <div class="left">
                <span matTooltip="File">
                  <mat-icon style="position: relative; top: 2px;">insert_drive_file</mat-icon>
                </span>
              </div>
              <div fxFlex="grow" fxLayout="column" class="body" *ngIf="!uploadStat.reason">
                <span class="title">
                  {{ uploadStat.count }} {{ uploadStat.count > 1 ? 'Files' : 'File' }} Uploaded To
                  <span class="case-link">{{ uploadStat.case_name }}</span>
                </span>
                <span class="message">
                  It May Take Few Seconds For The Files To Appear
                </span>
              </div>
              <div fxFlex="grow" fxLayout="column" class="body" *ngIf="uploadStat.reason">
                <span class="title">
                  {{ uploadStat.count }}
                  {{ uploadStat.reason === invalidUploadReason.zeroByteFile ? 'Zero Byte File(s)'
                  : 'File(s) With Invalid Name'}}
                  Not Uploaded To
                  <span class="case-link">{{ uploadStat.case_name }}</span>
                </span>
              </div>
              <div class="right" *ngIf="!uploadStat.reason">
                <span matTooltip="Uploaded">
                  <mat-icon class="success" style="position: relative; top: 2px;">cloud_done</mat-icon>
                </span>
              </div>
              <div class="right" *ngIf="uploadStat.reason">
                <span matTooltip="Not Uploaded">
                  <mat-icon class="warning" style="position: relative; top: 2px; color: #b00020;">warning</mat-icon>
                </span>
              </div>
            </div>
          </div>
          <mat-divider *ngIf="!last">
          </mat-divider>
        </mat-list-item>
      </span>

      <span *ngIf="uploaderStatus === 'uploading' || uploaderStatus === 'pending'">
        <mat-list-item *ngFor="let folder of folders; let first=first; let last=last;">
          <div matLine fxLayout="column" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="item" [ngClass]="{'first': first}">
              <div class="left">
                <span matTooltip="Folder">
                  <mat-icon style="position: relative; top: 2px;">folder</mat-icon>
                </span>
              </div>
              <div fxFlex="grow" fxLayout="column" class="body">
                <span class="title">
                  {{ folder.name }}
                </span>
              </div>
              <div class="right">
                <span matTooltip="Uploaded">
                  <mat-icon class="success" style="position: relative; top: 2px;">cloud_done</mat-icon>
                </span>
              </div>
            </div>
            <mat-divider *ngIf="!last">
            </mat-divider>
          </div>
        </mat-list-item>

        <mat-list-item *ngFor="let upload of uploads|async; let first=first; let last=last;">
          <div matLine fxLayout="column" fxLayoutAlign="space-between" *ngIf="upload">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="item" [ngClass]="{'first': first}">
              <div class="left">
                <span *ngIf="upload.state === UploadStatus.PROCESSING" matTooltip="{{upload.state}}">
                  <mat-icon>more_horiz</mat-icon>
                </span>
                <span *ngIf="upload.state === UploadStatus.UPLOADING || upload.state === UploadStatus.QUEUED"
                  matTooltip="File">
                  <mat-icon>insert_drive_file</mat-icon>
                </span>
                <span *ngIf="upload.state === UploadStatus.COMPLETED" matTooltip="{{upload.state}}">
                  <mat-icon>cloud_done</mat-icon>
                </span>
                <span *ngIf="upload.state === UploadStatus.ERRORED" matTooltip="{{upload.state}}">
                  <mat-icon>sync_problem</mat-icon>
                </span>
              </div>
              <div fxFlex="grow" fxLayout="column" class="body">
                <span class="title" matTooltip="{{ upload.file.name | decodeFilename }}">
                  {{ upload.file.name | decodeFilename | truncate:[25] }}
                  <span class="title-details" *ngIf="upload.case_name"> to {{ upload.case_name }}</span>
                </span>
                <span *ngIf="upload.state === UploadStatus.PENDING" class="message">
                  You May Resume This Upload By Uploading The Same File.
                </span>
                <span *ngIf="upload.state === UploadStatus.ERRORED" class="message">
                  {{ upload.errorMsg === '' ? 'Something Went Wrong With The Upload. Please Try Again.'
                  : upload.errorMsg | titlecase }}
                </span>
                <span *ngIf="upload.state === UploadStatus.UPLOADING && upload.progress" class="message">
                  <span *ngIf="upload.progress.readableSpeed !== ''">{{ upload.progress.readableSpeed }}/s</span>
                  <span *ngIf="upload.progress.totalUploaded !== 0">
                    &nbsp;-&nbsp;{{upload.progress.totalUploaded | bytes}} of {{upload.progress.fileSize | bytes}},
                  </span>
                  <span *ngIf="upload.progress.secondsLeft !== -1">
                    {{upload.progress.secondsLeft | formatSeconds }} left
                  </span>
                </span>
                <span *ngIf="upload.state === UploadStatus.QUEUED" class="message">
                  Queued For Uploading
                </span>
                <span *ngIf="upload.state === UploadStatus.PAUSED" class="message">
                  paused
                </span>
              </div>
              <div class="right">
                <button mat-icon-button (click)="onSelectToResume(upload)" matTooltip="upload"
                  *ngIf="upload.state === UploadStatus.PENDING">
                  <mat-icon>cloud_upload</mat-icon>
                </button>
                <button mat-icon-button (click)="onRetry(upload)" matTooltip="Retry"
                  *ngIf="upload.state === UploadStatus.ERRORED && !isExternalUpload">
                  <mat-icon>refresh</mat-icon>
                </button>
                <!-- <button mat-icon-button (click)="onResume(upload)"
                *ngIf="upload.state === UploadStatus.PAUSED && !uploadType" matTooltip="resume">
                <mat-icon>play_circle_filled</mat-icon>
              </button>
              <button mat-icon-button (click)="onPause(upload)"
                *ngIf="upload.state === UploadStatus.UPLOADING && upload.progress && !uploadType" matTooltip="pause">
                <mat-icon>pause_circle_filled</mat-icon>
              </button> -->
                <button mat-icon-button (click)="onPauseAndDelete(upload)" matTooltip="cancel" data-cy="case|uploader|button|cancel">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="progress-bar">
              <mat-progress-bar #bar mode="{upload.progress?.secondsLeft === -1 ? 'indeterminate' : 'determinate'}"
                [value]="upload.progress?.percentage"
                *ngIf="upload.state === (UploadStatus.UPLOADING || UploadStatus.PROCESSING)"></mat-progress-bar>
            </div>
            <mat-divider *ngIf="upload.state !== (UploadStatus.UPLOADING || UploadStatus.PROCESSING) && !last">
            </mat-divider>
          </div>
        </mat-list-item>
      </span>
    </mat-expansion-panel>
  </mat-accordion>
  <input #fileInput type="file" (change)="onFileSelect($event); fileInput.value='';"
    [accept]="allowedFileTypes.join(',')" [style.display]="'none'" />
</mat-nav-list>
