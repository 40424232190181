import { Injectable } from '@angular/core';
import {
  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse,
  HttpErrorResponse,
  HttpResponseBase
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { SessionStorage } from 'ngx-webstorage';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isExternalRequest } from 'app/app.utils';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  @SessionStorage('isLoading', false) private isLoading;

  constructor(
    private router: Router,
    private authService: AuthService,) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.isLoading = true;

    if (!isExternalRequest(request.url)) {
      request = request.clone({
        setHeaders: {
          Authorization: this.authService.token || ''
        }
      });
    }

    if (request.url.split('/').indexOf('streaming') !== -1) { // intercepting req body with 'withCredentials'
      request = request.clone({                               // parameter only for streaming api call
        withCredentials: true
      });
    }

    return next.handle(request).pipe(
      filter((event) => event instanceof HttpResponseBase),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.refresh_access_token) {
            this.authService.setToken(event.body.refresh_access_token);
          }
          this.isLoading = false;
          // do stuff with response if you want
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.isLoading = false;
          if (err.status === 401 && !isExternalRequest(request.url)) {
            this.authService.logout();
            this.router.navigateByUrl('/login');
            // return err;
          }
        }
      }),
    );
  }
}
